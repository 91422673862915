import storage from '@helpers/localStorage';
import { IRichPeriod } from '@interfaces/IPeriod';
import { $allSegments, getList } from '@stores/periods';
import { combine, createEvent, createStore, sample } from 'effector';
import { nanoid } from 'nanoid';

export const setSegment = createEvent<IRichPeriod | undefined>('set segment');
export const setNextSegment = createEvent<Event>('set next segment');

type ISelectedState = {
  segment: IRichPeriod;
  hash: string;
} | null;

const setHandler = (
  state: ISelectedState,
  segment: IRichPeriod | undefined,
) => {
  if (!segment) return null;
  return {
    segment,
    hash: nanoid(),
  };
};

const selected = createStore<ISelectedState>(null).on(setSegment, setHandler);

sample({
  source: [$allSegments, selected],
  target: selected,
  clock: setNextSegment,
  // @ts-ignore
  fn([allSegments, selected]) {
    if (!selected) return null;
    const index = allSegments.findIndex(
      (s: any) => s.id === selected.segment.id,
    );
    if (allSegments.length - 1 === index) return null;
    const segment = allSegments[index + 1];
    return {
      segment,
      hash: nanoid(),
      index: index + 1,
    };
  },
});

getList.watch(() => setSegment(undefined));

const initialAutoplay: boolean = storage.read('autoplay') === 'true' || false;
export const setAutoPlayMode = createEvent<boolean>();
export const $autoplay = createStore<boolean>(initialAutoplay).on(
  setAutoPlayMode,
  (_, payload) => payload,
);
$autoplay.watch((state) => {
  storage.write('autoplay', state.toString());
});

const $video = combine({ selected, autoplay: $autoplay });

export default $video;
