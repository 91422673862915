import React, { FunctionComponent } from 'react';
import css from './FAQ.module.less';
import componentContent from './FAQ.content.json';
import useTranslate from '@hooks/useTranslate';
import { useStore } from 'effector-react';
import $faq, { getList } from '@stores/faq';
import { Spin, Collapse } from 'antd';
import useMedia from '@hooks/useMedia';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useRef } from 'react';

interface FAQProps {}

const FAQ: FunctionComponent<FAQProps> = () => {
  const content = useTranslate(componentContent);
  const { list, preloaders, pagination, filters } = useStore($faq);
  const { isTabletOrMobile } = useMedia();

  useEffect(() => {
    getList({ pagination, filters });
    // eslint-disable-next-line
  }, []);

  const titleRef = useRef<HTMLDivElement>(null);

  return (
    <div className={classNames(css.root, { [css.mobile]: isTabletOrMobile })}>
      <div>
        <h1 ref={titleRef} className={css.title}>
          {content.title}
        </h1>
      </div>
      <Spin spinning={!!preloaders.getList}>
        <Collapse>
          {list.map((item) => (
            <Collapse.Panel header={item.title} key={item.id}>
              <div className={css.text}>{item.text}</div>
            </Collapse.Panel>
          ))}
        </Collapse>
      </Spin>
    </div>
  );
};

export default FAQ;
