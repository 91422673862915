const MAX = 500;

const generateLightColorHex = () => {
  let color = '#';
  for (let i = 0; i < 3; i++)
    color += (
      '0' + Math.floor(((1 + Math.random()) * Math.pow(16, 2)) / 2).toString(16)
    ).slice(-2);
  return color;
};

const chartColors: string[] = [
  '#9B59B6', // фиолетовый
  '#5DADE2', // голубой
  '#48C9B0', // бирюзовый
  '#5D6D7E', // темно-серый
  '#E67E22', // оранжевый
  '#f7008b', // розовый
  '#f5222d', // красный
  '#ad2102', // красно-коричневый
  '#d46b08', // оранжево-коричневый
  '#d48806', // золотой
  '#ad8b00', // темно-желтый
  '#CACFD2', // серый
  '#bae637', // лаймовый
  '#b7eb8f', // светло-зеленый
  '#36cfc9', // циан,
  '#bae7ff', // светло-голубой,
  '#2f54eb', // geekblue,
  '#F4D03F', // желтый
  '#d3adf7', // светло-пурпурный
  '#ff85c0', // светлая маджента
  '#ECF0F1', // белый
  '#ffccc7',
  '#ffd8bf',
  '#ffe7ba',
  '#fff1b8',
  '#ffffb8',
  '#f4ffb8',
  '#d9f7be',
  '#b5f5ec',
  '#bae7ff',
  '#d6e4ff',
  '#efdbff',
  '#ffd6e7',
  '#ffa39e',
  '#ffbb96',
  '#ffd591',
  '#ffe58f',
  '#fffb8f',
  '#eaff8f',
  '#b7eb8f',
  '#87e8de',
  '#91d5ff',
  '#adc6ff',
  '#d3adf7',
  '#ffadd2',
];

for (let index = chartColors.length; index < MAX; index++) {
  chartColors.push(generateLightColorHex());
}

export default chartColors;
