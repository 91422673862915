import React, { FunctionComponent } from 'react';
import css from './CameraSelector.module.less';
// import componentContent from './CameraSelector.content.json';
// import useTranslate from '@hooks/useTranslate';
import { Button } from 'antd';
import { sortBy } from 'lodash';
import { useStore } from 'effector-react';
import bus, { selectCamera } from '@stores/bus';
import useMedia from '@hooks/useMedia';
import classNames from 'classnames';
import { VideoCameraOutlined } from '@ant-design/icons';
import ICamera from '@interfaces/ICamera';
import { ButtonProps } from 'antd/lib/button';
import Tooltip from 'antd/es/tooltip';

interface CameraSelectorProps {
  mode: 'counter' | 'service';
}

const CameraSelector: FunctionComponent<CameraSelectorProps> = ({ mode }) => {
  // const content = useTranslate(componentContent);
  const { cameras, serviceCameras } = useStore(bus);
  const { isTabletOrMobile } = useMedia();
  const selectedCamearasSet = mode === 'counter' ? cameras : serviceCameras;
  const isTooMuch = selectedCamearasSet.length > 2;
  const getButtonProps = (camera: ICamera) =>
    ({
      key: camera.id,
      className: classNames(css.button, {
        [css.mobile]: isTabletOrMobile,
      }),
      type: camera.selected ? 'primary' : 'default',
      onClick: () => selectCamera(camera.id),
    } as ButtonProps);

  return (
    <div className={css.root}>
      <Button.Group>
        {sortBy(selectedCamearasSet, 'stream_id').map((camera) =>
          isTooMuch && !isTabletOrMobile ? (
            <Tooltip
              key={camera.id}
              title={`${camera.name}${
                camera.status === 'offline' ? ' (offline)' : ''
              }`}
            >
              <Button {...getButtonProps(camera)}>
                <VideoCameraOutlined />
              </Button>
            </Tooltip>
          ) : (
            <Button {...getButtonProps(camera)}>
              {camera.name}
              {camera.status === 'offline' ? ' (offline)' : null}
            </Button>
          ),
        )}
      </Button.Group>
    </div>
  );
};

export default CameraSelector;
