import auth from '@stores/auth';
import { useStoreMap } from 'effector-react';

const useAdmin = () => {
  const isAdmin = useStoreMap({
    store: auth,
    keys: [],
    fn: (state) => {
      const user = state.user;
      return user ? user.role === 'ADMIN' : false;
    },
  });

  return isAdmin;
};

export default useAdmin;
