import React, { FunctionComponent, useLayoutEffect, useState } from 'react';
import css from './MobileCountTab.module.less';
import componentContent from './MobileCountTab.content.json';
import useTranslate from '@hooks/useTranslate';
import Daycount from '@components/Daycount';
import CameraSelector from '@components/CameraSelector';
import CalculatorParams from '@components/CalculatorParams';
import Daychart from '@components/Daychart';
import Timelines from '@components/Timelines';
import VideoPreview from '@components/VideoPreview';
import FragmentList from '@components/FragmentList';
import { useStore } from 'effector-react';
import useMedia from '@hooks/useMedia';
import $video, { setAutoPlayMode, setSegment } from '@stores/video';
import { Empty, Checkbox } from 'antd';
import { Popup } from 'antd-mobile';
import classNames from 'classnames';
import { CloseOutlined, OrderedListOutlined } from '@ant-design/icons';
import MobileBottomPanel from '@components/MobileBottomPanel';
import useSegments from '@hooks/useSegments';
import useBarChart from '@hooks/useBarChart';
import useAdmin from '@hooks/useAdmin';

interface MobileCountTabProps {}

const MobileCountTab: FunctionComponent<MobileCountTabProps> = () => {
  const content = useTranslate(componentContent);
  const allSegments = useSegments();
  const { ticks, data: chartData } = useBarChart();
  const { isAlbum } = useMedia();
  const isAdmin = useAdmin();
  const { selected, autoplay } = useStore($video);
  const [segmentsOpened, setSegmentsVisibleState] = useState(false);
  const openSegments = () => setSegmentsVisibleState(true);
  const closeSegments = () => {
    setSegmentsVisibleState(false);
    setSegment(undefined);
  };
  const [viewportHeight, setViewPortHeight] = useState(0);

  useLayoutEffect(() => {
    function updateSize() {
      setViewPortHeight(window.innerHeight);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const hasData = chartData.length;

  return (
    <>
      <div className={css.daycount}>
        <Daycount />
      </div>
      <div className={css.paramsRow}>
        <div className={css.cameras}>
          <CameraSelector mode="counter" />
        </div>
        <div className={css.params}>
          <CalculatorParams />
        </div>
      </div>
      <div className={css.chartContainer}>
        {hasData ? (
          <div style={{ width: `${ticks.length * 80}px` }}>
            <div className={css.chart}>
              <Daychart />
            </div>
            <div className={css.timelines}>
              <Timelines />
            </div>
          </div>
        ) : (
          <div className={css.empty}>
            <Empty />
          </div>
        )}
      </div>
      <Popup visible={!!selected || segmentsOpened} onMaskClick={closeSegments}>
        <div className={classNames(css.fragments, { [css.album]: isAlbum })}>
          {!!selected ? (
            <div>
              <div
                className={css.videoContainer}
                style={!isAlbum ? { height: viewportHeight - 360 } : {}}
              >
                <VideoPreview className={css.video} autoplay={autoplay} />
                <CloseOutlined
                  onClick={closeSegments}
                  className={css.closeSegmentsIcon}
                />
              </div>
              <div className={css.autoplay}>
                <Checkbox
                  checked={autoplay}
                  onChange={(e) => setAutoPlayMode(e.target.checked)}
                >
                  {content.autoplay}
                </Checkbox>
              </div>
            </div>
          ) : null}
          <FragmentList
            className={css.fragmentList}
            editable={isAdmin}
            periods={allSegments}
            mobile
          />
        </div>
      </Popup>
      <MobileBottomPanel onClick={openSegments}>
        <OrderedListOutlined />
        {content.segments}
      </MobileBottomPanel>
    </>
  );
};

export default MobileCountTab;
