import { createEvent, createStore } from 'effector';
import { RefObject } from 'react';

export const setBodyRef =
  createEvent<RefObject<HTMLDivElement>>('set body ref');

const $bodyRef = createStore<RefObject<HTMLDivElement>>({ current: null }).on(
  setBodyRef,
  (state, { current }) => ({ current }),
);

export default $bodyRef;
