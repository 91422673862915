import api from '@constants/endpoints';
import request from '@helpers/request';
import { IBusGroup } from '@interfaces/IBus';
import { createEffect, createStore } from 'effector';

export const getCustomerGroups = createEffect({
  name: 'getCustomerGroups',
  async handler({ customerId }: { customerId: string }) {
    const response = await request(api.route.getListForCustomer, {
      urlParams: { customerId },
    });
    return response.data;
  },
});

export const $customerGroups = createStore<IBusGroup[]>([]).on(
  getCustomerGroups.done,
  (_, { result }) => result,
);
