import React, { FunctionComponent } from 'react';
import css from './LapsTabs.module.less';
import componentContent from './LapsTabs.content.json';
import useTranslate from '@hooks/useTranslate';
import { useStore } from 'effector-react';
import {
  timeline as $timeline,
  selectedLapNumber as $selectedLapNumber,
  setLap,
} from '@stores/gps';
import useMedia from '@hooks/useMedia';
import { Select } from 'antd';
import LapTabsItem from '@components/LapTabsItem';
import { $track } from '@stores/route';
import classNames from 'classnames';
// import useAdmin from '@hooks/useAdmin';
import { get } from 'lodash';
import { useEffect } from 'react';

interface LapsTabsProps {}

const LapsTabs: FunctionComponent<LapsTabsProps> = () => {
  const timeline = useStore($timeline);
  const selectedLapNumber = useStore($selectedLapNumber);
  const content = useTranslate(componentContent);
  const { isTabletOrMobile } = useMedia();
  const track = useStore($track);
  // const isAdmin = useAdmin();

  const laps = get(timeline, 'reachLaps', []);
  const trackLength = get(track, 'length');

  useEffect(() => {
    if (!laps.length) {
      setLap('route');
    }
  }, [laps.length, trackLength]);

  // if (!timeline && !trackLength) return null;

  if (isTabletOrMobile)
    return (
      <div className={css.mobileContainer}>
        <Select
          value={`${selectedLapNumber}`}
          className={css.mobileSelector}
          onChange={(number) => setLap(+number)}
        >
          {laps.map((l) => (
            <Select.Option value={`${l.number}`} key={`${l.number}`}>
              <div className={css.mobileLapOption}>
                <div className={css.title}>
                  {content.lap} {l.lap}
                </div>
                <div className={css.mobileLapMeta}>
                  <span className={css.metaTitle}>{content.flow}: </span>
                  <span className={css.in}>{l.entered}</span> /{' '}
                  <span className={css.out}>{l.exited}</span>
                </div>
                <div className={css.mobileLapMeta}>
                  <span className={css.metaTitle}>{content.halts}: </span>
                  <span className={css.in}>{l.stops}</span>
                </div>
              </div>
            </Select.Option>
          ))}
        </Select>
      </div>
    );

  return (
    <div className={css.root}>
      {laps.map((lap) => (
        <LapTabsItem key={lap.number} lap={lap} />
      ))}
      <div
        onClick={() => setLap('route')}
        className={classNames(css.routeTrackTab, {
          [css.active]: selectedLapNumber === 'route',
        })}
      >
        {content.routeTrack}
      </div>
    </div>
  );
};

export default LapsTabs;
