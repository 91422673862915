import { Effect, createEvent, createStore, Event } from 'effector';
import { PaginationProps } from 'antd/lib/pagination';
import { DEFAULT_PAGE_SIZE } from '@constants/pagination';
import { get } from 'lodash';

interface IPagableResponse {
  items: any[];
  number: number;
  total: number;
  totalPages: number;
}

export interface IPaginationsParams {
  page: number;
  perPage: number;
}

export const initialPaginationParams: PaginationProps = {
  current: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  total: 0,
};

interface IPaginationFabricConfig {
  getList: Effect<any, any, Error>;
  listName: string;
  resetEvent: Event<void>;
  bodyPath?: string[];
}

const createPaginationStore = (config: IPaginationFabricConfig) => {
  const { getList, listName, bodyPath } = config;
  const setPagination: Event<PaginationProps> = createEvent(
    `Set ${listName} pagination`,
  );
  const pagination = createStore<PaginationProps>(initialPaginationParams)
    .on(getList.done, (state, { result }: { result: IPagableResponse }) => {
      const { number, total } = bodyPath ? get(result, bodyPath) : result;
      return {
        ...state,
        current: (number || 0) + 1,
        total,
        showSizeChanger: false,
      };
    })
    .on(setPagination, (state, pagination) => {
      return pagination;
    })
    .reset(config.resetEvent);
  return { pagination, setPagination };
};

export default createPaginationStore;
