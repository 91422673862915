export const MOBILE_BREAKPOINT: number = 960;
export const WIDE_BREAKPOINT: number = 1560;

const desktopMediaQuery: string = `(min-width: ${MOBILE_BREAKPOINT}px)`;
const mobileMediaQuery: string = `(max-width: ${MOBILE_BREAKPOINT}px)`;
const wideMediaQuery: string = `(min-width: ${WIDE_BREAKPOINT}px)`;

export default {
  apiUrl: process.env.REACT_APP_API_URL || '/',
  // apiUrl: 'http://dockergpu2.nordclan:35816/',
  ymId: 91259253,

  mediaQueries: {
    mobile: mobileMediaQuery,
    desktop: desktopMediaQuery,
    wide: wideMediaQuery,
  },
};
