import React, { FunctionComponent, useLayoutEffect, useMemo } from 'react';
// import css from './MobileTable.module.less';
import componentContent from './StatsTimeline.content.json';
import useTranslate from '@hooks/useTranslate';
import { useStore, useStoreMap } from 'effector-react';
import stats, { setRef } from '@stores/stats';
import { get, mapValues } from 'lodash';
import { Tabs, Table } from 'antd';
import moment from 'moment';
import { busGroups, UNGROUP_ID } from '@stores/buses';
import { useEffect } from 'react';
import { ColumnsType } from 'antd/lib/table';
import sortByName from '@helpers/sortByName';
import { DATE_FORMAT } from '@config/format';
import css from './StatsTimeline.module.less';
import { useState } from 'react';
import { useRef } from 'react';
import $bodyRef from '@stores/bodyRef';
import classNames from 'classnames';

const PARAMS_HEIGHT = 220; // TODO определять динамически

const TabPane = Tabs.TabPane;

interface MobileTableProps {}

const MobileTable: FunctionComponent<MobileTableProps> = () => {
  const content = useTranslate(componentContent);
  const { barChartSource, groupByKey, dataKey, activeRef } = useStore(stats);

  const busIds = barChartSource.selectedBuses.map((bus) => bus.id);
  const routeIds = barChartSource.routeIds;
  const ids = {
    bus: busIds,
    route: routeIds,
  };
  const selectedItemIds = ids[groupByKey];
  const selectedItemsHash = selectedItemIds.join();

  const selectedRoutes = useStoreMap({
    store: busGroups,
    keys: [routeIds.join(',')],
    fn: (state, [ids]) => ids.split(',').map((id) => state[id]),
  });

  const { tabKeys, selectedTableData } = useMemo(() => {
    const tabKeys: string[] = [];
    const data = barChartSource.dataBy[groupByKey].map((item) => {
      const mappedItem = mapValues(item, (values, id) => {
        if (['name', 'timeValue'].includes(id)) return values;
        const value = values[dataKey];
        return value;
      });
      tabKeys.push(item.timeValue);
      return mappedItem;
    });
    const selectedData =
      data.find((item) => `${item.name}` === `${activeRef}`) || {};
    const selectedTableData: Record<string, string>[] = [];

    if (groupByKey === 'bus') {
      barChartSource.selectedBuses.forEach((b) => {
        selectedTableData.push({
          name: b.name || b.id,
          value: get(selectedData, [b.id], 0),
        });
      });
    }

    if (groupByKey === 'route') {
      selectedRoutes.forEach((r) => {
        const id = get(r, 'id', UNGROUP_ID);
        const name = get(r, 'name', content.withoutRoute);
        selectedTableData.push({
          name: name || id,
          value: get(selectedData, [id], 0),
        });
      });
    }

    return { tabKeys, selectedTableData };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataKey,
    groupByKey,
    barChartSource.dataBy,
    selectedItemsHash,
    activeRef,
  ]);
  const tabKeysHash = tabKeys.join(',');

  useEffect(() => {
    if (!activeRef && tabKeys.length) {
      setRef(`${tabKeys[0]}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRef, tabKeysHash]);

  const columns: ColumnsType<any> = [
    {
      title: content[groupByKey],
      dataIndex: 'name',
      sorter: sortByName,
      render: (name) => (
        <div className={classNames('nowrap', 'ellipsis')}>{name}</div>
      ),
    },
    {
      title: content[`${dataKey}Short`],
      dataIndex: 'value',
      sorter: (a, b) => a.value - b.value,
      render: (value) => {
        const valueString = value.toLocaleString();
        const postfix = dataKey === 'fare' ? ' ₽' : '';
        return valueString + postfix;
      },
    },
  ];

  const bodyRef = useStore($bodyRef);
  const tabsRef = useRef<HTMLDivElement>(null);
  const [tableScrollHeight, setHeight] = useState(240);

  useLayoutEffect(() => {
    const bodyElement = get(bodyRef, ['current']);
    if (!bodyElement) return;
    const tabsElement = get(tabsRef, ['current']);
    if (!tabsElement) return;

    const bodyHeight = get(bodyElement.getBoundingClientRect(), 'height', 0);
    const tabsHeight = get(tabsElement.getBoundingClientRect(), 'height', 0);

    setHeight(bodyHeight - tabsHeight - PARAMS_HEIGHT);
    // eslint-disable-next-line
  }, [bodyRef.current, tabsRef.current, activeRef]);

  return (
    <div>
      <div ref={tabsRef}>
        {tabKeys.length > 1 ? (
          <Tabs activeKey={`${activeRef}`} onChange={setRef}>
            {tabKeys.map((value) => (
              <TabPane
                key={`${value}`}
                tab={moment(value)
                  .tz(barChartSource.reportTimezone)
                  .format(barChartSource.tickFormat)}
              ></TabPane>
            ))}
          </Tabs>
        ) : null}
      </div>
      <div>
        <Table
          className={css.mobileTable}
          size="small"
          bordered
          title={() =>
            activeRef
              ? moment(+activeRef)
                  .tz(barChartSource.reportTimezone)
                  .format(DATE_FORMAT)
              : undefined
          }
          dataSource={selectedTableData}
          columns={columns}
          pagination={false}
          scroll={{ y: tableScrollHeight }}
        />
      </div>
    </div>
  );
};

export default MobileTable;
