import React, { FunctionComponent } from 'react';
import css from './MergedRequestsList.module.less';
import componentContent from './MergedRequestsList.content.json';
import useTranslate from '@hooks/useTranslate';
import IMergeRequest from '@interfaces/IMergeRequest';
import { $progress, getFile } from '@stores/merge';
import moment from 'moment';
import {
  FULL_TIME_FORMAT,
  FULL_TIME_FORMAT_WITH_SECONDS,
} from '@config/format';
import { Badge, Tooltip, Progress } from 'antd';
import { PresetStatusColorType } from 'antd/lib/_util/colors';
import { DownloadOutlined } from '@ant-design/icons';
import { useStoreMap } from 'effector-react';
import { get } from 'lodash';
import useAdmin from '@hooks/useAdmin';
import { customers } from '@stores/buses';
import { Link } from 'react-router-dom';
import { getPath } from '@helpers/navigation';

interface ItemProps {
  item: IMergeRequest;
}

const STATUSES: Record<IMergeRequest['status'], PresetStatusColorType> = {
  PROCESS: 'processing',
  FAILED: 'error',
  COMPLETE: 'success',
  SENT: 'default',
};

const Item: FunctionComponent<ItemProps> = ({ item }) => {
  const content = useTranslate(componentContent);
  const fromTo = `${moment(item.from).format(
    FULL_TIME_FORMAT_WITH_SECONDS,
  )} - ${moment(item.to).format(FULL_TIME_FORMAT_WITH_SECONDS)}`;

  const isAdmin = useAdmin();

  const customerName = useStoreMap({
    store: customers,
    keys: [isAdmin, item.client_id],
    fn(state, [isAdmin, id]) {
      if (!id) return null;
      if (!isAdmin) return null;
      const customer = state.find((c) => c.id === id);
      if (customer)
        return [customer.name, customer.patronymic, customer.surname].join(' ');
      return null;
    },
  });

  const progress = useStoreMap({
    store: $progress,
    keys: [item.id],
    fn: (state, [id]) => {
      const total = get(state, [id, 'total']);
      const loaded = get(state, [id, 'loaded']);

      if (!total || !loaded) return 0;
      return (loaded / total) * 100;
    },
  });

  return (
    <div className={css.item}>
      <div className={css.itemTitle}>
        <Tooltip title={content[item.status]} placement="left">
          <Badge status={STATUSES[item.status]} text={fromTo} />
        </Tooltip>
        {['COMPLETE', 'SENT'].includes(item.status) ? (
          <DownloadOutlined
            className={css.download}
            onClick={() => {
              getFile({ requestId: item.id });
            }}
          />
        ) : null}
      </div>
      <div className={css.meta}>
        {content.createdAd}: {moment(item.created_at).format(FULL_TIME_FORMAT)}
        {item.workplace ? (
          <>
            ,{' '}
            <Link
              to={getPath('bus', {
                busId: get(item, ['workplace', 'id']),
              })}
            >
              {get(item, ['workplace', 'description'])}
            </Link>
          </>
        ) : null}
        {customerName ? <>, {customerName}</> : null}
      </div>
      {progress ? (
        <div className={css.progress}>
          <Progress percent={Math.floor(progress)} />
        </div>
      ) : null}
    </div>
  );
};

export default Item;
