import React, { FunctionComponent, useRef, useEffect } from 'react';
import { TileLayer, Polyline, useMap, Pane } from 'react-leaflet';
import theme from '@config/theme.json';
import { Coordinate, IHalt, IStop } from '@interfaces/IGpsPoint';
import BusCurrentPosition from '@components/Bus/BusCurrentPosition';
import IRouteStop from '@interfaces/IRouteStops';

const CommonGpsMap: FunctionComponent<{
  halts: (IHalt | IStop | IRouteStop)[];
  haltMarkers: JSX.Element[];
  anchorMarker: JSX.Element | null;
  selectedHaltId: IHalt['id'] | null;
  points: Coordinate[];
}> = ({ halts, points, selectedHaltId, haltMarkers, anchorMarker }) => {
  const map = useMap();
  const trackRef = useRef<any>();

  useEffect(() => {
    if (trackRef.current) {
      map.fitBounds(trackRef.current.getBounds(), {
        padding: [100, 100],
      });
    }

    // eslint-disable-next-line
  }, [trackRef.current, points.length]);

  useEffect(() => {
    const selectedHalt = halts.find((h) => h.id === selectedHaltId) || null;
    if (selectedHalt) {
      const haltPoint: Coordinate = [
        selectedHalt.latitude,
        selectedHalt.longitude,
      ];
      map.setView(haltPoint, 15);
    }
    // eslint-disable-next-line
  }, [selectedHaltId]);

  return (
    <>
      {points.length ? (
        <Polyline
          ref={trackRef}
          pathOptions={{ fillColor: 'red', color: theme['@primary-color'] }}
          positions={points}
        />
      ) : null}
      <TileLayer
        attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Pane name="haltPane">{haltMarkers}</Pane>
      <Pane name="anchorPane">{anchorMarker}</Pane>
      <BusCurrentPosition />
    </>
  );
};

export default CommonGpsMap;
