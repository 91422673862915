import { createStore, createEvent, createEffect, combine } from 'effector';
import request from '@helpers/request';
import api from '@constants/endpoints';
import createPreloadersStore from './preloaders';
import IBus from '@interfaces/IBus';
import ICamera from '@interfaces/ICamera';
import saveResponseAsFile from '@helpers/saveResponseAsFile';
import { get } from 'lodash';
import { goTo } from '@helpers/navigation';
import { setTerminal } from '@stores/gps';
import update from 'immutability-helper';

export type TabName = 'count' | 'service' | 'gps';

export const reset = createEvent('Bus store reset');
export const setCameras = createEvent<ICamera[]>('set cameras');
export const selectCamera = createEvent<ICamera['id']>('select camera');
export const updateCameras = createEvent('update cameras');
export const setTab = createEvent<TabName>('update cameras');

export const getItem = createEffect({
  name: 'getItem',
  async handler({ urlParams, params, callback }: Record<string, any>) {
    const response = await request(api.buses.item, { urlParams, params });
    const bus: IBus = response.data;
    let config = {};
    try {
      config = JSON.parse(bus.config as string);
    } catch (error) {
      console.error(error);
    }
    bus.config = config;
    if (callback) callback(response.data);
    return bus;
  },
});

getItem.fail.watch((payload) => {
  const status = get(payload, ['error', 'response', 'status']);
  if (status === 404) goTo('buses');
});

export const getReport = createEffect({
  name: 'getReport',
  async handler({ params }: { params: Record<string, any> }) {
    const response = await request(api.stats.workplaces, { params });
    saveResponseAsFile(response);
  },
});

export const getReportWithTariff = createEffect({
  name: 'getReportWithTariff',
  async handler({ params }: { params: Record<string, any> }) {
    const response = await request(api.stats.exportWithTarif, { params });
    saveResponseAsFile(response);
  },
});

export const recalc = createEffect({
  name: 'recalc',
  async handler({
    cameraIds,
    data,
    callback,
  }: {
    cameraIds: ICamera['id'][];
    data: {
      from: string;
      to: string;
      reload: boolean;
    };
    callback: () => void;
  }) {
    await Promise.all(
      cameraIds.map((cameraId) =>
        request(api.segments.recalc, {
          data: { ...data, cameraId },
          urlParams: { cameraId },
        }),
      ),
    );
    if (callback) callback();
  },
});

export const updateStatus = createEffect({
  name: 'updateStatus',
  async handler({ urlParams }: Record<string, any>) {
    const response = await request(api.buses.updateStatus, { urlParams });
    return response.data;
  },
});

const getItemHandler = (state: ICamera[], { result }: { result: IBus }) => {
  return result.cameras
    .filter((c: ICamera) => !c.live)
    .map((camera: ICamera) => ({
      ...camera,
      selected: true,
    }));
};

const setServiceCamerasHandler = (
  state: ICamera[],
  { result }: { result: IBus },
) => {
  return result
    ? result.cameras
        .filter((c: ICamera) => c.live)
        .sort((a: ICamera, b: ICamera) => +a.stream_id - +b.stream_id)
        .map((c: ICamera, index: number) => ({ ...c, selected: index === 0 }))
    : [];
};

export const item = createStore<IBus>({
  gps_trackable: false,
  group: null,
  group_device_id: '',
  name: '',
  id: '',
  timezone: null,
  cameras: [],
  type: 'VMS',
  count_passengers_inside: false,
  terminal_bus_stop_id: '',
})
  .on(getItem.done, (state, { result }) => result)
  .on(setTerminal.done, (state, { params }) => ({
    ...state,
    terminal_bus_stop_id: params.busStopClassifierId,
  }))
  .reset(reset);

const selectCameraHandler = (state: ICamera[], cameraId: ICamera['id']) => {
  const updatedCameraIndex = state.findIndex(
    (camera) => camera.id === cameraId,
  );
  if (updatedCameraIndex < 0) return state;
  const updatedCameras = update(state, {
    [updatedCameraIndex]: {
      selected: { $set: !state[updatedCameraIndex].selected },
    },
  });
  return updatedCameras;
};

const selectServiceCameraHandler = (
  state: ICamera[],
  cameraId: ICamera['id'],
) => {
  const isServiceCamera = state.some((c) => c.id === cameraId);
  if (!isServiceCamera) return state;
  return state.map((c) => ({ ...c, selected: c.id === cameraId }));
};

export const cameras = createStore<ICamera[]>([])
  .on(getItem.done, getItemHandler)
  .on(setCameras, (state, c) => c)
  .on(selectCamera, selectCameraHandler)
  .on(updateCameras, (state) =>
    state.map((camera) => ({ ...camera, selected: true })),
  )
  .on(updateStatus.done, (state, { result }) =>
    state.map((c) => ({ ...c, ...result })),
  )
  .reset(reset);

export const preloaders = createPreloadersStore([
  getItem,
  updateStatus,
  getReport,
  getReportWithTariff,
  recalc,
]);

export const serviceCameras = createStore<ICamera[]>([])
  .on(getItem.done, setServiceCamerasHandler)
  .on(selectCamera, selectServiceCameraHandler)
  .on(updateStatus.done, (state, { result }) =>
    state.map((c) => ({ ...c, ...result })),
  )
  .reset(reset);

export const activeTab = createStore<TabName>('count')
  .on(setTab, (_, tabName) => tabName)
  .reset(reset);

const bus = combine({
  item,
  cameras,
  serviceCameras,
  preloaders,
  activeTab,
});

export default bus;
