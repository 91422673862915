import React, { FunctionComponent } from 'react';
import css from './CustomRangePicker.module.less';
// import componentContent from './CustomRangePicker.content.json';
// import useTranslate from '@hooks/useTranslate';
import { Input, DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import { Moment } from 'moment';
import { get } from 'lodash';

interface CustomRangePickerProps {}

const CustomRangePicker: FunctionComponent<
  CustomRangePickerProps & RangePickerProps<Moment>
> = ({
  open,
  onOpenChange,
  inputReadOnly,
  value,
  onChange,
  allowClear,
  style,
  format,
  ...rest
}) => {
  // const content = useTranslate(componentContent);
  const startValue = get(value, 0, null);
  const endValue = get(value, 1, null);

  const onStartValueChange = (value: Moment | null, formatString: string) => {
    if (onChange) {
      onChange(
        [
          value,
          endValue ? endValue : value ? value.clone().add(1, 'day') : null,
        ],
        ['', ''],
      );
    }
  };

  const onEndValueChange = (value: Moment | null, formatString: string) => {
    if (onChange) {
      onChange(
        [
          startValue
            ? startValue
            : value
            ? value.clone().subtract(1, 'day')
            : null,
          value,
        ],
        ['', ''],
      );
    }
  };

  return (
    <div className={css.root}>
      <Input.Group compact>
        <DatePicker
          open={open}
          onOpenChange={onOpenChange}
          style={{ width: '50%' }}
          inputReadOnly={inputReadOnly}
          allowClear={allowClear}
          format={format}
          autoFocus
          value={startValue}
          onChange={onStartValueChange}
        ></DatePicker>
        <DatePicker
          style={{ width: '50%' }}
          inputReadOnly={inputReadOnly}
          allowClear={allowClear}
          format={format}
          value={endValue}
          onChange={onEndValueChange}
        ></DatePicker>
      </Input.Group>
    </div>
  );
};

export default CustomRangePicker;
