import IPeriod, { IEvent, IRichPeriod } from '@interfaces/IPeriod';

export default (
  a: IPeriod | IEvent | IRichPeriod,
  b: IPeriod | IEvent | IRichPeriod,
) => {
  if (a.start_time < b.start_time) return -1;
  if (b.start_time > a.start_time) return 1;
  return 0;
};
