import React, { FunctionComponent } from 'react';
import { useStore } from 'effector-react';
import authStore, { logOutWithRequest } from '@stores/auth';
import {
  LogoutOutlined,
  LoadingOutlined,
  BellFilled,
  BellOutlined,
} from '@ant-design/icons';
import css from './UserBlock.module.less';
import classNames from 'classnames';
import { Dropdown } from 'antd';
import Notifications from '@components/Notifications';
import { useRef } from 'react';
import useMedia from '@hooks/useMedia';
import useNotiCount from '@hooks/useNotiCount';
import { useState } from 'react';

interface UserBlockProps {
  onlyLogout?: boolean;
}

const UserBlock: FunctionComponent<UserBlockProps> = ({ onlyLogout }) => {
  const auth = useStore(authStore);
  const { isTabletOrMobile } = useMedia();
  const { unreaded, all } = useNotiCount();
  const [notiOpened, setNotiOpenState] = useState(false);

  const notificationRef = useRef<HTMLDivElement>(null);

  return (
    <div className={classNames(css.root, { [css.mobile]: isTabletOrMobile })}>
      {auth.preloaders.getMe ? (
        <LoadingOutlined />
      ) : onlyLogout ? null : (
        <div>
          <span>{auth.user ? auth.user.login : null}</span>
          {all ? (
            <Dropdown
              onVisibleChange={setNotiOpenState}
              trigger={['click']}
              overlay={
                <div className={css.notiScroll} ref={notificationRef}>
                  <Notifications open={notiOpened} />
                </div>
              }
            >
              {unreaded ? (
                <BellFilled className={classNames(css.icon, css.noti)} />
              ) : (
                <BellOutlined className={classNames(css.icon)} />
              )}
            </Dropdown>
          ) : null}
        </div>
      )}
      <div
        className={classNames(css.icon, {
          [css.onlyLogout]: onlyLogout,
          [css.mobile]: isTabletOrMobile,
        })}
        onClick={() => logOutWithRequest()}
      >
        {auth.preloaders.logOutWithRequest ? (
          <LoadingOutlined />
        ) : (
          <LogoutOutlined />
        )}
      </div>
    </div>
  );
};

export default UserBlock;
