import React, { FunctionComponent } from 'react';
import css from './Notifications.module.less';
import componentContent from './Notifications.content.json';
import useTranslate from '@hooks/useTranslate';
import MergedRequestsList from '@components/MergedRequestsList';
import News from '@components/News';
import useNotiCount from '@hooks/useNotiCount';
import { Empty } from 'antd';

interface NotificationsProps {
  open: boolean;
}

const Notifications: FunctionComponent<NotificationsProps> = ({ open }) => {
  const content = useTranslate(componentContent);
  const { all } = useNotiCount();

  if (all <= 0) {
    return (
      <div className={css.root}>
        <Empty description={content.empty} />
      </div>
    );
  }

  return (
    <div className={css.root} onClick={(e) => e.stopPropagation()}>
      <MergedRequestsList />
      <News open={open} />
    </div>
  );
};

export default Notifications;
