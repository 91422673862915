import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.less';

function setHeight() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}
setHeight();
document.documentElement.setAttribute('data-prefers-color-scheme', 'dark');
window.addEventListener('resize', setHeight);

ReactDOM.render(
  // TODO: обновить and и включить strictMode после резолва этого ишью:
  // https://github.com/ant-design/ant-design/issues/22493
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
