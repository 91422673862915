import React, { FunctionComponent, useState } from 'react';
import css from './SelectedSegmentTabs.module.less';
import componentContent from './SelectedSegmentTabs.content.json';
import useTranslate from '@hooks/useTranslate';
import MapTab from '@components/MapTab';
import VideoPreview from '@components/VideoPreview';
import { Tabs } from 'antd';
import { VideoCameraOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { hasVideoStatuses, liveVideoStatuses } from '@constants/periodStatuses';
import { useStore } from 'effector-react';
import $bus from '@stores/bus';
import { useEffect } from 'react';
import Checkbox from 'antd/es/checkbox';
import $video, { setAutoPlayMode } from '@stores/video';

interface SelectedSegmentTabsProps {
  videoClassName?: string;
  mapClassName?: string;
}

type TabName = 'video' | 'map';

const SelectedSegmentTabs: FunctionComponent<SelectedSegmentTabsProps> = ({
  videoClassName,
}) => {
  const content = useTranslate(componentContent);
  const { selected, autoplay } = useStore($video);
  const selectedSegment = selected ? selected?.segment : null;
  const { item } = useStore($bus);
  let selectedTab = null;
  const hasVideo = selectedSegment
    ? hasVideoStatuses.includes(selectedSegment.status)
    : false;
  const hasMap = item.gps_trackable;
  const [selectedTabName, setTab] = useState<TabName | string>(
    hasVideo ? 'video' : 'map',
  );

  const isLive = selectedSegment
    ? liveVideoStatuses.includes(selectedSegment.status)
    : false;

  useEffect(() => {
    if (hasVideo && !hasMap) setTab('video');
    if (!hasVideo && hasMap) setTab('map');
  }, [hasVideo, hasMap]);
  if (!selected) return null;

  if (!hasVideo && !hasMap) return null;

  switch (selectedTabName) {
    case 'video':
      selectedTab = (
        <VideoPreview className={videoClassName} autoplay={autoplay} />
      );
      break;

    case 'map':
      selectedTab = <MapTab />;
      break;

    default:
      break;
  }

  return (
    <div className={css.root}>
      <div className={css.tabs}>
        <Tabs
          className={css.tabsContent}
          onChange={setTab}
          activeKey={selectedTabName}
          size="small"
          type="card"
        >
          {hasVideo ? (
            <Tabs.TabPane
              tab={
                <>
                  <VideoCameraOutlined />
                  {content.video}
                </>
              }
              key="video"
            />
          ) : null}
          {hasMap ? (
            <Tabs.TabPane
              tab={
                <>
                  <EnvironmentOutlined />
                  {content.map}
                </>
              }
              key="map"
            />
          ) : null}
        </Tabs>
        {selectedSegment && selectedTabName === 'video' && !isLive ? (
          <div className={css.autoplay}>
            <Checkbox
              checked={autoplay}
              onChange={(e) => setAutoPlayMode(e.target.checked)}
            >
              {content.autoplay}
            </Checkbox>
          </div>
        ) : null}
      </div>

      {selectedTab}
    </div>
  );
};

export default SelectedSegmentTabs;
