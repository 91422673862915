import React, { FunctionComponent, useEffect } from 'react';
import css from './News.module.less';
// import componentContent from './News.content.json';
// import useTranslate from '@hooks/useTranslate';
import INews from '@interfaces/INews';
import moment from 'moment';
import { DATE_FORMAT } from '@config/format';
import { readNews } from '@stores/news';

interface ItemProps {
  item: INews;
}

const Item: FunctionComponent<ItemProps> = ({ item }) => {
  // const content = useTranslate(componentContent);

  useEffect(() => {
    if (!item.read) readNews({ newsId: item.id });
  }, [item.read, item.id]);

  return (
    <div className={css.item}>
      <div className={css.itemTitle}>
        <h3>{item.title}</h3>
        <div className={css.date}>
          {item.dateFrom
            ? moment(item.dateFrom).format(DATE_FORMAT)
            : moment(item.createdAt).format(DATE_FORMAT)}
        </div>
      </div>
      <div
        className={css.text}
        dangerouslySetInnerHTML={{
          __html: (item.text || '').replace(/\r?\n|\r/g, '<br>'),
        }}
      />
    </div>
  );
};

export default Item;
