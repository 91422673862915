import locale, { LocaleName } from '@stores/locale';
import { useStore } from 'effector-react';
import { forEach } from 'lodash';
import common from '@constants/common.content.json';
import { useMemo } from 'react';

export default function useTranslate(
  translationObject: Record<string, Record<LocaleName, string>>,
  mergeWithCommon = true,
) {
  const localeName = useStore(locale);

  const commonContent = useMemo(() => {
    const _commonContent: Record<string, string> = {};
    forEach(
      common,
      (selector: Record<string, string>, key) =>
        (_commonContent[key] = selector[localeName]),
    );
    return _commonContent;
  }, [localeName]);

  const componentContent: Record<string, string> = {};
  forEach(
    translationObject,
    (selector, key) => (componentContent[key] = selector[localeName] || key),
  );

  const result = {
    ...(mergeWithCommon ? commonContent : {}),
    ...componentContent,
  };

  const proxy = new Proxy(result, {
    get: function (target, name: string) {
      if (name in target) {
        return target[name];
      } else {
        return name;
      }
    },
  });

  return proxy;
}
