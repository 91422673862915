import { useStore, useStoreMap } from 'effector-react';
import { cameras as $cameras } from '@stores/bus';
import ICamera from '@interfaces/ICamera';
import { $events, combineData } from '@stores/periods';
import { forEach } from 'lodash';
import createBarChartData from '@helpers/createBarChartData';
import { IEvent } from '@interfaces/IPeriod';

const useBarChart = () => {
  const cameraIds = useStoreMap({
    store: $cameras,
    keys: [],
    fn: (state) => {
      const selectedIds: ICamera['id'][] = [];
      state.forEach((c) => (c.selected ? selectedIds.push(c.id) : null));
      return selectedIds;
    },
  });
  const events = useStore($events);

  const data = useStoreMap({
    store: combineData,
    keys: [cameraIds.join(','), events],
    fn: (state, [ids, hash]) => {
      const { totalMax, totalMin } = state.timelineParams;
      const allEvents: IEvent[] = [];
      forEach(events, (events, id) => {
        if (!ids.split(',').includes(`${id}`)) return;
        allEvents.push(...events);
      });
      return createBarChartData({
        totalMax,
        totalMin,
        allEvents,
      });
    },
  });

  return data;
};

export default useBarChart;
