import React, { FunctionComponent } from 'react';
import css from './RecalcButton.module.less';
import componentContent from './RecalcButton.content.json';
import useTranslate from '@hooks/useTranslate';
import { SyncOutlined, LoadingOutlined } from '@ant-design/icons';
import { useStore } from 'effector-react';
import $bus, { recalc } from '@stores/bus';
import { Tooltip, Popover, Checkbox, Button, Form } from 'antd';
import { useState } from 'react';
import dateStore from '@stores/date';
import { getEvents, getList } from '@stores/periods';
import { useEffect } from 'react';

interface RecalcButtonProps {}

const RecalcButton: FunctionComponent<RecalcButtonProps> = () => {
  const content = useTranslate(componentContent);
  const { item, cameras, preloaders } = useStore($bus);
  const date = useStore(dateStore);
  const [visible, setVisibleState] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setVisibleState(false);
  }, [preloaders.recalc]);

  useEffect(() => {
    form.setFieldsValue({ cameraIds: cameras.map((c) => c.id) });
  }, [cameras, form]);

  const onConfirm = (values: Record<string, any>) => {
    const { cameraIds, reload } = values;
    if (date) {
      const from = date ? date?.formattedStartdate : '';
      const to = date ? date?.formattedEnddate : '';
      const data = { from, to, reload };
      recalc({
        cameraIds,
        data,
        callback: () => {
          const params = {
            date: date.date,
            bus: item,
          };
          getList(params);
          getEvents(params);
        },
      });
    }
  };

  const popoverContent = (
    <div className={css.popover}>
      <Form form={form} onFinish={onConfirm}>
        <Form.Item
          noStyle
          name="cameraIds"
          initialValue={cameras.map((c) => c.id)}
        >
          <Checkbox.Group
            options={cameras
              .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
              .map((c) => ({ label: c.name, value: c.id }))}
          />
        </Form.Item>
        <Form.Item
          noStyle
          name="reload"
          valuePropName="checked"
          initialValue={false}
        >
          <div className={css.reload}>
            <Checkbox>{content.reload}</Checkbox>
          </div>
        </Form.Item>
        <div className={css.footer}>
          <Button size="small" onClick={() => setVisibleState(false)}>
            {content.cancel}
          </Button>
          <Button size="small" type="primary" htmlType="submit">
            {content.ok}
          </Button>
        </div>
      </Form>
    </div>
  );

  return (
    <div className={css.root}>
      {preloaders.recalc ? (
        <LoadingOutlined />
      ) : (
        <Popover
          visible={visible}
          onVisibleChange={setVisibleState}
          content={popoverContent}
          placement="bottomLeft"
          trigger={['click']}
          title={content.recalc}
        >
          <Tooltip title={content.recalc}>
            <SyncOutlined />
          </Tooltip>
        </Popover>
      )}
    </div>
  );
};

export default RecalcButton;
