import React, { FunctionComponent, ReactNode } from 'react';
import css from './MobileBottomPanel.module.less';

interface MobileBottomPanelProps {
  children: ReactNode | ReactNode[];
  onClick?: () => void;
}

const MobileBottomPanel: FunctionComponent<MobileBottomPanelProps> = ({
  children,
  onClick,
}) => {
  return (
    <div onClick={onClick} className={css.root}>
      {children}
    </div>
  );
};

export default MobileBottomPanel;
