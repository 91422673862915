import appConfig from '@config/app';
import { useMediaQuery } from 'react-responsive';

const useMedia = () => {
  const isBigScreen = useMediaQuery({
    query: appConfig.mediaQueries.desktop,
  });
  const isTabletOrMobile = useMediaQuery({
    query: appConfig.mediaQueries.mobile,
  });
  const withFields = useMediaQuery({
    query: appConfig.mediaQueries.wide,
  });
  const isAlbum = useMediaQuery({ query: '(orientation: landscape)' });
  return { isBigScreen, isTabletOrMobile, isAlbum, withFields };
};

export default useMedia;
