import React, { FunctionComponent } from 'react';
import css from './BusHeader.module.less';
import Daycount from '@components/Daycount';
import Sumcount from '@components/SumCount';
import CameraSelector from '@components/CameraSelector';

interface BusHeaderProps {}

const BusHeader: FunctionComponent<BusHeaderProps> = () => {
  return (
    <div className={css.header}>
      <div>
        <CameraSelector mode="counter" />
      </div>
      <Daycount />
      <div style={{ marginLeft: 'auto' }}>
        <Sumcount />
      </div>
    </div>
  );
};

export default BusHeader;
