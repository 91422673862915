import React, { FunctionComponent, useState, useEffect, useMemo } from 'react';
import css from './SegmentsDownloader.module.less';
import componentContent from './SegmentsDownloader.content.json';
import useTranslate from '@hooks/useTranslate';
import ICamera from '@interfaces/ICamera';
import { Slider, Input, DatePicker, Button } from 'antd';
import Timeline from '@components/Timeline';
import { useStore, useStoreMap } from 'effector-react';
import { SliderValue } from 'antd/lib/slider';
import { BUS_DEFAULT_TIMEZONE, TIME_FORMAT } from '@config/format';
import moment from 'moment-timezone';
import { get } from 'lodash';
import {
  preloadAllFiles,
  preloaders as $periodsPreloaders,
} from '@stores/periods';
import { item } from '@stores/bus';
import $servicePeriods, { getServiceList } from '@stores/servicePeriods';
import $date from '@stores/date';
import { IRichPeriod } from '@interfaces/IPeriod';

const HOUR = 3.6e6;

interface SegmentsDownloaderProps {
  camera: ICamera;
  mergedSegments: IRichPeriod[];
}

const SegmentsDownloader: FunctionComponent<SegmentsDownloaderProps> = ({
  camera,
  mergedSegments,
}) => {
  const content = useTranslate(componentContent);
  const { preloadAllFiles: isPreload } = useStore($periodsPreloaders);
  const dateState = useStore($date);

  const { mergedTimelineParams } = useStore($servicePeriods);
  const [selectedRange, setRange] = useState<SliderValue | undefined>(
    mergedTimelineParams
      ? [mergedTimelineParams?.totalMin, mergedTimelineParams?.totalMin]
      : undefined,
  );
  const timezone =
    useStoreMap({
      store: item,
      keys: [],
      fn: (state) => state.timezone,
    }) || BUS_DEFAULT_TIMEZONE;

  const utcOffset = useMemo(() => {
    return moment.tz(timezone).utcOffset();
  }, [timezone]);

  const startValue = get(selectedRange, [0], undefined);
  const endValue = get(selectedRange, [1], undefined);
  const start = startValue
    ? moment.utc(startValue).tz(timezone)
    : moment.utc(startValue).tz(timezone).subtract(utcOffset, 'm');
  const end = endValue
    ? moment.utc(endValue).tz(timezone)
    : moment.utc(endValue).tz(timezone).subtract(utcOffset, 'm');

  const moreThanMax = endValue - startValue > HOUR;
  const nothingSelected = !(endValue - startValue);
  const offline = !camera.enabled || camera.status === 'offline';

  useEffect(() => {
    setRange(
      mergedTimelineParams
        ? [mergedTimelineParams?.totalMin, mergedTimelineParams?.totalMin]
        : undefined,
    );
  }, [mergedTimelineParams]);

  const preload = () => {
    if (selectedRange) {
      preloadAllFiles({
        cameraId: camera.id,
        startTime: start.format(),
        endTime: end.format(),
        callback: dateState
          ? () =>
              getServiceList({
                startdate: dateState.formattedStartdate,
                enddate: dateState.formattedEnddate,
              })
          : undefined,
      });
    }
  };

  return (
    <div className={css.root}>
      <div className={css.topline}>
        <div className={css.inputRange}>
          <Input.Group compact>
            <DatePicker.TimePicker
              value={start}
              onChange={(value) =>
                setRange([
                  value?.valueOf(),
                  get(selectedRange, [1], undefined),
                ] as SliderValue)
              }
            />
            <DatePicker.TimePicker
              value={end}
              onChange={(value) =>
                setRange([
                  get(selectedRange, [0], undefined),
                  value?.valueOf(),
                ] as SliderValue)
              }
            />
          </Input.Group>
        </div>
        <div className={css.actions}>
          <Button
            type="primary"
            loading={!!isPreload}
            onClick={preload}
            disabled={moreThanMax || nothingSelected || offline}
          >
            {content.preload}
          </Button>
        </div>
        <div className={css.info}>
          {offline
            ? content.offlineText
            : moreThanMax
            ? content.moreThanMax
            : nothingSelected
            ? content.nothingSelected
            : null}
        </div>
      </div>
      <div>
        <div className={css.timelineContainer}>
          <Timeline
            merged={true}
            periods={mergedSegments}
            formattedTotalMax={
              mergedTimelineParams
                ? mergedTimelineParams?.formattedTotalMax
                : ''
            }
            formattedTotalMin={
              mergedTimelineParams
                ? mergedTimelineParams?.formattedTotalMin
                : ''
            }
          />
        </div>
        <Slider
          // tooltipVisible
          range
          step={600000}
          tipFormatter={(value) =>
            value ? moment.utc(value).tz(timezone).format(TIME_FORMAT) : '00:00'
          }
          value={selectedRange}
          onChange={setRange}
          min={mergedTimelineParams?.totalMin}
          max={mergedTimelineParams?.totalMax}
        />
      </div>
    </div>
  );
};

export default SegmentsDownloader;
