import React, {
  ChangeEventHandler,
  FunctionComponent,
  MouseEventHandler,
  useRef,
  useState,
} from 'react';
import css from './UploadGpxForRoute.module.less';
import componentContent from './UploadGpxForRoute.content.json';
import useTranslate from '@hooks/useTranslate';
import { Button } from 'antd';
import { UploadOutlined, SyncOutlined } from '@ant-design/icons';
import {
  createRouteFromGpx,
  $preloaders,
  updateRouteFromGpx,
} from '@stores/route';
import { get } from 'lodash';
import { useStore } from 'effector-react';
import { item } from '@stores/bus';
import { nanoid } from 'nanoid';

interface UploadGpxForRouteProps {
  mode?: 'create' | 'update';
  groupId?: string;
}

const UploadGpxForRoute: FunctionComponent<UploadGpxForRouteProps> = ({
  mode = 'create',
  groupId = '',
}) => {
  const content = useTranslate(componentContent);
  const fileRef = useRef<HTMLInputElement>(null);
  const preloaders = useStore($preloaders);
  const bus = useStore(item);
  const [inputKey, setInputKey] = useState(nanoid());

  const handleFileClick: MouseEventHandler = () => {
    if (fileRef.current) fileRef.current.click();
  };

  const handleFilesChange: ChangeEventHandler = async (e) => {
    const file = get(e, ['target', 'files', 0]);
    const formData = new FormData();
    const busId = get(bus, 'id');

    if (file) {
      formData.append('file', file);

      switch (mode) {
        case 'create':
          await createRouteFromGpx({ formData, busId });
          break;

        case 'update':
          await updateRouteFromGpx({ formData, groupId, preloaderId: groupId });
          break;

        default:
          break;
      }
    }

    setInputKey(nanoid());
  };

  return (
    <>
      <Button
        title={mode === 'update' ? content.update : ''}
        icon={mode === 'create' ? <UploadOutlined /> : <SyncOutlined />}
        onClick={handleFileClick}
        loading={
          Boolean(preloaders.createRouteFromGpx) ||
          preloaders.updateRouteFromGpx === groupId
        }
      >
        {mode === 'create' ? content.upload : null}
      </Button>
      <input
        key={inputKey}
        type="file"
        className={css.fileInput}
        ref={fileRef}
        onChange={handleFilesChange}
      />
    </>
  );
};

export default UploadGpxForRoute;
