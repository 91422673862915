import React, { FunctionComponent } from 'react';
import css from './CalcualtorParams.module.less';
// import componentContent from './CalcualtorParams.content.json';
// import useTranslate from '@hooks/useTranslate';
import Button from 'antd/es/button';
import { Popup } from 'antd-mobile';
import { useState } from 'react';
import Sumcount from '@components/SumCount';
import useTotalSum from '@hooks/useTotalSum';

interface CalcualtorParamsProps {}

const CalcualtorParams: FunctionComponent<CalcualtorParamsProps> = () => {
  // const content = useTranslate(componentContent);
  const [visible, setVisible] = useState(false);
  const { calcSum } = useTotalSum();

  return (
    <div className={css.root}>
      <Button className={css.button} onClick={() => setVisible(!visible)}>
        {(calcSum || 0).toLocaleString('ru')} <span>₽</span>
      </Button>
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
      >
        <div className={css.modalBody}>
          <Sumcount />
        </div>
      </Popup>
    </div>
  );
};

export default CalcualtorParams;
