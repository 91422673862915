import React, { FunctionComponent } from 'react';
import css from './StatsLegendItem.module.less';
import chartColors from '@constants/chartColors';
import { GREEN } from '@constants/colors';
import IBus, { IBusGroup } from '@interfaces/IBus';
import { hoverBus, hoveredBusId as $hoveredBusId } from '@stores/stats';
import { useStore } from 'effector-react';

interface StatsLegendItemProps {
  item: IBus | IBusGroup;
  index: number;
  value?: string;
}

const StatsLegendItem: FunctionComponent<StatsLegendItemProps> = ({
  item,
  index,
  value,
}) => {
  const hoveredBusId = useStore($hoveredBusId);
  return (
    <div
      className={css.root}
      onMouseEnter={() => hoverBus(item.id)}
      onMouseLeave={() => hoverBus(null)}
      style={{
        opacity: hoveredBusId
          ? `${hoveredBusId}` === `${item.id}`
            ? 1
            : 0.5
          : 1,
      }}
    >
      <div
        className={css.legendMarker}
        style={{ backgroundColor: chartColors[index] || GREEN }}
        title={value}
      >
        <span>{value}</span>
      </div>
      <div className={css.legendText} title={item.name}>
        {item.name}
      </div>
    </div>
  );
};

export default StatsLegendItem;
