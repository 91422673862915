import stats, { $touched } from '@stores/stats';
import { Spin, Modal, Space, Result } from 'antd';
import { useStore } from 'effector-react';
import React, { FunctionComponent, useMemo } from 'react';
import useTranslate from '@hooks/useTranslate';
import componentContent from './StatsTimeline.content.json';
import css from './StatsTimeline.module.less';
import StatsLegend from '@components/StatsLegend';
import { mapValues } from 'lodash';
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import Chart from './Chart';
import useMedia from '@hooks/useMedia';
import classNames from 'classnames';
import Filters from './Filters';
import MobileTable from './MobileTable';
import DataDisplayParams from './DataDisplayParams';

interface StatsTimelineProps {}

const StatsTimeline: FunctionComponent<StatsTimelineProps> = () => {
  const { isTabletOrMobile } = useMedia();
  const content = useTranslate(componentContent);
  const touched = useStore($touched);
  const { barChartSource, preloaders, dataKey, groupByKey } = useStore(stats);

  const busIds = barChartSource.selectedBuses.map((bus) => bus.id);
  const routeIds = barChartSource.routeIds;
  const ids = {
    bus: busIds,
    route: routeIds,
  };
  const selectedItemIds = ids[groupByKey];

  const { data, totalCount } = useMemo(() => {
    let totalCount = 0;
    const data = barChartSource.dataBy[groupByKey].map((item) => {
      const mappedItem = mapValues(item, (values, id) => {
        if (['name', 'timeValue'].includes(id)) return values;
        const value = values[dataKey];
        if (selectedItemIds.map((id) => `${id}`).includes(id))
          totalCount = totalCount + value;
        return value;
      });
      return mappedItem;
    });
    return { data, totalCount };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataKey, groupByKey, barChartSource.dataBy, selectedItemIds.join()]);

  const isOneDay = data.length === 1;

  const emptyElement = (
    <div style={{ marginTop: 48 }}>
      {touched && !preloaders.getStats ? (
        <Result
          icon={<InfoCircleOutlined />}
          title={content.emptyTitle}
          extra={content.emptyText}
        />
      ) : null}
    </div>
  );

  const addonParams = <DataDisplayParams totalCount={totalCount} />;

  return (
    <div className={classNames(css.root, { [css.mobile]: isTabletOrMobile })}>
      <Spin spinning={!!preloaders.getStats}>
        <Filters />
      </Spin>
      {barChartSource.dataBy[groupByKey].length && !isTabletOrMobile ? (
        <div className={css.chartContainer}>
          <StatsLegend isOneDay={isOneDay} />
          <div className={css.chart}>
            <Chart data={data} isOneDay={isOneDay} />
            {addonParams}
          </div>
        </div>
      ) : isTabletOrMobile ? (
        <div>
          {barChartSource.dataBy[groupByKey].length ? addonParams : null}
          <MobileTable />
        </div>
      ) : (
        emptyElement
      )}
      <Modal
        visible={!!preloaders.getStats}
        closable={false}
        footer={null}
        title={content.loadingTitle}
      >
        <Space direction="horizontal">
          <LoadingOutlined /> {content.loadingContent}
        </Space>
      </Modal>
    </div>
  );
};

export default StatsTimeline;
