import React, { FunctionComponent } from 'react';
import { IRichPeriod } from '@interfaces/IPeriod';
import css from './Timeline.module.less';
import { Tooltip } from 'antd';
import cn from 'classnames';
import componentContent from './Timeline.content.json';
import useTranslate from '@hooks/useTranslate';
import {
  processingStatuses,
  waitingStatuses,
  errorStatuses,
} from '@constants/periodStatuses';
import { setSegment } from '@stores/video';
import useMedia from '@hooks/useMedia';
import { MAX_OBJECTS } from '@constants/limits';
import useAdmin from '@hooks/useAdmin';

interface TimelineProps {
  periods: IRichPeriod[];
  formattedTotalMin: string;
  formattedTotalMax: string;
  merged?: boolean;
}

const Timeline: FunctionComponent<TimelineProps> = ({
  periods,
  formattedTotalMin,
  formattedTotalMax,
  merged,
}) => {
  const isAdmin = useAdmin();
  const content = useTranslate(componentContent);
  const isTooMutch = periods ? periods.length > 20 : true;
  const { isTabletOrMobile } = useMedia();
  return (
    <div
      className={cn(css.root, {
        [css.merged]: merged,
        [css.mobile]: isTabletOrMobile,
      })}
    >
      {(isTooMutch || merged) && !isTabletOrMobile && (
        <div className={css.totalForm}>{formattedTotalMin}</div>
      )}
      {(isTooMutch || merged) && !isTabletOrMobile && (
        <div className={css.totalTo}>{formattedTotalMax}</div>
      )}
      {periods && Array.isArray(periods)
        ? periods.map((period) => {
            const {
              left,
              right,
              formattedFrom,
              formattedTo,
              status,
              id,
              max_objects_count,
            } = period;
            const attention = (max_objects_count || 0) > MAX_OBJECTS;
            const processing = processingStatuses.includes(status);
            const waiting = waitingStatuses.includes(status);
            const error = errorStatuses.includes(status);
            const tooltipContent =
              processing || waiting ? (
                content[status]
              ) : (
                <div>
                  <div className={css.tooltipLabel}>
                    {content.from} {formattedFrom} {content.to} {formattedTo}:
                  </div>
                  {period.entered ? (
                    <div className={css.in}>
                      {content.in}: {period.entered[0] + period.entered[1] || 0}
                      {period.entered[1]
                        ? ` (
              ${content.children}: ${period.entered[1] || 0})`
                        : null}
                    </div>
                  ) : null}
                  {period.exited ? (
                    <div className={css.out}>
                      {content.out}: {period.exited[0] + period.exited[1] || 0}
                      {period.exited[1]
                        ? ` (
              ${content.children}: ${period.exited[1] || 0})`
                        : null}
                    </div>
                  ) : null}
                  {/* <div>
              {content.sum}: {period.currSum || 0}
            </div> */}
                </div>
              );
            return (
              <div
                key={id || left}
                className={cn(css.period, {
                  [css.processed]: processing,
                  [css.waiting]: waiting,
                  [css.error]: error,
                  [css.borderless]: isTooMutch || merged,
                  [css.mobile]: isTabletOrMobile,
                  [css.attention]: isAdmin && attention,
                })}
                onClick={() => setSegment(period)}
                style={{ left, right }}
              >
                {/* {!processing && !waiting && !isTooMutch && !merged && (
                  <div className={css.innerCount}>
                    <span>
                      {period.entered
                        ? period.entered[0] + period.entered[1] || 0
                        : null}
                    </span>
                    <span className={css.divider}>/</span>
                    <span>
                      {period.exited
                        ? period.exited[0] + period.exited[1] || 0
                        : null}
                    </span>
                  </div>
                )}
                {!isTooMutch && !merged && (
                  <div className={css.from}>{formattedFrom}</div>
                )}
                {!isTooMutch && !merged && (
                  <div className={css.to}>{formattedTo}</div>
                )} */}

                {merged || isTabletOrMobile ? null : (
                  <Tooltip title={tooltipContent} className={css.tooltip}>
                    <div className={css.inOut} />
                  </Tooltip>
                )}
              </div>
            );
          })
        : null}
    </div>
  );
};

export default React.memo(Timeline);
