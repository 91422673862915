import api from '@constants/endpoints';
import { MAX_PAGE_SIZE } from '@constants/limits';
import request from '@helpers/request';
import { IBusGroup } from '@interfaces/IBus';
import { item } from '@stores/bus';
import {
  addGroupForBus,
  getStops,
  removeGroupForBus,
  createRouteFromGpx,
  updateRouteFromGpx,
} from '@stores/route';
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector';
import { get, sortBy } from 'lodash';

export const resetGroupsStores = createEvent();
export const resetSelectedGroup = createEvent();
export const setRoute = createEvent<IBusGroup>();

const getGroups = createEffect({
  name: 'getGroups',

  async handler(params: Partial<Record<string, any>>) {
    const response = await request(api.groups.getList, {
      params: { ...params, perPage: MAX_PAGE_SIZE },
    });
    return get(response, ['data', 'items']) as IBusGroup[];
  },
});

export const getGroupsForCustomer = attach({
  effect: getGroups,
  source: { bus: item },
  mapParams: (_: {}, { bus }) => ({
    clientIds: get(bus, ['clients'], []).map((c) => c.id),
  }),
});

export const getGroupsForBus = attach({
  effect: getGroups,
  source: { bus: item },
  mapParams: (_: {}, { bus }) => ({
    workplaceId: get(bus, ['id']),
  }),
});

export const $selectedRoute = createStore<IBusGroup | null>(null)
  .on(setRoute, (_, payload) => payload)
  .on(updateRouteFromGpx.doneData, (current, updated) =>
    get(current, 'id') === get(updated, 'id') ? updated : current,
  )
  .reset([resetSelectedGroup, createRouteFromGpx.done]);
// .reset(resetGroupsStores); // убивает выбранный маршрут при переключении на другую вкладку

export const $busGroups = createStore<IBusGroup[]>([])
  .on(getGroupsForBus.doneData, (_, payload) => payload)
  .reset(resetGroupsStores);

export const $customerGroups = createStore<IBusGroup[]>([])
  .on(getGroupsForCustomer.doneData, (_, payload: IBusGroup[]) =>
    sortBy(payload, 'name'),
  )
  .reset(resetGroupsStores);

export const $allGroups = createStore<IBusGroup[]>([]);

const getGroupsClocks = [
  createRouteFromGpx.done,
  updateRouteFromGpx.done,
  addGroupForBus.done,
  removeGroupForBus.done,
];

sample({
  clock: getGroupsClocks,
  target: getGroupsForBus,
  fn: () => ({}),
});

sample({
  clock: getGroupsClocks,
  target: getGroupsForCustomer,
  fn: () => ({}),
});

sample({
  clock: setRoute,
  target: [getStops],
  fn: ({ id }) => {
    return { groupId: id };
  },
});

sample({
  clock: updateRouteFromGpx.done,
  source: $selectedRoute,
  target: getStops,
  filter: (selectedGroup, { result }) =>
    get(result, 'id') === get(selectedGroup, 'id'),
  fn: (selectedGroup) => {
    return { groupId: get(selectedGroup, 'id', '') };
  },
});
