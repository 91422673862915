import React, { FunctionComponent } from 'react';
import { Form, Input, Button, Card, Spin, Alert } from 'antd';
import authStore, { getToken } from '@stores/auth';
import { useStore } from 'effector-react';
import { Redirect } from 'react-router';
import routes from '@config/routes';
import css from './Login.module.less';
import useTranslate from '@hooks/useTranslate';
import componentContent from './Login.content.json';
import { INPUT_FIELD_MAX_LENGTH } from '@constants/limits';
import useMedia from '@hooks/useMedia';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

interface LoginProps {}

const Login: FunctionComponent<LoginProps> = () => {
  const onFinish = (values: Record<string, any>) => {
    getToken(values);
  };
  const auth = useStore(authStore);
  const content = useTranslate(componentContent);
  const isAuth = !!auth.user;
  const { isTabletOrMobile } = useMedia();

  const tailLayout = isTabletOrMobile
    ? {
        labelCol: { span: 1 },
        wrapperCol: {
          span: 24,
        },
      }
    : {
        wrapperCol: {
          offset: layout.labelCol.span,
          span: layout.wrapperCol.span,
        },
      };

  if (isAuth) return <Redirect to={routes.root.path} />;

  return (
    <div className={css.root}>
      <Spin spinning={!!auth.preloaders.getToken || false}>
        <Card>
          <Form
            {...layout}
            name="auth"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              label={content.username_label}
              name="username"
              rules={[
                {
                  required: true,
                  message: content.username_message,
                },
              ]}
            >
              <Input maxLength={INPUT_FIELD_MAX_LENGTH} />
            </Form.Item>

            <Form.Item
              label={content.password_label}
              name="password"
              rules={[{ required: true, message: content.password_message }]}
            >
              <Input.Password maxLength={INPUT_FIELD_MAX_LENGTH} />
            </Form.Item>

            {/* <Form.Item {...tailLayout} name="remember" valuePropName="checked">
              <Checkbox>Запомнить</Checkbox>
            </Form.Item> */}

            {auth.failGetToken ? (
              <Alert
                className={css.errorText}
                message={content[auth.failGetToken]}
                description={content[`${auth.failGetToken}_description`]}
                type="error"
              />
            ) : null}

            <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!!auth.preloaders.getToken}
                block={isTabletOrMobile}
              >
                {content.login_button_text}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Spin>
    </div>
  );
};

export default Login;
