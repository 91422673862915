import React, { FunctionComponent } from 'react';
import { TooltipProps } from 'recharts';
import { Card } from 'antd';
import useTranslate from '@hooks/useTranslate';
import css from './CustomHaltStatsTooltip.module.less';

interface CustomHaltStatsTooltipProps {
  items?: any[];
}

const CustomHaltStatsTooltip: FunctionComponent<
  TooltipProps & CustomHaltStatsTooltipProps
> = (props) => {
  const content = useTranslate({});
  const label = props.label;
  const items = (props.payload || []).map((item) => {
    return {
      color: item?.fill,
      name: item.name,
      value: item?.value,
    };
  });

  return (
    <Card size="small" title={label} className={css.root}>
      <div className={css.grid}>
        {items.map((item, i) => {
          return (
            <div
              className={css.item}
              key={`${item.name}_${i}`}
              style={{ color: item.color }}
            >
              <div className={css.name}>{content[item.name]}</div>:
              <div className={css.value}>
                {(item.value as number).toFixed(2)}
              </div>
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default CustomHaltStatsTooltip;
