import React, { FunctionComponent } from 'react';
import css from './FareCorrection.module.less';
import componentContent from './FareCorrection.content.json';
import useTranslate from '@hooks/useTranslate';
import { Popover, Input, InputNumber, Button } from 'antd';
import { EditOutlined, CheckOutlined } from '@ant-design/icons';
import { useStore } from 'effector-react';
import { get } from 'lodash';
import $busCalculator, { setCalculatorData } from '@stores/busCalculator';
import cn from 'classnames';
import { useState } from 'react';
import { useEffect } from 'react';

interface FareCorrectionProps {}

const FareCorrection: FunctionComponent<FareCorrectionProps> = () => {
  const content = useTranslate(componentContent);

  const calculatorState = useStore($busCalculator);
  const correction = get(calculatorState, 'cashless');

  const [visible, setVisible] = useState<boolean>(false);
  const [localValue, setValue] = useState<number | undefined>(correction);
  useEffect(() => {
    setValue(correction);
  }, [correction]);

  const hasCorrection = Number.isFinite(correction) && correction !== 0;
  const save = () => {
    setCalculatorData({ cashless: localValue });
    setVisible(false);
  };

  return (
    <Popover
      trigger={['click']}
      visible={visible}
      onVisibleChange={setVisible}
      content={
        <div className={css.correction}>
          <Input.Group compact>
            <InputNumber
              value={localValue || undefined}
              onChange={(value) => setValue(value as number)}
              className={css.correctionInput}
              autoFocus
              onPressEnter={save}
            />
            <Button type="primary" icon={<CheckOutlined />} onClick={save} />
          </Input.Group>
          <div className={css.correctionExtra}>{content.description}</div>
        </div>
      }
    >
      <EditOutlined
        className={cn(css.correctionIcon, { [css.has]: hasCorrection })}
      />
    </Popover>
  );
};

export default FareCorrection;
