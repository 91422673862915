import React, { FunctionComponent, useRef, useEffect } from 'react';
import css from './HaltStats.module.less';
import componentContent from './HaltStats.content.json';
import useTranslate from '@hooks/useTranslate';
import {
  $preloaders,
  $stats,
  allDays,
  $filterParams,
  setFilterParams,
  $selectedHaltStatsParams,
  reset,
} from '@stores/halt-stats';
import { useStore } from 'effector-react';
import { get, map, mapValues, set } from 'lodash';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  Bar,
} from 'recharts';
import { useMemo } from 'react';
import chartColors from '@constants/chartColors';
import { Spin, Radio, Button } from 'antd';
import { TIME_FORMAT } from '@config/format';
import moment from 'moment';
import { ISelectedHaltStatsParams } from '@interfaces/ISelectedHaltStats';
import CustomHaltStatsTooltip from '@components/CustomHaltStatsTooltip';
import { selectedHaltId as $selectedHaltId } from '@stores/gps';

interface HaltStatsProps {}

const HaltStats: FunctionComponent<HaltStatsProps> = () => {
  const content = useTranslate(componentContent);
  const stats = useStore($stats);
  const preloaders = useStore($preloaders);
  const filterParams = useStore($filterParams);
  const selectedHaltId = useStore($selectedHaltId);
  const params = useStore($selectedHaltStatsParams);
  const busStopClassifierId = get(params, ['busStopClassifierId']);
  const snapshotRef = useRef<{
    selectedHaltId: string | null;
    busStopClassifierId: string | undefined;
  }>();

  useEffect(() => {
    if (
      get(snapshotRef, ['current', 'selectedHaltId']) !== selectedHaltId &&
      get(snapshotRef, ['current', 'busStopClassifierId']) ===
        busStopClassifierId
    ) {
      reset();
    }
    snapshotRef.current = {
      selectedHaltId,
      busStopClassifierId,
    };
  }, [selectedHaltId, busStopClassifierId]);

  const data = useMemo(() => {
    const dataMap: Record<string, any> = {};
    mapValues(
      stats,
      (items, dayName: ISelectedHaltStatsParams['dayOfWeek']) => {
        if (filterParams.weekDays.includes(dayName))
          items.forEach((item) => {
            const timeFormatted = moment(item.from, TIME_FORMAT + 'Z').format(
              TIME_FORMAT,
            );
            set(dataMap, [timeFormatted, dayName], item[filterParams.countKey]);
          });
      },
    );
    return map(dataMap, (item, time) => ({ ...item, time }));
  }, [stats, filterParams]);

  return (
    <div className={css.root}>
      <div className={css.chartContainer}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart margin={{ left: -20, right: 16 }} data={data}>
            <XAxis dataKey="time" />
            <YAxis />
            <Tooltip
              cursor={{ opacity: 0.1 }}
              content={<CustomHaltStatsTooltip />}
              wrapperStyle={{ top: 80 }}
              allowEscapeViewBox={{ x: true, y: false }}
            />
            {allDays.map((day, index) => (
              <Bar
                key={day}
                dataKey={day}
                // stroke={chartColors[index]}
                // dot={false}
                // type="monotone"
                // strokeWidth={2}
                fill={chartColors[index]}
                stackId="1"
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className={css.filters}>
        <Radio.Group
          size="small"
          value={filterParams.countKey}
          onChange={(e) => setFilterParams({ countKey: e.target.value })}
        >
          <Radio.Button value="entered">{content.in}</Radio.Button>
          <Radio.Button value="exited">{content.out}</Radio.Button>
        </Radio.Group>
        <Button.Group size="small">
          {allDays.map((day, i) => (
            <Button
              value={day}
              key={day}
              type={filterParams.weekDays.includes(day) ? 'primary' : 'default'}
              style={
                filterParams.weekDays.includes(day)
                  ? {
                      backgroundColor: chartColors[i],
                      borderColor: chartColors[i],
                    }
                  : {}
              }
              onClick={() => {
                if (filterParams.weekDays.includes(day)) {
                  const i = filterParams.weekDays.findIndex((d) => d === day);
                  const weekDays = [...filterParams.weekDays];
                  weekDays.splice(i, 1);
                  setFilterParams({
                    weekDays,
                  });
                } else {
                  setFilterParams({
                    weekDays: [...filterParams.weekDays, day],
                  });
                }
              }}
            >
              {content[day]}
            </Button>
          ))}
        </Button.Group>
      </div>
      {preloaders.getStats ? <Spin spinning className={css.spin} /> : null}
    </div>
  );
};

export default HaltStats;
