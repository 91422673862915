import React, { FunctionComponent, useEffect } from 'react';
import { IRichPeriod } from '@interfaces/IPeriod';
import css from './FragmentList.module.less';
import { useVirtual } from 'react-virtual';
import FragmentListItem from '@components/FragmentListItem';
import ICamera from '../../interfaces/ICamera';
import { useStore } from 'effector-react';
import $video from '@stores/video';
import { isUndefined } from 'lodash';
import classNames from 'classnames';

interface FragmentListProps {
  periods?: IRichPeriod[];
  short?: boolean;
  mobile?: boolean;
  editable?: boolean;
  cameraStatus?: ICamera['status'];
  className?: string;
}

const FragmentList: FunctionComponent<FragmentListProps> = ({
  periods,
  short,
  editable,
  cameraStatus,
  mobile,
  className,
}) => {
  const parentRef = React.useRef(null);

  const rowVirtualizer = useVirtual({
    size: periods ? periods.length : 0,
    parentRef,
    estimateSize: React.useCallback(() => (short ? 40 : 60), [short]),
  });

  const { selected } = useStore($video);
  const selectedSegment = selected ? selected?.segment : null;

  useEffect(() => {
    const selectedSegmentIndex = selectedSegment
      ? periods?.findIndex((s) => s.id === selectedSegment.id)
      : undefined;
    if (
      selectedSegment &&
      !isUndefined(selectedSegmentIndex) &&
      selectedSegmentIndex >= 0
    ) {
      rowVirtualizer.scrollToIndex(selectedSegmentIndex);
    }
    // eslint-disable-next-line
  }, [selectedSegment]);

  return (
    <div className={classNames(css.root, className)} ref={parentRef}>
      <div
        className="ListInner"
        style={{
          height: `${rowVirtualizer.totalSize}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {periods
          ? rowVirtualizer.virtualItems.map((virtualRow) => (
              <div
                key={virtualRow.index}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                <FragmentListItem
                  cameraStatus={cameraStatus}
                  short={short}
                  editable={editable}
                  fragment={periods[virtualRow.index]}
                  mobile={mobile}
                />
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default FragmentList;
