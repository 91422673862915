import React, { FunctionComponent } from 'react';
import css from './News.module.less';
// import componentContent from './News.content.json';
// import useTranslate from '@hooks/useTranslate';
import INews from '@interfaces/INews';
import { Badge } from 'antd';
import { useEffect } from 'react';
import { readNews } from '@stores/news';
import { useRef } from 'react';

interface ItemProps {
  item: INews;
  open: boolean;
}

const Item: FunctionComponent<ItemProps> = ({ item, open }) => {
  // const content = useTranslate(componentContent);
  const openRef = useRef(open);

  useEffect(() => {
    if (openRef.current && !open && !item.read) {
      readNews({ newsId: item.id });
    }
    openRef.current = open;
  }, [open, item.read, item.id]);

  return (
    <div className={css.item}>
      <div className={css.header}>
        <div className={css.title}>
          <Badge status={item.read ? 'default' : 'success'} text={item.title} />
        </div>
      </div>
      <div
        className={css.text}
        dangerouslySetInnerHTML={{
          __html: (item.text || '').replace(/\r?\n|\r/g, '<br>'),
        }}
      />
    </div>
  );
};

export default Item;
