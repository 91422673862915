import React, { FunctionComponent, useRef, useEffect } from 'react';
import {
  MapContainer,
  Marker,
  TileLayer,
  Polyline,
  useMap,
} from 'react-leaflet';
import css from './MapTab.module.less';
import { useStore, useStoreMap } from 'effector-react';
import gps, { $dayList } from '@stores/gps';
import { get } from 'lodash';
import theme from '@config/theme.json';
import L from 'leaflet';
import marker from './marker.svg';
import $video from '@stores/video';

interface MapTabProps {}

const MapProvider: FunctionComponent<MapTabProps> = (props) => {
  return (
    <div className={css.root}>
      <MapContainer
        style={{ height: 288, backgroundColor: 'black' }} // @fragment-frame-height
        center={[50, 50]}
        zoom={13}
      >
        <MapTab {...props} />
      </MapContainer>
    </div>
  );
};

const MapTab: FunctionComponent<MapTabProps> = () => {
  const { selectedLapNumber } = useStore(gps);
  const pointsByLap = useStoreMap({
    store: $dayList,
    keys: [selectedLapNumber],
    fn: (state, [number]) => {
      return get(
        state.find((l) => l.number === number),
        ['track'],
        [],
      );
    },
  });
  const map = useMap();
  const { selected } = useStore($video);
  const segment = selected ? selected?.segment : null;
  const trackRef = useRef<any>();
  const markerRef = useRef<any>();
  const center = get(segment, ['gpsPoints', 0], undefined);
  const points: any[] = pointsByLap.map((p) => [p.latitude, p.longitude]);

  useEffect(() => {
    if (trackRef.current) {
      map.fitBounds(trackRef.current.getBounds().pad(0));
    }
    // eslint-disable-next-line
  }, [trackRef.current, center]);

  const icon = L.divIcon({
    iconSize: [48, 48],
    iconAnchor: [24, 48],
    className: css.icon,
    html: `<img width="48" height="48" src="${marker}"></img>`,
  });

  return (
    <>
      {points.length ? (
        <Polyline
          ref={trackRef}
          // @ts-ignore
          pathOptions={{ fillColor: 'red', color: theme['@primary-color'] }}
          positions={points}
        />
      ) : null}
      {segment && center ? (
        <>
          <Polyline
            ref={markerRef}
            pathOptions={{ fillColor: 'red', color: theme['@success-color'] }}
            positions={segment.gpsPoints}
          />
          <Marker key={center.join()} position={center} icon={icon} />
        </>
      ) : null}
      <TileLayer
        // @ts-ignore
        attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
        // url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
        // url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
    </>
  );
};

export default MapProvider;
