import React, { FunctionComponent, useEffect } from 'react';
import css from './ServiceCamera.module.less';
import componentContent from './ServiceCamera.content.json';
import useTranslate from '@hooks/useTranslate';
import ICamera from '@interfaces/ICamera';
// @ts-ignore
// import { ReactFlvPlayer } from 'react-flv-player';
import SegmentsDownloader from '@components/SegmentsDownloader';
import cn from 'classnames';
import { IRichPeriod } from '@interfaces/IPeriod';
import FragmentList from '@components/FragmentList';
import VideoPreview from '@components/VideoPreview';
import { useStore, useStoreMap } from 'effector-react';
import $date from '@stores/date';
import {
  item as $bus,
  updateStatus,
  preloaders as $busPreloaders,
} from '@stores/bus';
import { Button, Spin } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import CMSPlayer from '../CMSPlayer';
import $video, { setSegment } from '@stores/video';

interface ServiceCameraProps {
  item: ICamera;
  segments: IRichPeriod[];
  mergedSegments: IRichPeriod[];
}

const ServiceCamera: FunctionComponent<ServiceCameraProps> = ({
  item,
  segments,
  mergedSegments,
}) => {
  const content = useTranslate(componentContent);
  const isOffline = !item.enabled || item.status === 'offline';
  const busId = useStoreMap({
    store: $bus,
    keys: [],
    fn: (state) => state.id,
  });
  const isStatusUpdating = useStoreMap({
    store: $busPreloaders,
    keys: [],
    fn: (state) => state.updateStatus || false,
  });

  const dateState = useStore($date);

  const { selected } = useStore($video);
  const selectedSegment = selected ? selected?.segment : null;

  useEffect(() => {
    setSegment(undefined);
  }, [dateState]);

  return (
    <div className={css.root}>
      <div className={css.header}>
        <SegmentsDownloader mergedSegments={mergedSegments} camera={item} />
      </div>
      <div className={css.body}>
        <div className={css.fragments}>
          <div
            className={cn(css.liveSelector, {
              [css.selected]: !selectedSegment,
            })}
            onClick={() => setSegment(undefined)}
          >
            <span className={cn(css.status, { [css.offline]: isOffline })} />{' '}
            {item.name} (live stream)
          </div>
          <FragmentList
            short
            key="service"
            cameraStatus={item.status}
            // editable={}
            periods={segments}
          />
        </div>
        <div className={css.video}>
          <div className={css.title}>
            {selectedSegment ? (
              <>
                {selectedSegment.formattedFrom} - {selectedSegment.formattedTo}
              </>
            ) : (
              <>
                <span
                  className={cn(css.status, { [css.offline]: isOffline })}
                />
                {item.name} (live stream)
              </>
            )}
          </div>
          <div>
            {isOffline ? (
              <Spin spinning={!!isStatusUpdating}>
                <div className={css.requestHolder}>
                  <Button
                    icon={<SyncOutlined />}
                    onClick={() => updateStatus({ urlParams: { busId } })}
                  >
                    {content.updateStatus}
                  </Button>
                </div>
              </Spin>
            ) : selectedSegment ? (
              <VideoPreview className={css.preloadedVideo} />
            ) : (
              <div>
                {/* <ReactFlvPlayer width="100%" height={294} url={item.liveurl} /> */}
                <CMSPlayer playUrl={item.liveurl} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCamera;
