export const DATE_FORMAT = 'DD.MM.YYYY';
export const TIME_FORMAT = 'HH:mm';
export const TIME_FORMAT_WITH_SECONDS = 'HH:mm:ss';
export const FULL_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
export const FULL_TIME_FORMAT_WITH_SECONDS = `${DATE_FORMAT} ${TIME_FORMAT_WITH_SECONDS}`;

export const CLIENT_DATE_FORMAT = DATE_FORMAT;
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';

export const BUS_DEFAULT_TIMEZONE = 'Europe/Moscow';
