import React, { FunctionComponent, useEffect } from 'react';
import css from './BusGroups.module.less';
import componentContent from './BusGroups.content.json';
import useTranslate from '@hooks/useTranslate';
import UploadGpxForRoute from '@components/UploadGpxForRoute';
import {
  $busGroups,
  $selectedRoute,
  getGroupsForBus,
  getGroupsForCustomer,
  resetGroupsStores,
  setRoute,
  $customerGroups,
} from '@stores/groups';
import { useStore } from 'effector-react';
import { Button, Select, Spin } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { item } from '@stores/bus';
import { addGroupForBus, removeGroupForBus } from '@stores/route';
import { get } from 'lodash';

interface BusGroupsProps {}

const BusGroups: FunctionComponent<BusGroupsProps> = () => {
  const content = useTranslate(componentContent);
  const busGroups = useStore($busGroups);
  const customerGroups = useStore($customerGroups);
  const pending = useStore(getGroupsForBus.pending);
  const adding = useStore(addGroupForBus.pending);
  const removing = useStore(removeGroupForBus.pending);
  const bus = useStore(item);
  const selectedRoute = useStore($selectedRoute);

  useEffect(() => {
    getGroupsForCustomer({});
    getGroupsForBus({});
    return () => resetGroupsStores();
  }, []);

  return (
    <div className={css.root}>
      <Spin spinning={pending || removing}>
        <div className={css.grid}>
          {busGroups
            .sort((a, b) => Number(a.id) - Number(b.id))
            .map((g) => (
              <div key={g.id}>
                <Button.Group>
                  <Button
                    type={
                      get(selectedRoute, 'id') === g.id ? 'primary' : 'default'
                    }
                    onClick={() => setRoute(g)}
                  >
                    {g.name}
                  </Button>
                  <UploadGpxForRoute mode="update" groupId={g.id} />
                  <Button
                    icon={<CloseOutlined />}
                    onClick={() =>
                      removeGroupForBus({ groupId: g.id, workplaceId: bus.id })
                    }
                  />
                </Button.Group>
              </div>
            ))}
        </div>
      </Spin>
      <div className={css.actions}>
        <UploadGpxForRoute />
        <Select
          disabled={adding || pending}
          key={busGroups.length}
          value={undefined}
          onChange={(groupId: string) =>
            addGroupForBus({ groupId, workplaceId: bus.id })
          }
          suffixIcon={<PlusOutlined />}
          placeholder={content.addRoute}
          style={{ width: 240 }}
          options={customerGroups
            .filter((cg) => !busGroups.some((bg) => bg.id === cg.id))
            .map((cg) => ({
              value: cg.id,
              label: cg.name,
            }))}
        />
      </div>
    </div>
  );
};

export default BusGroups;
