import { useStore, useStoreMap } from 'effector-react';
import { $list as $mergeRequestsList } from '@stores/merge';
import { sum } from 'lodash';
import { $list as $newsList } from '@stores/news';

const useNotiCount = () => {
  const requestsCount = useStoreMap({
    store: $mergeRequestsList,
    keys: [],
    fn(state) {
      const requests = {
        PROCESS: 0,
        COMPLETE: 0,
        ALL: 0,
      };
      state.forEach((r) => {
        if (r.status === 'PROCESS') requests.PROCESS = requests.PROCESS + 1;
        if (r.status === 'COMPLETE') requests.COMPLETE = requests.COMPLETE + 1;
      });
      requests.ALL = requests.ALL = requests.ALL + 1;
      return requests;
    },
  });

  const news = useStore($newsList);
  const unreadedNewsCount = news.filter((n) => !n.read).length;

  return {
    unreaded: sum([
      requestsCount.COMPLETE,
      requestsCount.PROCESS,
      unreadedNewsCount,
    ]),
    all: sum([requestsCount.ALL, news.length]),
    unreadedNewsCount: unreadedNewsCount,
  };
};

export default useNotiCount;
