import React, { FunctionComponent } from 'react';
import css from './BusListSelector.module.less';
import { useStore } from 'effector-react';
import { list } from '@stores/buses';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import Item from './Item';
import { useState } from 'react';
import { Input } from 'antd';
import componentContent from './BusListSelector.content.json';
import useTranslate from '@hooks/useTranslate';
import { useMemo } from 'react';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { useRef } from 'react';

interface BusListSelectorProps {}

const BusListSelector: FunctionComponent<BusListSelectorProps> = () => {
  const content = useTranslate(componentContent);
  const buses = useStore(list);
  const { busId } = useParams<{ busId: string }>();
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [collapsed, setCollapseState] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (collapsed) setSearchValue(undefined);
  }, [collapsed]);

  const filteredList = useMemo(() => {
    return buses.filter((b) =>
      b.name
        .toLocaleLowerCase()
        .includes((searchValue || '').toLocaleLowerCase()),
    );
  }, [buses, searchValue]);

  useEffect(() => {}, [busId]);

  if (collapsed)
    return (
      <div className={css.root}>
        <div
          className={css.collapseIcon}
          onClick={() => setCollapseState(false)}
        >
          <SearchOutlined />
        </div>
      </div>
    );

  return (
    <div ref={containerRef} className={css.root}>
      <div className={css.busList}>
        <Input
          // size="small"
          suffix={
            <CloseOutlined
              className={css.close}
              onClick={() => setCollapseState(true)}
            />
          }
          autoFocus
          className={css.search}
          placeholder={content.search}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <div className={css.elevator}>
          {filteredList.map((b) => (
            <Item key={b.id} active={`${b.id}` === busId} bus={b} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BusListSelector;
