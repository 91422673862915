import React, { FunctionComponent } from 'react';
// import css from './MobileBottomWidget.module.less';
// import componentContent from './MobileBottomWidget.content.json';
// import useTranslate from '@hooks/useTranslate';

interface MobileBottomWidgetProps {
  children: JSX.Element;
}

const MobileBottomWidget: FunctionComponent<MobileBottomWidgetProps> = ({
  children,
}) => {
  // const content = useTranslate(componentContent);

  return <div>{children}</div>;
};

export default MobileBottomWidget;
