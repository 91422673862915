import React, { FunctionComponent, useEffect } from 'react';
import css from './Stats.module.less';
import StatsTimeline from '../StatsTimeline';
// import componentContent from './Stats.content.json';
// import useTranslate from '@hooks/useTranslate';
import { reset } from '@stores/stats';
import { Alert } from 'antd';
// import request from '@helpers/request';
// import moment from 'moment';
// import { SERVER_DATE_FORMAT } from '@config/format';
// import { Button } from 'antd';

interface StatsProps {}

const Stats: FunctionComponent<StatsProps> = () => {
  // const content = useTranslate(componentContent);
  useEffect(() => {
    return () => reset();
  }, []);

  return (
    <div className={css.root}>
      <Alert
        style={{ marginBottom: 16 }}
        message="Раздел статистики включен для вас в демо-режиме. Мы будем рады обратной связи."
      />
      {/* <div style={{ marginBottom: 32 }}>
        <Button
          onClick={() => {
            request(
              {
                url: '/api/stat/passenger-traffic/recalculate',
                method: 'POST',
              },
              {
                params: {
                  to: moment().format(SERVER_DATE_FORMAT),
                  from: moment()
                    .subtract(2, 'month')
                    .format(SERVER_DATE_FORMAT),
                },
              },
            );
          }}
        >
          Пересчитать последние два месяца
        </Button>
      </div> */}
      <StatsTimeline />
    </div>
  );
};

export default Stats;
