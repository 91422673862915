import React, { FunctionComponent } from 'react';
import css from './Daycount.module.less';
import componentContent from './Daycount.content.json';
import useTranslate from '@hooks/useTranslate';
import { useStoreMap } from 'effector-react';
import { get } from 'lodash';
import useMedia from '@hooks/useMedia';
import classNames from 'classnames';
import useSummary from '@hooks/useSummary';
import useAdmin from '@hooks/useAdmin';
import $busCalculator from '@stores/busCalculator';
import { Spin } from 'antd';
import { $preloaders as $datePreloaders } from '@stores/date';

const Daycount: FunctionComponent<{}> = () => {
  const content = useTranslate(componentContent);
  const isAdmin = useAdmin();
  const { eventSumIn, eventSumOut, adminEventSumIn, adminEventSumOut } =
    useSummary();
  const countDirection = useStoreMap({
    store: $busCalculator,
    keys: [],
    fn: (state) => get(state, 'calculation_event') || null,
  });


  const loadingSingle = useStoreMap({
    store: $datePreloaders,
    keys: [],
    fn: (state) => !!state.getPrepareDayStats,
  });

  const { isTabletOrMobile } = useMedia();

  return (
    <Spin spinning={loadingSingle}>
      <div className={css.root}>
        <div className={css.countContainer}>
          {countDirection === 'ENTERED' || isAdmin ? (
            <div
              className={classNames(css.inContainer, {
                [css.mobile]: isTabletOrMobile,
              })}
            >
              <div
                className={classNames(css.label, { [css.single]: !isAdmin })}
              >
                {content.in}
              </div>
              <div
                className={classNames(css.inCount, {
                  [css.mobile]: isTabletOrMobile,
                  [css.hided]: countDirection === 'EXITED',
                  [css.selectedCount]: countDirection === 'ENTERED',
                  [css.singleCount]: !isAdmin,
                })}
              >
                {eventSumIn || 0}
              </div>
            </div>
          ) : null}
          {countDirection === 'EXITED' || isAdmin ? (
            <>
              {isAdmin ? <div className={css.divider} /> : null}
              <div className={css.outContainer}>
                <div
                  className={classNames(css.label, { [css.single]: !isAdmin })}
                >
                  {content.out}
                </div>
                <div
                  className={classNames(css.outCount, {
                    [css.mobile]: isTabletOrMobile,
                    [css.hided]: countDirection === 'ENTERED',
                    [css.selectedCount]: countDirection === 'EXITED',
                    [css.singleCount]: !isAdmin,
                  })}
                >
                  {eventSumOut || 0}
                </div>
              </div>
            </>
          ) : null}
        </div>
        {isAdmin ? (
          <div className={css.original}>
            <div className={css.originalTitle}>{content.original}: </div>
            <div>
              {content.in}: {adminEventSumIn || 0}
            </div>

            <div>
              {content.out}: {adminEventSumOut || 0}
            </div>
          </div>
        ) : null}
      </div>
    </Spin>
  );
};

export default Daycount;
