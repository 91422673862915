import {
  abortRequestsPending,
  getRequests,
  startRequestsPending,
} from '@stores/merge';
import { useStore, useStoreMap } from 'effector-react';
import { useEffect } from 'react';
import { $list as $mergeRequestsList } from '@stores/merge';
import $auth from '@stores/auth';
import { getList as getBuses } from '@stores/buses';
import { getList as getNews } from '@stores/news';
import { getList as getMessages } from '@stores/messages';
import { get } from 'lodash';
import { getCustomerGroups } from '@stores/customer-groups';
import { getFaqCount } from '@stores/faq';

const useCommonData = () => {
  const auth = useStore($auth);
  const isAuth = !!auth.user;
  const userId = get(auth, ['user', 'id']);
  const isAdmin = get(auth, ['user', 'role']) === 'ADMIN';

  useEffect(() => {
    if (isAuth) {
      getBuses();
      getRequests();
      getNews();
      getMessages();
      getFaqCount();
    }
  }, [isAuth]);

  useEffect(() => {
    if (userId && !isAdmin) {
      getCustomerGroups({ customerId: userId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const processedRequestsCount = useStoreMap({
    store: $mergeRequestsList,
    keys: [],
    fn(state) {
      return state.filter((r) => r.status === 'PROCESS').length;
    },
  });

  useEffect(() => {
    if (processedRequestsCount) {
      startRequestsPending();
      return () => abortRequestsPending();
    }
  }, [processedRequestsCount]);
};

export default useCommonData;
