import React, { FunctionComponent } from 'react';
import Desktop from '@layouts/Desktop';
import Mobile from '@layouts/Mobile';
import useMedia from '@hooks/useMedia';
import { useEffect } from 'react';
import { getMe } from '@stores/auth';

interface RootLayoutProps {
  isAuth: boolean;
  children?: React.ReactNode;
}

const RootLayout: FunctionComponent<RootLayoutProps> = ({
  isAuth,
  children,
}) => {
  // const content = useTranslate(componentContent);
  const { isBigScreen, isTabletOrMobile } = useMedia();

  useEffect(() => {
    if (isAuth) getMe();
  }, [isAuth]);

  if (isBigScreen) return <Desktop isAuth={isAuth}>{children}</Desktop>;
  if (isTabletOrMobile) return <Mobile isAuth={isAuth}>{children}</Mobile>;
  return null;
};

export default RootLayout;
