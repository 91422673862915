import moment from 'moment-timezone';
import { get } from 'lodash';
import { TIME_FORMAT } from '@config/format';
import IPeriod from '@interfaces/IPeriod';

interface IConfig {
  merged?: boolean;
}

const createTimelineParams = (
  results: { data: { segments: IPeriod[] } }[],
  timezone: string,
  config?: IConfig,
) => {
  let totalMin = 0;
  let totalMax = 0;
  const isMerged = get(config, ['merged'], false);

  results.forEach((response) => {
    const segments = get(
      response,
      ['data', isMerged ? 'merged_segments' : 'segments'],
      [],
    );
    if (!segments.length) return;

    const firstSegment = segments[0];
    const lastSegment = segments[segments.length - 1];
    const penultimateSegment = segments[segments.length - 2];

    const minValue = firstSegment
      ? moment(firstSegment.start_time).startOf('hour').valueOf()
      : 0;
    const maxValue = lastSegment
      ? moment(
          lastSegment.end_time ||
            penultimateSegment.end_time ||
            firstSegment.end_time,
        )
          .add(1, 'hour')
          .startOf('hour')
          .valueOf()
      : 0;

    if ((totalMin && minValue < totalMin) || !totalMin) {
      totalMin = minValue;
    }

    if ((totalMax && maxValue > totalMax) || !totalMax) {
      totalMax = maxValue;
    }
  });

  const fullLength = totalMax - totalMin;

  const formattedTotalMin = totalMin
    ? moment(totalMin).tz(timezone).format(TIME_FORMAT)
    : '00:00';
  const formattedTotalMax = totalMax
    ? moment(totalMax).tz(timezone).format(TIME_FORMAT)
    : '00:00';

  return {
    totalMax,
    totalMin,
    formattedTotalMin,
    formattedTotalMax,
    fullLength,
  };
};

export default createTimelineParams;
