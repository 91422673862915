import { useStore } from 'effector-react';
import React, { FunctionComponent } from 'react';
import messages from '@stores/messages';
import { Alert } from 'antd';
import css from './Messages.module.less';

interface MessagesProps {}

const Messages: FunctionComponent<MessagesProps> = () => {
  const { list } = useStore(messages);

  return (
    <div className={css.root}>
      {list.map((m) => (
        <Alert
          key={m.id}
          message={m.title}
          type="info"
          closable
          description={m.text}
        />
      ))}
    </div>
  );
};

export default Messages;
