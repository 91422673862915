import { DATE_FORMAT } from '@config/format';
import { item } from '@stores/bus';
import dateStore from '@stores/date';
import {
  abortPositionPending,
  position as $position,
  selectedLapNumber as $selectedLapNumber,
  $dayList,
  startPositionPending,
} from '@stores/gps';
import { useStore } from 'effector-react';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { Pane } from 'react-leaflet';
import L from 'leaflet';
import { Marker } from 'react-leaflet';
import { ILap } from '@interfaces/IGpsPoint';

interface BusCurrentPositionProps {}

const BusCurrentPosition: FunctionComponent<BusCurrentPositionProps> = () => {
  const position = useStore($position);
  const selectedLapNumber = useStore($selectedLapNumber);
  const laps = useStore($dayList);
  const date = useStore(dateStore);
  const formattedDate = date?.formattedDate;

  const isCurrentLap = useMemo(() => {
    let currentLap = null;
    const now = moment();

    const formattedToday = moment().format(DATE_FORMAT);
    laps.forEach((l) => {
      if (now.isAfter(moment(l.start))) currentLap = l;
    });
    return (
      formattedToday === formattedDate &&
      !!currentLap &&
      (currentLap as ILap).number === selectedLapNumber
    );
  }, [selectedLapNumber, laps, formattedDate]);

  const bus = useStore(item);

  useEffect(() => {
    if (isCurrentLap) {
      startPositionPending({ busId: bus.id });
    } else {
      abortPositionPending();
    }
    return () => abortPositionPending();
  }, [bus.id, isCurrentLap]);

  const positionIcon = L.divIcon({
    iconSize: [40, 40],
    iconAnchor: [20, 20],
    pane: 'positionPane',
    className: '',
    html: `
    <svg width="31" height="34" viewBox="0 0 31 34" fill="none" transform="rotate(${position?.direction})">
      <path d="M15.4966 0L0 34L15.4966 25.6735V0Z" fill="#52C41A"/>
      <path d="M15.4966 0L30.9932 34L15.4966 25.6735V0Z" fill="#48A61A"/>
    </svg>
  `,
  });

  const positionMarker =
    position && isCurrentLap ? (
      <Marker
        position={[position.latitude, position.longitude]}
        icon={positionIcon}
      />
    ) : null;
  return <Pane name="positionPane">{positionMarker}</Pane>;
};

export default BusCurrentPosition;
