import {
  createStore,
  createEvent,
  createEffect,
  combine,
  sample,
} from 'effector';
import request from '@helpers/request';
import api from '@constants/endpoints';
import createPreloadersStore from './preloaders';
import IBus, { IBusGroup } from '@interfaces/IBus';
import { findKey, forEach, get } from 'lodash';
import { AxiosRequestConfig } from 'axios';
import moment, { Moment } from 'moment-timezone';
import { BUS_DEFAULT_TIMEZONE } from '@config/format';
import saveResponseAsFile from '@helpers/saveResponseAsFile';
import { RangePickerProps } from 'rc-picker';
import { getList, UNGROUP_ID, list as $busesList } from '@stores/buses';

export const reset = createEvent('Buses store reset');
export type ReportPeriod = 'year' | 'month' | 'quarter' | 'week' | 'date';
export type TickType = 'day' | 'week' | 'month';
export type DataKey = 'entered' | 'exited' | 'fare';
export type StatsItem = {
  cashless: number;
  entered: number;
  exited: number;
  fare: number;
  id: IBus['id'];
  salary: number;
  start_time: string;
};
export type ChartItem = {
  name: string;
  timeValue: string;
} & Record<IBus['id'], Pick<StatsItem, DataKey>>;

interface IParamsState {
  period: ReportPeriod;
  date: Moment;
  customDate: RangePickerProps<Moment>['value'];
  busRouteIds: string[];
  selectedBusIds: IBus['id'][];
  disabledButton: boolean;
}

interface IBarChartSource {
  dataBy: {
    bus: ChartItem[];
    route: ChartItem[];
  };
  selectedBuses: IBus[];
  reportTimezone: string;
  axisTicks: number[];
  tickFormat: string;
  tickExpandedFormat: string;
  period: string;
  tickType: TickType;
  lineChartTicks: number[];
  routeIds: IBusGroup['id'][];
}

type ReportRequestOptions = {
  startdate: string;
  enddate: string;
  period: ReportPeriod;
  selectedBuses: IBus[];
  selectedTimezone: string;
};

export const setParams = createEvent<Record<string, any>>('set report params');
export const hoverBus = createEvent<IBus['id'] | null>('set hovered bus id');
export const setRef = createEvent<IBus['id'] | null>('set reference line');
export const setDataKey = createEvent<DataKey>('set data key');
export const setGroupByKey = createEvent<'bus' | 'route'>('set groupby key');
export const setHoveredData = createEvent<ChartItem | null>('set hovered data');

const getTickParams = (period: ReportPeriod) => {
  let tickType: TickType = 'day';
  let halfTick = [12, 'hour'];
  let tickFormat = 'dd';
  let tickExpandedFormat = 'dd';

  switch (period) {
    case 'date':
      tickType = 'day';
      halfTick = [12, 'hour'];
      tickFormat = 'dd';
      tickExpandedFormat = 'dd, DD MMMM';
      break;
    case 'week':
      tickType = 'day';
      halfTick = [12, 'hour'];
      tickFormat = 'dd';
      tickExpandedFormat = 'dd, DD MMMM';
      break;
    case 'month':
      tickType = 'day';
      halfTick = [12, 'hour'];
      tickFormat = 'DD';
      tickExpandedFormat = 'dd, DD MMMM';
      break;
    case 'quarter':
      tickType = 'month';
      halfTick = [2, 'week'];
      tickFormat = 'MMMM';
      tickExpandedFormat = 'MMMM';
      break;
    case 'year':
      tickType = 'month';
      halfTick = [2, 'week'];
      tickFormat = 'MMMM';
      tickExpandedFormat = 'MMMM';
      break;
  }

  return { tickType, halfTick, tickFormat, tickExpandedFormat };
};

const addDataEffect = ({
  item,
  map,
  mark,
  id,
  entered,
  exited,
  fare,
}: {
  item: ChartItem;
  map: Map<any, any>;
  mark: string;
  id: string;
  entered: number;
  exited: number;
  fare: number;
}) => {
  if (item) {
    map.set(mark, {
      ...item,
      [id]: {
        entered: get(item, [id, 'entered']) + entered,
        exited: get(item, [id, 'exited']) + exited,
        fare: get(item, [id, 'fare']) + fare,
      },
    });
  }
};

export const exportStats = createEffect({
  name: 'exportStats',
  async handler({
    selectedBuses,
    startdate,
    enddate,
    period,
  }: ReportRequestOptions) {
    const params: AxiosRequestConfig['params'] = {
      from: startdate,
      to: enddate,
      workplaceIds: selectedBuses.map((bus) => bus.id),
      groupby: getTickParams(period).tickType,
    };

    const resultsResponse = await request(api.stats.export, {
      params,
    });

    saveResponseAsFile(resultsResponse);
  },
});

export const getStats = createEffect({
  name: 'getStats',
  async handler(options: ReportRequestOptions) {
    const { selectedBuses, selectedTimezone, startdate, enddate, period } =
      options;
    const dataByBuses: Record<IBus['id'], StatsItem[]> = {};
    const routesMap: Record<IBusGroup['id'], IBus['id'][]> = {
      [UNGROUP_ID]: [],
    };

    selectedBuses.forEach((bus) => (dataByBuses[bus.id] = []));
    const { tickType, halfTick, tickFormat, tickExpandedFormat } =
      getTickParams(period);

    const requestParams: AxiosRequestConfig['params'] = {
      dateFrom: startdate,
      dateTo: enddate,
      groupby: tickType,
      workplaces: selectedBuses.map((bus) => bus.id),
    };

    const resultsResponse = await request(api.stats.list, {
      params: requestParams,
    });

    const results: StatsItem[] = resultsResponse.data;

    results.forEach((result, i) => {
      const currentBus = selectedBuses.find((bus) => bus.id === result.id);
      if (!currentBus) return;
      dataByBuses[result.id].push(result);
      const currentRoute = currentBus.group;
      if (currentRoute) {
        if (routesMap[currentRoute.id]) {
          if (!routesMap[currentRoute.id].includes(result.id))
            routesMap[currentRoute.id].push(result.id);
        } else {
          routesMap[currentRoute.id] = [result.id];
        }
      } else {
        routesMap[UNGROUP_ID].push(result.id);
      }
    });

    const chartBusDataMap = new Map();
    const chartRouteDataMap = new Map();

    let axisTick = moment(startdate)
      .tz(selectedTimezone)
      .startOf(tickType)
      .valueOf();
    const startTick = moment(startdate)
      .tz(selectedTimezone)
      .startOf(tickType)
      .add(...halfTick)
      .valueOf();
    const endTick = moment(enddate)
      .tz(selectedTimezone)
      .endOf(tickType)
      .add(...halfTick)
      .valueOf();

    const ticks: number[] = [];
    const axisTicks: number[] = [startTick];
    for (
      let currentTick = startTick;
      currentTick <= endTick;
      currentTick = moment(currentTick).add(1, tickType).valueOf()
    ) {
      const markInTimeLine = moment(currentTick).tz(selectedTimezone);
      const markInTimelineFormatted = markInTimeLine.format();
      ticks.push(currentTick);
      const barChartBusDataItem: Record<string, any> = {
        timeValue: currentTick,
        name: currentTick,
      };
      const barChartRouteDataItem: Record<string, any> = {
        timeValue: currentTick,
        name: currentTick,
      };
      selectedBuses.forEach(
        (bus) =>
          (barChartBusDataItem[bus.id] = { entered: 0, exited: 0, fare: 0 }),
      );
      chartBusDataMap.set(markInTimelineFormatted, barChartBusDataItem);
      Object.keys(routesMap).forEach(
        (routeId) =>
          (barChartRouteDataItem[routeId] = { entered: 0, exited: 0, fare: 0 }),
      );
      chartRouteDataMap.set(markInTimelineFormatted, barChartRouteDataItem);
      axisTick = moment(axisTick).add(1, tickType).valueOf();
      axisTicks.push(axisTick);
    }
    forEach(dataByBuses, (busData, busId) => {
      busData.forEach((event) => {
        const routeId = findKey(routesMap, (busIds) =>
          // @ts-ignore
          busIds.includes(+busId),
        );
        const { entered, exited, fare, start_time } = event;
        const markInTimeline = moment
          .tz(start_time, selectedTimezone)
          .startOf(tickType)
          .add(...halfTick);
        const markInTimelineFormatted = markInTimeline.format();
        const createdBusDataObject = chartBusDataMap.get(
          markInTimelineFormatted,
        );
        const createdRoutesDataObject = chartRouteDataMap.get(
          markInTimelineFormatted,
        );
        addDataEffect({
          mark: markInTimelineFormatted,
          item: createdBusDataObject,
          map: chartBusDataMap,
          id: busId,
          entered,
          exited,
          fare,
        });
        addDataEffect({
          mark: markInTimelineFormatted,
          item: createdRoutesDataObject,
          map: chartRouteDataMap,
          id: routeId as string,
          entered,
          exited,
          fare,
        });
      });
    });

    const now = moment();
    const feature = now.clone().add(...halfTick);
    const halfTickValue = now.diff(feature);
    let lineChartTicks = [...axisTicks];
    lineChartTicks.shift();
    lineChartTicks = lineChartTicks.map((t) => t + halfTickValue);

    const source: IBarChartSource = {
      routeIds: Object.keys(routesMap),
      period,
      selectedBuses,
      reportTimezone: selectedTimezone,
      dataBy: {
        bus: Array.from(chartBusDataMap.values()),
        route: Array.from(chartRouteDataMap.values()),
      },
      axisTicks,
      tickFormat,
      tickExpandedFormat,
      tickType,
      lineChartTicks,
    };

    return source;
  },
});

export const barChartSource = createStore<IBarChartSource>({
  dataBy: {
    bus: [],
    route: [],
  },
  selectedBuses: [],
  reportTimezone: BUS_DEFAULT_TIMEZONE,
  axisTicks: [],
  tickFormat: 'dd',
  tickExpandedFormat: 'dd',
  period: '',
  tickType: 'day',
  lineChartTicks: [],
  routeIds: [],
})
  .on(getStats.done, (state, { result }) => result)
  .reset(reset);

const setParamsHandler = (
  state: IParamsState,
  newParams: Record<string, any>,
) => {
  const newState = { ...state, ...newParams };
  if (newState.selectedBusIds.length) {
    newState.disabledButton = false;
  } else {
    newState.disabledButton = true;
  }
  return newState;
};

const initialParams: IParamsState = {
  period: 'date',
  date: moment(),
  customDate: [null, null],
  busRouteIds: [],
  selectedBusIds: [],
  disabledButton: false,
};

const params = createStore<IParamsState>(initialParams)
  .on(setParams, setParamsHandler)
  .on(getStats.done, (state) => ({ ...state, disabledButton: true }))
  .on(getList.done, (state, { result: { unsorted } }) => ({
    ...state,
    selectedBusIds: unsorted.map((bus) => bus.id),
  }))
  .reset(reset);

sample({
  source: [$busesList, params],
  target: params,
  clock: [reset],
  fn: ([list, params]) => ({
    ...params,
    selectedBusIds: list.map((b) => b.id),
  }),
});

const preloaders = createPreloadersStore([getStats, exportStats]);

export const hoveredBusId = createStore<IBus['id'] | null>(null).on(
  hoverBus,
  (_, payload) => payload,
);

export const activeRef = createStore<string | null>(null)
  .on(setRef, (_, payload) => payload)
  .on(getStats.done, () => null);

export const dataKey = createStore<DataKey>('entered').on(
  setDataKey,
  (_, payload) => payload,
);

export const groupByKey = createStore<'bus' | 'route'>('bus').on(
  setGroupByKey,
  (_, payload) => payload,
);

export const hoveredRef = createStore<ChartItem | null>(null).on(
  setHoveredData,
  (_, payload) => payload,
);

const stats = combine({
  activeRef,
  preloaders,
  barChartSource,
  params,
  hoveredBusId,
  dataKey,
  groupByKey,
});

export const touch = createEvent<boolean>('touch stats filters form');
export const $touched = createStore<boolean>(false).on(
  touch,
  (payload) => payload,
);

export default stats;
