import React, { FunctionComponent } from 'react';
import css from './MergeSegmentsButton.module.less';
import componentContent from './MergeSegmentsButton.content.json';
import useTranslate from '@hooks/useTranslate';
import { Tooltip } from 'antd';
import { VideoCameraOutlined } from '@ant-design/icons';
import { openModal } from '@stores/merge';
import MergeModal from '@components/MergeModal';

interface MergeSegmentsButtonProps {}

const MergeSegmentsButton: FunctionComponent<MergeSegmentsButtonProps> = () => {
  const content = useTranslate(componentContent);

  return (
    <>
      <Tooltip title={content.merge}>
        <VideoCameraOutlined onClick={() => openModal()} className={css.root} />
      </Tooltip>
      <MergeModal />
    </>
  );
};

export default MergeSegmentsButton;
