import { BUS_DEFAULT_TIMEZONE, SERVER_DATE_FORMAT } from '@config/format';
import api from '@constants/endpoints';
import request from '@helpers/request';
import {
  IHaltStatsItem,
  ISelectedHaltStatsParams,
} from '@interfaces/ISelectedHaltStats';
import { item } from '@stores/bus';
import createPreloadersStore from '@stores/preloaders';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { get } from 'lodash';
import moment from 'moment';

type FilterParams = {
  countKey: 'entered' | 'exited';
  weekDays: ISelectedHaltStatsParams['dayOfWeek'][];
};

export const allDays: ISelectedHaltStatsParams['dayOfWeek'][] = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
];

export const setParams = createEvent<Partial<ISelectedHaltStatsParams> | null>(
  'set selected halt data',
);

export const setFilterParams = createEvent<Partial<FilterParams>>(
  'set selected halt filter params',
);

export const reset = createEvent('reset halt stats store');

export const getStats = createEffect({
  name: 'getStats',
  async handler(params: Partial<ISelectedHaltStatsParams>) {
    const responses = await Promise.all(
      allDays.map((dayOfWeek) =>
        request(api.stats.getHaltStats, {
          params: { ...params, dayOfWeek, groupMinutes: 60 },
        }),
      ),
    );
    const daysMap = responses.reduce((acc, response, index) => {
      acc[allDays[index]] = response.data;
      return acc;
    }, {} as Record<ISelectedHaltStatsParams['dayOfWeek'], any[]>);
    return daysMap;
  },
});

export const $selectedHaltStatsParams =
  createStore<Partial<ISelectedHaltStatsParams> | null>(null)
    .on(setParams, (state, payload) => payload)
    .reset(reset);

export const $stats = createStore<
  Record<ISelectedHaltStatsParams['dayOfWeek'], IHaltStatsItem[]>
>({
  MONDAY: [],
  TUESDAY: [],
  WEDNESDAY: [],
  THURSDAY: [],
  FRIDAY: [],
  SATURDAY: [],
  SUNDAY: [],
})
  .on(getStats.done, (state, { result }) => result)
  .reset(reset);

export const $preloaders = createPreloadersStore([getStats]);

sample({
  source: [item],
  clock: setParams,
  target: getStats,
  filter: (_, params) => !!params,
  fn: ([bus], params) => {
    return {
      ...params,
      dateFrom: moment()
        .tz(get(bus, ['timezone']) || BUS_DEFAULT_TIMEZONE)
        .subtract(3, 'month')
        .startOf('day')
        .format(SERVER_DATE_FORMAT),
      dateTo: moment()
        .tz(get(bus, ['timezone']) || BUS_DEFAULT_TIMEZONE)
        .format(SERVER_DATE_FORMAT),
      groupId: get(bus, ['group', 'id']),
    };
  },
});

export const $filterParams = createStore<FilterParams>({
  countKey: 'entered',
  weekDays: [...allDays],
}).on(setFilterParams, (state, payload) => ({ ...state, ...payload }));

sample({
  source: [],
  clock: setParams,
  target: reset,
  filter: (_, params) => !params,
});
