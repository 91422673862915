import React, { FunctionComponent, useState } from 'react';
import css from './RouteSelector.module.less';
import componentContent from './RouteSelector.content.json';
import useTranslate from '@hooks/useTranslate';
import { Popover, Button, Input } from 'antd';
import { useStore } from 'effector-react';
import { busGroups, UNGROUP_ID } from '@stores/buses';
import { IBusGroup } from '@interfaces/IBus';
import List from './List';
import { values } from 'lodash';
import { $customerGroups } from '@stores/customer-groups';

interface RouteSelectorProps {
  value: IBusGroup['id'][];
  onChange: (value: IBusGroup['id'][]) => void;
  listPreloading: boolean;
}

const RouteSelector: FunctionComponent<RouteSelectorProps> = ({
  value,
  onChange,
  listPreloading,
}) => {
  const content = useTranslate(componentContent);
  const groupsMap = useStore(busGroups) || {};
  const customerGroups = useStore($customerGroups);
  const groups = values(groupsMap).filter((g) => g) as IBusGroup[];
  const localOnChange = (key: string) => {
    const newValue = [...value];
    if (value.includes(key)) {
      const index = value.findIndex((bid) => bid === key);
      newValue.splice(index, 1);
    } else {
      newValue.push(key);
    }
    onChange(newValue);
  };

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const filteredList = (
    groups.filter((g) => g.id !== UNGROUP_ID).length ? groups : customerGroups
  ).filter((g) =>
    g.name.toLowerCase().includes((searchValue || '').toLowerCase()),
  );

  const menu = (
    <div className={css.menu}>
      <div>
        <Input.Search
          autoFocus
          className={css.search}
          placeholder={content.searchPlaceholder}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          allowClear
        />
      </div>
      <List list={filteredList} value={value} onChange={localOnChange} />
      <div className={css.footer}>
        <Input.Group compact>
          <Button onClick={() => onChange(groups.map((b) => b.id))}>
            {content.selectAll}
          </Button>
          <Button onClick={() => onChange([])}>{content.clear}</Button>
        </Input.Group>
      </div>
    </div>
  );

  return (
    <div className={css.root}>
      <div className={css.busSelector}>
        <Popover
          content={menu}
          trigger={['click']}
          overlayClassName={css.popover}
        >
          <Button
            className={css.button}
            loading={listPreloading}
            disabled={listPreloading}
          >
            {value.length
              ? `${content.selected}: ${value.length}`
              : content.selectBus}
          </Button>
        </Popover>
      </div>
    </div>
  );
};

export default RouteSelector;
