import routes from '@config/routes';
import { values } from 'lodash';
import { matchPath, useLocation } from 'react-router';

export default () => {
  const location = useLocation();
  const currentRoute = values(routes).find(
    (route) =>
      route.path !== '/' &&
      !!matchPath(location.pathname, {
        path: route.path,
        exact: true,
        strict: false,
      }),
  );
  return currentRoute;
};
