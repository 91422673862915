import React, { FunctionComponent } from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router';
import { useStore } from 'effector-react';
import authStore, { clearTargetUrl, setTargetUrl } from '@stores/auth';
import routes from '@config/routes';

interface AuthRouteProps extends RouteProps {}

const AuthRoute: FunctionComponent<AuthRouteProps> = ({
  children,
  render,
  ...rest
}) => {
  const auth = useStore(authStore);
  const isAuth = !!auth.user;
  const hasPermission = true; // TODO Роли
  const toRootRoute = (
    <Route
      {...rest}
      render={({ location }) => (
        <Redirect
          to={{
            pathname: routes.root.path,
            state: { from: location },
          }}
        />
      )}
    />
  );

  const toLoginRoute = (
    <Route
      {...rest}
      render={({ location }) => (
        <Redirect
          to={{
            pathname: routes.login.path,
            state: { from: location },
          }}
        />
      )}
    />
  );

  const toOriginalRoute = <Route {...rest} render={render} />;
  const location = useLocation();

  if (isAuth) {
    if (hasPermission) {
      clearTargetUrl();
      return toOriginalRoute;
    } else {
      return toRootRoute;
    }
  } else {
    setTargetUrl(location.pathname + location.search);
    return toLoginRoute;
  }
};

export default AuthRoute;
