import React, { FunctionComponent, useState } from 'react';
import css from './MobileServiceTab.module.less';
import componentContent from './MobileServiceTab.content.json';
import useTranslate from '@hooks/useTranslate';
import CameraSelector from '@components/CameraSelector';
import CMSPlayer from '@components/CMSPlayer';
import { useStore } from 'effector-react';
import bus from '@stores/bus';
import MobileBottomPanel from '@components/MobileBottomPanel';
import VideoPreview from '@components/VideoPreview';
import { Popup } from 'antd-mobile';
import FragmentList from '@components/FragmentList';
import $servicePeriods from '@stores/servicePeriods';
import { OrderedListOutlined, CloseOutlined } from '@ant-design/icons';
import $video, { setSegment } from '@stores/video';
import { Empty } from 'antd';
import ICamera from '@interfaces/ICamera';
import FragmentListItem from '@components/FragmentListItem';

interface MobileServiceTabProps {}

const MobileServiceTab: FunctionComponent<MobileServiceTabProps> = () => {
  const content = useTranslate(componentContent);
  const { serviceCameras } = useStore(bus);
  const [mobilePopupVisible, setMobilePopoupVisible] = useState(false);
  const { segmentsByCamera } = useStore($servicePeriods);
  const selectedCamera = serviceCameras.find((c) => c.selected);
  const { selected } = useStore($video);
  const selectedSegment = selected ? selected?.segment : null;
  const isOnline = selectedCamera && selectedCamera.status === 'online';

  return (
    <div className={css.root}>
      <div className={css.cameraSelectorContainer}>
        <CameraSelector mode="service" />
      </div>
      {isOnline ? (
        <div key={(selectedCamera as ICamera).id} className={css.videoPreview}>
          {selectedSegment ? (
            <VideoPreview className={css.preloadedVideo} />
          ) : (
            <div className={css.preloadedVideo}>
              <CMSPlayer playUrl={(selectedCamera as ICamera).liveurl} />
            </div>
          )}
        </div>
      ) : (
        <Empty />
      )}
      {isOnline ? (
        <MobileBottomPanel
          onClick={
            !selectedSegment ? () => setMobilePopoupVisible(true) : undefined
          }
        >
          {selectedSegment ? (
            <div className={css.selectedSegment}>
              <div className={css.segment}>
                <FragmentListItem
                  onClick={() => {
                    setSegment(undefined);
                    setMobilePopoupVisible(true);
                  }}
                  selected={false}
                  short
                  borderless
                  fragment={selectedSegment}
                />
              </div>
              <CloseOutlined
                className={css.resetSegment}
                onClick={() => {
                  setSegment(undefined);
                  setMobilePopoupVisible(false);
                }}
              />
            </div>
          ) : (
            <>
              <OrderedListOutlined />
              {content.segments}
            </>
          )}
        </MobileBottomPanel>
      ) : null}
      <Popup
        visible={mobilePopupVisible && !selectedSegment}
        onMaskClick={() => setMobilePopoupVisible(false)}
      >
        <div className={css.popup}>
          <FragmentList
            short
            key="service"
            cameraStatus={selectedCamera ? selectedCamera.status : undefined}
            periods={
              segmentsByCamera && selectedCamera
                ? segmentsByCamera[selectedCamera.id]
                : []
            }
          />
        </div>
      </Popup>
    </div>
  );
};

export default MobileServiceTab;
