import { UNGROUP_ID } from '@stores/buses';

export default (a: any, b: any) => {
  let aName = a.name;
  let bName = b.name;
  if (a.id === UNGROUP_ID) aName = UNGROUP_ID;
  if (b.id === UNGROUP_ID) bName = UNGROUP_ID;
  if (aName > bName) return 1;
  if (aName < bName) return -1;
  return 0;
};
