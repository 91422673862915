import api from '@constants/endpoints';
import createQueryListStore from '@helpers/createQueryList';
import request from '@helpers/request';
import INews from '@interfaces/INews';
import createPreloadersStore from '@stores/preloaders';
import { combine, createEffect, createStore } from 'effector';
import update from 'immutability-helper';

const {
  list: all,
  getList: getAll,
  getListAttached: getAllAttached,
  pagination,
  filters,
} = createQueryListStore<INews>({
  endpoint: api.news.all,
  listName: 'news',
  defaultFilterValues: { sortBy: 'dateFrom', sortType: 'DESC', type: 'NEWS' },
});

export const getList = createEffect({
  name: 'getList',
  async handler() {
    const response = await request(api.news.list);
    return response.data;
  },
});

export const readNews = createEffect({
  name: 'readNews',
  async handler({ newsId }: { newsId: INews['id'] }) {
    const response = await request(api.news.read, { urlParams: { newsId } });
    return response.data;
  },
});

const readHandler = (
  state: INews[],
  { params }: { params: { newsId: INews['id'] } },
) => {
  const index = state.findIndex((n) => n.id === params.newsId);
  if (index >= 0) {
    return update(state, {
      $splice: [[index, 1, { ...state[index], read: true }]],
    });
  }
};

export const $list = createStore<INews[]>([])
  .on(getList.done, (state, { result }) => result.items)
  .on(readNews.done, readHandler);

all.on(readNews.done, readHandler);

const $preloaders = createPreloadersStore([getList, getAll]);

export { getAll, getAllAttached };

const $news = combine({
  list: $list,
  preloaders: $preloaders,
  all,
  pagination,
  filters,
});

export default $news;
