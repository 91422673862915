import {
  createStore,
  createEvent,
  Event,
  createEffect,
  combine,
} from 'effector';
import IAuthUser from '@interfaces/IAuthUser';
import storage from '@helpers/localStorage';
import createPreloadersStore from './preloaders';
// import { message } from 'antd';
import api from '@constants/endpoints';
import request from '@helpers/request';
import BASIC_AUTH_TOKEN from '@constants/basicAuthToken';
import qs from 'qs';
import { get } from 'lodash';

type IUserState = Partial<IAuthUser> | null;
type IFailLogin =
  | 'fail_login_network'
  | 'fail_login'
  | 'fail_login_unexpected'
  | 'token_expired'
  | 'user_inactive'
  | 'user_blocked';

export enum LogoutReasonEnum {
  USER_COMMAND = 'user_command',
  TOKEN_EXPIRED = 'token_expired',
  INACTIV = 'user_inactive',
  BLOCKED = 'user_blocked',
}

export const saveUser: Event<IAuthUser> = createEvent('Save User');
export const logout = createEvent<LogoutReasonEnum>('Log Out');
export const saveTokens: Event<Record<string, string>> =
  createEvent('Save Tokens');
export const failLogin = createEvent<IFailLogin>('Fail login');
export const setTargetUrl = createEvent<string>('Set Target Route');
export const clearTargetUrl = createEvent('Clear Target Route');

export const getToken = createEffect({
  name: 'getToken',
  async handler(creds: Record<string, string>) {
    const Authorization = BASIC_AUTH_TOKEN;
    const data = qs.stringify({
      grant_type: 'password',
      ...creds,
    });
    try {
      const response = await request(
        api.auth.getToken,
        {
          headers: { Authorization },
          data,
        },
        { catch: false },
      );
      return response;
    } catch (e) {
      const code = parseInt(get(e, ['response', 'status']));
      if (e.message === 'Network Error') {
        failLogin('fail_login_network');
      } else if (code === 400) {
        failLogin('fail_login');
      } else if (code === 401) {
        failLogin('user_inactive');
      } else if (code === 403) {
        failLogin('user_blocked');
      } else {
        failLogin('fail_login_unexpected');
      }
      throw e;
    }
  },
});

export const getMe = createEffect({
  name: 'getMe',
  async handler() {
    const response = await request(api.auth.getMe);
    return response;
  },
});

export const logOutWithRequest = createEffect({
  name: 'logOutWithRequest',
  async handler() {
    try {
      const response = await request(api.auth.logOut);
      return response;
    } catch (error) {
      logout(LogoutReasonEnum.USER_COMMAND);
    }
  },
});

getToken.done.watch((data) => {
  storage.writeObject('tokens', data.result.data);
});

const initialTokens = storage.readObject('tokens') || {};
const tokens = createStore<Record<string, string>>(initialTokens)
  .on(getToken.done, (state, data) => data.result?.data)
  .on(getToken.fail, (state, data) => {
    // message.error(data.error.message);
  })
  .on(saveTokens, (state, data) => data)
  .on(logout, () => ({}));

tokens.watch((tokens: Record<string, string>) =>
  storage.writeObject('tokens', tokens),
);

const preloaders = createPreloadersStore([getToken, getMe, logOutWithRequest]);

const initialUserState = (storage.readObject('user') as IAuthUser) || null;
const user = createStore<IUserState>(initialUserState)
  .on(logout, (state: IUserState) => null)
  .on(getToken.done, (state: IUserState) => ({}))
  .on(logOutWithRequest.done, (state: IUserState) => null)
  .on(getMe.done, (state: IUserState, { result }) => result.data);
user.watch((user: IUserState) => storage.writeObject('user', user));

const failGetToken = createStore<IFailLogin | null>(null)
  .on(failLogin, (state, data) => data)
  .on(logout, (state, data) =>
    data === 'token_expired' ? 'token_expired' : null,
  );

const targetUrl = createStore<string | null>(null)
  .on(setTargetUrl, (state, url) => url)
  .on(clearTargetUrl, () => null);

const auth = combine({ preloaders, user, failGetToken, targetUrl });

export default auth;
