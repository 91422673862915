import { createStore, createEvent, createEffect, combine } from 'effector';
import request from '@helpers/request';
import api from '@constants/endpoints';
import createPreloadersStore from './preloaders';
import INews from '@interfaces/INews';

export const reset = createEvent('messages store reset');

export const getList = createEffect({
  name: 'getList',
  async handler() {
    const response = await request(api.messages.list);
    return response.data;
  },
});

const list = createStore<INews[]>([])
  .on(getList.done, (state, { result }) => result)
  .reset(reset);

const preloaders = createPreloadersStore([getList]);

const messages = combine({ list, preloaders });

export default messages;
