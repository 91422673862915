import React, { FunctionComponent } from 'react';
import Timeline from '@components/Timeline';
import { sortBy } from 'lodash';
import bus from '@stores/bus';
import { useStore, useStoreMap } from 'effector-react';
import { combineData } from '@stores/periods';
import useMedia from '@hooks/useMedia';
import css from './Timelines.module.less';
import classNames from 'classnames';

interface TimelinesProps {}

const Timelines: FunctionComponent<TimelinesProps> = () => {
  const { cameras } = useStore(bus);

  const segments = useStoreMap({
    store: combineData,
    keys: [],
    fn(state) {
      return state.segments;
    },
  });

  const formattedTotalMax = useStoreMap({
    store: combineData,
    keys: [],
    fn(state) {
      return state.timelineParams.formattedTotalMax;
    },
  });

  const formattedTotalMin = useStoreMap({
    store: combineData,
    keys: [],
    fn(state) {
      return state.timelineParams.formattedTotalMin;
    },
  });

  const { isTabletOrMobile } = useMedia();

  return (
    <>
      {sortBy(cameras, 'stream_id').map((camera) =>
        camera.selected ? (
          <div
            className={classNames(css.container, {
              [css.mobile]: isTabletOrMobile,
            })}
            key={camera.id}
          >
            <Timeline
              periods={segments[camera.id] || []}
              formattedTotalMin={formattedTotalMin}
              formattedTotalMax={formattedTotalMax}
            />
          </div>
        ) : null,
      )}
    </>
  );
};

export default React.memo(Timelines);
