import React, { FunctionComponent } from 'react';
import ICamera from '@interfaces/ICamera';
import css from './Camera.module.less';
import cn from 'classnames';

interface CameraProps {
  item: ICamera;
}

const Camera: FunctionComponent<CameraProps> = ({ item }) => {
  return (
    <div className={css.root}>
      <span
        className={cn(css.status, {
          [css.statusOnline]: item.enabled || item.status === 'online',
          [css.statusOffline]: !item.enabled || item.status === 'offline',
        })}
      ></span>
      {item.name}
    </div>
  );
};

export default Camera;
