import React, {
  FunctionComponent,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MapContainer, Marker } from 'react-leaflet';
import css from './GpsTab.module.less';
import { useStore } from 'effector-react';
import gps, { setHalt, setVideoSegment } from '@stores/gps';
import { Coordinate, IStop } from '@interfaces/IGpsPoint';
import { Empty, Spin } from 'antd';
import L from 'leaflet';
import marker from './marker.svg';
import HaltsListItem from '@components/HaltsListItem';
import LapsTimeLine from '@components/LapsTimeLine';
import LapsTabs from '@components/LapsTabs';
import VideoPreview from '@components/VideoPreview';
import { get } from 'lodash';
import { CloseOutlined, OrderedListOutlined } from '@ant-design/icons';
import MobileBottomPanel, {
  BOTTOM_PANEL_HEIGHT,
} from '@components/MobileBottomPanel';
import useMedia from '@hooks/useMedia';
import classNames from 'classnames';
import useTranslate from '@hooks/useTranslate';
import componentContent from './GpsTab.content.json';
import { Popup } from 'antd-mobile';
import {
  $selectedHaltStatsParams,
  reset as resetHaltStats,
  setParams,
} from '@stores/halt-stats';
import HaltStats from '@components/HaltStats';
import {
  $preloaders as $routePreloaders,
  $selectedTab,
  $stops,
  $track,
  getStopsForCurrentBus,
  resetRoute,
} from '@stores/route';
import CommonGpsMap from '@components/CommonGpsMap';
import RouteTabs from '@components/RouteTabs';
import RoutePriceTable from '@components/RoutePriceTable';
import { useParams } from 'react-router';
import RouteActions from '@components/RouteActions';
import IRouteStop from '@interfaces/IRouteStops';
import BusGroups from '@components/BusGroups';
import { $selectedRoute, resetSelectedGroup, setRoute } from '@stores/groups';
import { item as $bus } from '@stores/bus';

// const getRouteStopIcon = (halt: IHalt) => {
//   return L.divIcon({
//     iconSize: [16, 16],
//     iconAnchor: [8, 8],
//     className: css.icon,
//     pane: 'haltPane',
//     html: `
//       <svg width="16" height="17" viewBox="0 0 16 17" fill="none" transform="rotate(${halt.direction})">
//         <path d="M2 15L8 2L14 15" stroke="${theme['@primary-color']}" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
//       </svg>`,
//   });
// };

const DEFAULT_PREVIEW_HEIGHT = 480;

interface GpsTabProps {}

const GpsTab: FunctionComponent<GpsTabProps> = (props) => {
  const {
    dayList,
    preloaders,
    selectedLapNumber,
    selectedHaltId,
    selectedSegment,
  } = useStore(gps);
  const selectedHaltStatsData = useStore($selectedHaltStatsParams);
  // const zones = useStore($zones);
  // const focusedZones = useStore($focusedZoneIds);
  // const zoneTracks: Coordinate[][] = zones.map((z) =>
  //   (z.track || []).map((p) => [p.latitude, p.longitude]),
  // );
  const { isTabletOrMobile, isAlbum } = useMedia();
  const content = useTranslate(componentContent);
  const { busId } = useParams<{ busId: string }>();

  const routeStops = useStore($stops);
  const routePreloaders = useStore($routePreloaders);
  const selectedRouteTab = useStore($selectedTab);
  const selectedRoute = useStore($selectedRoute);
  const bus = useStore($bus);

  const group = get(bus, ['group']);
  const mainGroupId = get(group, 'id');
  const selectedRouteId = get(selectedRoute, 'id');

  const prevSnapshotRef = useRef<any>();

  const selectedFullDataLap = useMemo(() => {
    return dayList.find((l) => l.number === selectedLapNumber);
  }, [dayList, selectedLapNumber]);

  const routeTrack = useStore($track);
  const isRoute = selectedLapNumber === 'route';

  const track = get(
    dayList.find((l) => l.number === selectedLapNumber) || dayList[0],
    ['track'],
    [],
  );

  const points: Coordinate[] = isRoute
    ? selectedRoute
      ? (get(selectedRoute, ['track'], []) || []).map((p) => [
          p.latitude,
          p.longitude,
        ])
      : routeTrack
    : track.map((p) => [p.latitude, p.longitude]);

  const {
    stops,
    startId,
    endId,
  }: { stops: (IStop | IRouteStop)[]; startId?: string; endId?: string } =
    useMemo(() => {
      const currentLapStops: IStop[] = get(
        selectedFullDataLap,
        'busStopDtoList',
        [],
      );

      if (isRoute) {
        let startId: string | undefined = undefined;
        let endId: string | undefined = undefined;
        const stops = routeStops.map((rh) => {
          if (rh.routeDirection === 'FORWARD') startId = rh.id;
          if (rh.routeDirection === 'REVERSE') endId = rh.id;
          return rh;
        });
        return { stops, startId, endId };
      } else {
        return { stops: currentLapStops };
      }
    }, [selectedFullDataLap, routeStops, isRoute]);

  const icon = L.divIcon({
    iconSize: [16, 16],
    iconAnchor: [8, 8],
    className: css.icon,
    pane: 'haltPane',
    html: `
      <svg width="20" height="20" viewBox="0 0 20 20" class="${css.marker}">
        <circle cx="10" cy="10" r="8" />
      </svg>`,
  });

  const stopsMarkers: JSX.Element[] = useMemo(() => {
    return stops.map((h, i) => {
      return (
        <Marker
          key={[h.latitude, h.longitude, i].join()}
          position={[h.latitude, h.longitude]}
          eventHandlers={{
            click: () => setHalt(h.id),
          }}
          // icon={isRoute ? getRouteStopIcon(h) : icon}
          icon={icon}
        />
      );
    });
    // eslint-disable-next-line
  }, [stops]);

  const selectedHalt = stops.find((s) => s.id === selectedHaltId);

  const anchorIcon = L.divIcon({
    iconSize: [48, 48],
    iconAnchor: [24, 48],
    className: css.icon,
    pane: 'anchorPane',
    html: `<img width="48" height="48" src="${marker}"></img>`,
  });

  const anchorMarker = selectedHalt ? (
    <Marker
      position={[selectedHalt.latitude, selectedHalt.longitude]}
      icon={anchorIcon}
    />
  ) : null;

  const spinning =
    !!preloaders.getList ||
    !!routePreloaders.getStops ||
    !!routePreloaders.setDirection;

  const mapAnchor = useRef<HTMLDivElement>(null);
  const [mapAvailableHeight, setMapAvailableHeight] = useState(320);

  useLayoutEffect(() => {
    function updateSize() {
      if (!isTabletOrMobile) return;
      const anchorElement = get(mapAnchor, ['current']);
      if (!anchorElement) return;
      const anchorRect = anchorElement.getBoundingClientRect();
      if (!anchorRect) return;
      const anchorPosition = anchorRect.top;
      setMapAvailableHeight(
        window.innerHeight - anchorPosition - BOTTOM_PANEL_HEIGHT,
      );
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [isTabletOrMobile, isAlbum]);

  const [mobilePopupVisible, setMobilePopoupVisible] = useState(false);

  useEffect(() => {
    return () => resetSelectedGroup(); // сбросить выбранный маршрут
  }, []);

  useEffect(() => {
    if (!['stops', 'price'].includes(selectedRouteTab)) return;
    if (group && selectedRouteId && mainGroupId !== selectedRouteId) {
      setRoute(group);
    } else if (!group) {
      resetRoute();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRouteTab]);

  useEffect(() => {
    getStopsForCurrentBus();
    return () => {
      resetHaltStats();
      // resetRoute();
    };
  }, [busId]);

  useEffect(() => {
    setVideoSegment(null);
  }, [dayList]);

  useEffect(() => {
    const selectedSegmentId = get(selectedSegment, 'id');
    const oldSelectedSegmentId = get(prevSnapshotRef, [
      'current',
      'selectedSegmentId',
    ]);
    const oldSelectedHaltId = get(prevSnapshotRef, [
      'current',
      'selectedHaltId',
    ]);
    if (
      selectedSegmentId === oldSelectedSegmentId &&
      selectedHaltId !== oldSelectedHaltId
    ) {
      setVideoSegment(null);
    }
    const newSnapshot = { selectedHaltId, selectedSegmentId };
    prevSnapshotRef.current = newSnapshot;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHaltId]);

  return (
    <div>
      <div>
        <LapsTabs />
        {isTabletOrMobile ? null : <LapsTimeLine />}
      </div>
      <div
        className={classNames(css.container, {
          [css.mobile]: isTabletOrMobile,
          [css.route]: isRoute,
        })}
      >
        {isTabletOrMobile ? <div ref={mapAnchor}></div> : null}
        {selectedSegment ? (
          <div
            className={classNames(css.videoContainer, {
              [css.mobile]: isTabletOrMobile,
            })}
            style={{
              height: isTabletOrMobile
                ? mapAvailableHeight
                : DEFAULT_PREVIEW_HEIGHT,
            }}
          >
            <VideoPreview
              segment={selectedSegment}
              className={css.segmentVideo}
            />
            <div className={css.closeIcon}>
              <CloseOutlined onClick={() => setVideoSegment(null)} />
            </div>
          </div>
        ) : selectedHaltStatsData ? (
          <div
            className={classNames(css.statsContainer, {
              [css.mobile]: isTabletOrMobile,
            })}
          >
            <HaltStats />
            <div className={css.closeIcon}>
              <CloseOutlined onClick={() => setParams(null)} />
            </div>
          </div>
        ) : (
          <div
            className={classNames(css.map, {
              [css.mobile]: isTabletOrMobile,
            })}
          >
            <MapContainer
              style={{
                height: isTabletOrMobile
                  ? mapAvailableHeight
                  : DEFAULT_PREVIEW_HEIGHT,
                backgroundColor: 'black',
              }}
              center={[50, 50]}
              key={mapAvailableHeight}
              zoom={13}
            >
              <CommonGpsMap
                selectedHaltId={selectedHaltId}
                halts={stops}
                points={points}
                haltMarkers={stopsMarkers}
                anchorMarker={anchorMarker}
                {...props}
              />
            </MapContainer>
          </div>
        )}
        {isTabletOrMobile ? (
          <>
            <MobileBottomPanel onClick={() => setMobilePopoupVisible(true)}>
              {selectedHalt ? (
                <div className={css.mobileSelectedHalt}>
                  <HaltsListItem
                    isRoute={isRoute}
                    halt={null}
                    singleStop={selectedHalt}
                    onClick={() => setHalt(null)}
                    onClose={() => {
                      setHalt(null);
                      setMobilePopoupVisible(false);
                    }}
                  />
                </div>
              ) : (
                <>
                  <OrderedListOutlined />
                  {content.halts}
                </>
              )}
            </MobileBottomPanel>
            <Popup
              visible={mobilePopupVisible && !selectedHalt}
              onMaskClick={() => setMobilePopoupVisible(false)}
            >
              <div className={css.mobileHaltsContainer}>
                {stops.length ? (
                  stops.map((s, i) => (
                    <HaltsListItem
                      index={i}
                      isRoute={isRoute}
                      key={`${s.id}_${selectedLapNumber}`}
                      halt={null}
                      singleStop={s}
                      selected={s.id === selectedHaltId}
                      onClick={setHalt}
                    />
                  ))
                ) : (
                  <Empty />
                )}
              </div>
            </Popup>
          </>
        ) : (
          <>
            {isRoute ? (
              <div className={css.routeTabs}>
                <RouteTabs />
              </div>
            ) : null}
            <div className={classNames(css.content, { [css.route]: isRoute })}>
              <Spin spinning={spinning}>
                {isRoute && selectedRouteTab === 'price' ? (
                  <div>
                    <RoutePriceTable />
                  </div>
                ) : isRoute && selectedRouteTab === 'actions' ? (
                  <RouteActions />
                ) : isRoute && selectedRouteTab === 'routes' ? (
                  <BusGroups />
                ) : stops.length ? (
                  stops.map((s, i) => (
                    <HaltsListItem
                      index={i}
                      isRoute={isRoute}
                      key={`${s.id}_${selectedLapNumber}`}
                      halt={null}
                      singleStop={s}
                      selected={s.id === selectedHaltId}
                      onClick={setHalt}
                      isStart={startId === s.id}
                      isEnd={endId === s.id}
                      isMiddle={
                        (!!startId || !!endId) &&
                        s.id !== startId &&
                        s.id !== endId
                      }
                    />
                  ))
                ) : (
                  <Empty />
                )}
              </Spin>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GpsTab;
