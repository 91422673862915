import React, { FunctionComponent } from 'react';
import css from './HaltsListItem.module.less';
import componentContent from './HaltsListItem.content.json';
import useTranslate from '@hooks/useTranslate';
import { IStop } from '@interfaces/IGpsPoint';
import IBus from '@interfaces/IBus';
import moment from 'moment-timezone';
import { TIME_FORMAT } from '@config/format';
import { VideoCameraOutlined } from '@ant-design/icons';
import { setVideoSegment } from '@stores/gps';
import { get } from 'lodash';

interface StopProps {
  item: IStop;
  timezone: string;
  bus: IBus;
}

const Stop: FunctionComponent<StopProps> = ({ item, timezone, bus }) => {
  const content = useTranslate(componentContent);
  const s = item;
  const routeStopName = get(item, ['routeStop', 'name']);
  const groupName = get(item, ['routeStop', 'groupName']);

  return (
    <React.Fragment>
      <div>
        {moment(s.arrival).tz(timezone).format(TIME_FORMAT)}
        {routeStopName ? ` [${routeStopName}]` : null}:{' '}
        {groupName ? groupName : null}
      </div>
      <div className={css.in}>
        {content.in}:{' '}
        <span>
          <b>{s.entered}</b>
        </span>
      </div>
      <div className={css.out}>
        {content.out}:{' '}
        <span>
          <b>{s.exited}</b>
        </span>
      </div>
      <div>
        {bus.count_passengers_inside ? (
          <>
            {content.passengers}:{' '}
            <span>
              <b>{s.passengers}</b>
            </span>
          </>
        ) : null}
      </div>
      <div>
        {s.segments.map((segment) => (
          <VideoCameraOutlined
            key={segment.id}
            className={css.videoSelector}
            onClick={(e) => {
              e.stopPropagation();
              setVideoSegment(segment);
            }}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export default Stop;
