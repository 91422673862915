import React, { FunctionComponent } from 'react';
import css from './MobileGpsTab.module.less';
// import componentContent from './MobileGpsTab.content.json';
// import useTranslate from '@hooks/useTranslate';
import GpsTab from '@components/GpsTab';

interface MobileGpsTabProps {}

const MobileGpsTab: FunctionComponent<MobileGpsTabProps> = () => {
  // const content = useTranslate(componentContent);

  return (
    <div className={css.root}>
      <GpsTab />
    </div>
  );
};

export default MobileGpsTab;
