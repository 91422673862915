import React, { FunctionComponent } from 'react';
import css from './ServiceTab.module.less';
// import componentContent from './ServiceTab.content.json';
// import useTranslate from '@hooks/useTranslate';
import { useStore } from 'effector-react';
import { serviceCameras as $serviceCameras } from '@stores/bus';
import $servicePeriods from '@stores/servicePeriods';
import { Divider } from 'antd';
import ServiceCamera from '@components/ServiceCamera';
import CameraSelector from '../CameraSelector';

interface ServiceTabProps {}

const ServiceTab: FunctionComponent<ServiceTabProps> = () => {
  // const content = useTranslate(componentContent);
  const serviceCameras = useStore($serviceCameras);
  const { mergedSegmentsByCamera, segmentsByCamera } =
    useStore($servicePeriods);

  return (
    <div>
      {serviceCameras.length > 1 ? (
        <>
          <div className={css.header}>
            <CameraSelector mode="service" />
          </div>
          <Divider />
        </>
      ) : null}
      <div className={css.cameras}>
        {serviceCameras
          .filter((c) => c.selected)
          .map((c) => (
            <div key={c.id} className={css.camera}>
              <ServiceCamera
                segments={segmentsByCamera ? segmentsByCamera[c.id] : []}
                mergedSegments={
                  mergedSegmentsByCamera ? mergedSegmentsByCamera[c.id] : []
                }
                item={c}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default ServiceTab;
