import React, { FunctionComponent } from 'react';
// import css from './News.module.less';
// import componentContent from './News.content.json';
// import useTranslate from '@hooks/useTranslate';
import { useStore } from 'effector-react';
import { $list } from '@stores/news';
import Item from './Item';

interface NewsProps {
  open: boolean;
}

const News: FunctionComponent<NewsProps> = ({ open }) => {
  // const content = useTranslate(componentContent);
  const list = useStore($list);

  return (
    <div>
      {list.map((n) => (
        <Item item={n} key={n.id} open={open} />
      ))}
    </div>
  );
};

export default News;
