import { createStore, createEvent, createEffect, combine } from 'effector';
import request from '@helpers/request';
import api from '@constants/endpoints';
import createPreloadersStore from './preloaders';
import {
  Coordinate,
  IFullDataLap,
  IHalt,
  ILap,
  IReachLap,
  IStop,
} from '@interfaces/IGpsPoint';
import { item as busStore } from '@stores/bus';
import moment from 'moment';
import { BUS_DEFAULT_TIMEZONE, TIME_FORMAT } from '@config/format';
import IBus from '@interfaces/IBus';
import schedulePending from '@helpers/schedulePending';
import IPeriod from '@interfaces/IPeriod';

interface IBusPosition {
  latitude: number;
  longitude: number;
  direction: number;
}

interface ILapsTimeLineState {
  totalStartTimeValue: number;
  totalEndTimeValue: number;
  totalStartFormatted: string;
  totalEndFormatted: string;
  reachLaps: IReachLap[];
}

type PointsState = Record<
  ILap['number'],
  { number: number; start: string; end: string; points: Coordinate[] }
>;

export const reset = createEvent('gps store reset');
export const setLap = createEvent<ILap['number'] | 'route' | null>('set lap');
export const setHalt = createEvent<IHalt['id'] | null>('set halt');
export const setVideoSegment = createEvent<IPeriod | null>('set halt video');

export const setTerminal = createEffect({
  name: 'setTerminal',
  async handler({
    preloaderId,
    ...data
  }: {
    busStopClassifierId: IStop['classifier_id'];
    workplaceId: IBus['id'];
    preloaderId: IStop['classifier_id'];
  }) {
    await request(api.gps.setTerminal, {
      data,
    });
  },
});

export const getDayList = createEffect({
  name: 'getDayList',
  async handler(params: { workplaceId: string; date: string }) {
    const lapsResponse = await request(
      api.gps.lapsByDay,
      {
        params,
      },
      { catch: false },
    );
    return lapsResponse.data as IFullDataLap[];
  },
});

export const getPosition = createEffect({
  name: 'getPosition',
  async handler({ busId }: { busId: IBus['id'] }) {
    const response = await request(api.gps.getPosition, {
      urlParams: { busId },
    });
    return response.data;
  },
});

export const startPositionPending = createEvent<{ busId: IBus['id'] }>(
  'startPositionPending',
);
export const abortPositionPending = createEvent('abortPositionPending');

schedulePending('positionPending', {
  effect: getPosition,
  timeout: 1000,
  start: startPositionPending,
  abort: abortPositionPending,
});

export const $dayList = createStore<IFullDataLap[]>([])
  .on(getDayList.done, (state, { result }) => result)
  .reset(reset);

export const position = createStore<IBusPosition | null>(null)
  .on(getPosition.done, (state, { result }) => result)
  .reset(reset);

const createTimeline = (
  state: ILapsTimeLineState | null,
  { result: laps }: { result: IFullDataLap[] },
) => {
  if (!laps.length) return null;
  const now = moment();
  const firstLap: ILap = laps[0];
  const lastLap: ILap = laps[(laps as ILap[]).length - 1];
  const bus = busStore.getState();
  const timezone = bus.timezone || BUS_DEFAULT_TIMEZONE;
  let totalStart = moment(firstLap.start).tz(timezone);
  let totalEnd = moment(lastLap.end).tz(timezone);

  laps.forEach((lap) => {
    const startDate = moment(lap.start).tz(timezone);
    const endDate = moment(lap.end).tz(timezone);
    if (startDate.isBefore(totalStart)) totalStart = startDate;
    if (endDate.isAfter(totalEnd)) totalEnd = endDate;
  });

  const totalStartTimeValue = totalStart.valueOf() || now.valueOf();
  const totalEndTimeValue = totalEnd.valueOf() || now.valueOf();
  const totalStartFormatted =
    totalStart.format(TIME_FORMAT) || now.format(TIME_FORMAT);
  const totalEndFormatted =
    totalEnd.format(TIME_FORMAT) || now.format(TIME_FORMAT);

  const reachLaps: IReachLap[] = [];

  laps.forEach((lap) => {
    const { start, end } = lap;
    const startDate = moment(start).tz(timezone);
    const endDate = moment(end).tz(timezone);
    const durationValue = endDate.diff(startDate);
    const durationObject = moment.duration(durationValue);
    const formattedDuration = `${durationObject.hours()}h ${durationObject.minutes()}m ${durationObject.seconds()}s`;
    const lapStartTimeValue = startDate.valueOf();
    const lapEndTimeValue = endDate.valueOf();

    reachLaps.push({
      ...lap,
      startValue: lapStartTimeValue,
      endValue: lapEndTimeValue,
      startPositionPercent: 0,
      endPositionPercent: 0,
      percent: 0,
      formattedDuration,
      durationValue,
      formattedStart: startDate.format(TIME_FORMAT),
      formattedEnd: endDate.format(TIME_FORMAT),
    });
  });

  const fullLenghtValue = totalEndTimeValue - totalStartTimeValue;

  reachLaps.forEach((lap, i) => {
    const lapLenghtValue = lap.endValue - lap.startValue;
    const lapPercent = (lapLenghtValue / fullLenghtValue) * 100;
    if (i === 0) {
      lap.startPositionPercent = 0;
      lap.endPositionPercent = 100 - lapPercent;
    } else {
      const startPositionPercent =
        ((lap.startValue - totalStartTimeValue) / fullLenghtValue) * 100;
      const endPositionPercent = 100 - (startPositionPercent + lapPercent);
      lap.startPositionPercent = startPositionPercent;
      lap.endPositionPercent = endPositionPercent;
    }
    lap.percent = lapPercent;
  });

  const data: ILapsTimeLineState | null = {
    totalStartTimeValue,
    totalEndTimeValue,
    totalStartFormatted,
    totalEndFormatted,
    reachLaps,
  };

  return data;
};

export const selectedLapNumber = createStore<ILap['number'] | 'route' | null>(
  null,
)
  .on(setLap, (state, payload) => payload)
  .reset(reset);

export const selectedHaltId = createStore<IHalt['id'] | null>(null)
  .on(setHalt, (state, payload) => payload)
  .reset(reset);

export const selectedSegment = createStore<IPeriod | null>(null)
  .on(setVideoSegment, (state, payload) => payload)
  .reset(reset);

export const preloaders = createPreloadersStore([getDayList, setTerminal]);

export const timeline = createStore<ILapsTimeLineState | null>(null).on(
  getDayList.done,
  createTimeline,
);

export const recreateStops = createEffect({
  name: 'recreateStops',

  async handler({
    workplaceId,
    date,
  }: {
    workplaceId?: string;
    date?: string;
  }) {
    const response = await request(api.route.generateStops, {
      urlParams: { workplaceId },
      params: { date },
    });
    return response.data;
  },
});

const gps = combine({
  preloaders,
  timeline,
  selectedLapNumber,
  selectedHaltId,
  selectedSegment,
  dayList: $dayList,
});

export default gps;
