import $busCalculator from '@stores/busCalculator';
import { useStore, useStoreMap } from 'effector-react';
import { get } from 'lodash';
import useSummary from '@hooks/useSummary';
import { item } from '@stores/bus';
import { IBusGroup } from '@interfaces/IBus';
import { MIN_FARE } from '@constants/limits';
import { $daySummary } from '@stores/day-summary';

type Direction = 'in' | 'out';

export default () => {
  const calculatorState = useStore($busCalculator);
  const daySummary = useStore($daySummary);
  const type_calculation: IBusGroup['type_calculation'] = useStoreMap({
    store: item,
    keys: [],
    fn: (state) => get(state, ['group', 'type_calculation'], 'FIXED'),
  });
  const { eventSumIn, eventSumOut } = useSummary();
  const count = {
    in: eventSumIn,
    out: eventSumOut,
  };

  const correction = get(calculatorState, 'cashless', 0);
  const fare = get(calculatorState, 'fare', MIN_FARE) || MIN_FARE;
  const percent = get(calculatorState, 'salary_percent', 0) || 0;
  const direction: Direction =
    get(calculatorState, 'calculation_event', 'ENTERED') === 'ENTERED'
      ? 'in'
      : 'out';
  const totalSum =
    type_calculation === 'CALCULATION'
      ? get(daySummary, 'fare', 0)
      : fare * count[direction];

  const restSum = (totalSum / 100) * (percent || 0);
  const calcSum = totalSum - restSum - correction;

  return { totalSum, calcSum, restSum };
};
