import React, {
  FunctionComponent,
  useEffect,
  useState,
  ChangeEventHandler,
} from 'react';
import Bus from '@components/Bus/Bus';
import css from './Buses.module.less';
import { Input, Spin, Divider } from 'antd';
import useTranslate from '@hooks/useTranslate';
import componentContent from './Buses.content.json';
import buses, { UNGROUP_ID } from '@stores/buses';
import $auth from '@stores/auth';
import { useStore } from 'effector-react';
import Messages from '@components/Messages';
import { get } from 'lodash';
import ICamera from '@interfaces/ICamera';
import IBus, { IBusGroup } from '@interfaces/IBus';
import useMedia from '@hooks/useMedia';
import cn from 'classnames';
import useAdmin from '@hooks/useAdmin';
const { Search } = Input;

interface BusesProps {}

const Buses: FunctionComponent<BusesProps> = () => {
  const content = useTranslate(componentContent);
  const { user } = useStore($auth);

  const isAdmin = useAdmin();
  const isIntergrator = !!get(user, ['integrator_clients', 'length']);
  const { isTabletOrMobile } = useMedia();

  const { preloaders, listByCustomers, customers, busGroups } = useStore(buses);
  const [filteredList, setList] = useState(new Map(listByCustomers));

  useEffect(() => {
    setList(new Map(listByCustomers));
  }, [listByCustomers]);

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const onSearchTextChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.currentTarget.value;
    setSearchValue(value);
  };

  const sortBusGroups = (
    aGroup: IBusGroup | null,
    bGroup: IBusGroup | null,
  ) => {
    const aName = get(aGroup, ['name'], '');
    const bName = get(bGroup, ['name'], '');
    const aId = get(aGroup, ['id'], '');
    const bId = get(bGroup, ['id'], '');
    if (aId === UNGROUP_ID) return -1;
    if (bId === UNGROUP_ID) return 1;
    if (aName < bName) return -1;
    if (aName > bName) return 1;
    return 0;
  };

  const filterBuses = (bus: IBus) => {
    if (searchValue) {
      const busNameMatch = bus.name
        .toLowerCase()
        .includes(searchValue.toLowerCase());
      const cameraNameMatch = !!bus.cameras.filter((camera: ICamera) =>
        camera.name.toLowerCase().includes(searchValue.toLowerCase()),
      ).length;
      return busNameMatch || cameraNameMatch;
    } else return true;
  };

  return (
    <div className={cn(css.root, { [css.mobile]: isTabletOrMobile })}>
      <Messages />
      <div className={css.search}>
        <Search
          placeholder={content.search_placeholder}
          value={searchValue}
          onChange={onSearchTextChange}
        />
      </div>
      <Spin spinning={!!preloaders.getList}>
        <div>
          {customers
            .filter((customer) => {
              const isActive =
                ![3, 4].includes(customer.status) && customer.enabled;
              return isActive;
            })
            .map((customer) => {
              const groupItemsMap = filteredList.get(customer.id);
              const groupIds = groupItemsMap
                ? Array.from(groupItemsMap.keys())
                : [];

              let busCount = 0;
              const allBuses: Record<string, IBus[]> = {};
              groupIds.forEach((groupId) => {
                const items = (groupItemsMap?.get(groupId) || []).filter(
                  filterBuses,
                );
                allBuses[groupId] = items;
                busCount = busCount + (items ? items?.length : 0);
              });
              if (!busCount) return null;

              return (
                <React.Fragment key={customer.id}>
                  <div className={css.listContainer}>
                    {isAdmin || isIntergrator ? (
                      <div className={css.customer}>
                        {[customer.name, customer.surname].join(' ')}
                      </div>
                    ) : null}
                    {groupIds
                      .map((gid) => get(busGroups, [gid]))
                      .sort(sortBusGroups)
                      .map((group) => {
                        const groupId = get(group, 'id', UNGROUP_ID);
                        const groupName = get(group, 'name');
                        if (!get(allBuses, [groupId, 'length'])) return null;
                        return (
                          <div key={groupId} className={css.group}>
                            {groupId !== UNGROUP_ID ? (
                              <Divider orientation="left">{groupName}:</Divider>
                            ) : null}
                            <div className={css.list}>
                              {allBuses[groupId].map((b) => (
                                <Bus groupIds={groupIds} item={b} key={b.id} />
                              ))}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </React.Fragment>
              );
            })}
        </div>
      </Spin>
    </div>
  );
};

export default Buses;
