import React, { FunctionComponent } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import routes from '@config/routes';
import { filter, get } from 'lodash';
import authStore from '@stores/auth';
import { useStore } from 'effector-react';
import locale from '@stores/locale';
import { MenuMode } from 'antd/lib/menu';
import css from './MainMenu.module.less';
import useMedia from '@hooks/useMedia';
import classNames from 'classnames';
import useCurrentRoute from '@hooks/useCurrentRoute';
import useNotiCount from '@hooks/useNotiCount';
import { $faqCount } from '@stores/faq';

interface MainMenuProps {
  onClick?: () => void;
  mode: MenuMode;
}

const MainMenu: FunctionComponent<MainMenuProps> = ({ onClick, mode }) => {
  const currentRoute = useCurrentRoute();
  const auth = useStore(authStore);
  const localeName = useStore(locale);
  const menuItems = filter(
    routes,
    (route) =>
      !!(route.menuSlotes && route.menuSlotes.includes('main') && auth.user),
  );
  const { isTabletOrMobile } = useMedia();
  const { unreadedNewsCount } = useNotiCount();
  const faqCount = useStore($faqCount);

  return (
    <div>
      <Menu
        theme="dark"
        mode={mode}
        selectedKeys={[get(currentRoute, 'name', '')]}
        onClick={onClick ? () => onClick() : undefined}
      >
        {menuItems
          .filter((route) => {
            if (route.name === 'faq') return !!faqCount;
            return true;
          })
          .map((route) => (
            <Menu.Item key={route.name}>
              <Link to={route.path}>{route.title[localeName]}</Link>
              {route.addonIcon && route.name !== 'news' ? (
                <div
                  className={classNames(css.icon, {
                    [css.mobile]: isTabletOrMobile,
                  })}
                >
                  {route.addonIcon}
                </div>
              ) : route.addonIcon &&
                route.name === 'news' &&
                unreadedNewsCount ? (
                <div
                  className={classNames(css.icon, {
                    [css.mobile]: isTabletOrMobile,
                    [css.alert]: unreadedNewsCount,
                  })}
                >
                  {route.addonIcon}
                </div>
              ) : null}
            </Menu.Item>
          ))}
      </Menu>
    </div>
  );
};

export default MainMenu;
