// import { BUS_DEFAULT_TIMEZONE } from '@config/format';
// import React, { FunctionComponent } from 'react';
import { FunctionComponent } from 'react';
import { TooltipProps } from 'recharts';
// import moment from 'moment-timezone';
// import { Card } from 'antd';
// import useTranslate from '@hooks/useTranslate';
import { setHoveredData, TickType } from '@stores/stats';
import IBus from '@interfaces/IBus';
// import css from './CustomStatsTooltip.module.less';
import { useEffect } from 'react';
import { get } from 'lodash';

interface CustomStatsTooltipProps {
  timezone: string;
  period: TickType;
  tickFormat: string;
  items: IBus[];
}

const CustomStatsTooltip: FunctionComponent<
  TooltipProps & CustomStatsTooltipProps
> = (props) => {
  // const content = useTranslate({});
  // const timezone = props.timezone || BUS_DEFAULT_TIMEZONE;
  // const currentTime = moment(props.label).tz(timezone);
  // const label = `${currentTime.format(props.tickFormat)}`;
  // const items = props.items.map((item) => {
  //   const payload = props.payload?.find((data) => data.dataKey === item.id);
  //   return {
  //     color: payload?.fill,
  //     name: item.name,
  //     id: item.id,
  //     value: payload?.value,
  //   };
  // });

  const key =
    get(props, ['label']) + get(props, ['payload', 'length']).toString();

  useEffect(() => {
    setHoveredData(get(props, ['payload', 0, 'payload'], null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return null;

  // return (
  //   <Card
  //     size="small"
  //     // style={{ zIndex: 1, position: 'relative', width: 130, marginLeft: -75 }}
  //     title={label}
  //   >
  //     <div className={css.grid}>
  //       {items.map((item, i) => {
  //         return (
  //           <div
  //             className={css.bus}
  //             key={`${item.id}_${i}`}
  //             style={{ color: item.color }}
  //           >
  //             <div className={css.name}>{item.name}</div>:
  //             <div className={css.value}>{item.value}</div>
  //           </div>
  //         );
  //       })}
  //     </div>
  //   </Card>
  // );
};

export default CustomStatsTooltip;
