import { IRichPeriod } from '@interfaces/IPeriod';
import React, { FunctionComponent, useState } from 'react';
import cn from 'classnames';
import css from './FragmentListItem.module.less';
import { Space, Tag, Tooltip } from 'antd';
import {
  processingStatuses,
  waitingStatuses,
  errorStatuses,
  hasSourceStatuses,
} from '@constants/periodStatuses';
import componentContent from './FragmentListItem.content.json';
import useTranslate from '@hooks/useTranslate';
import { useStore, useStoreMap } from 'effector-react';
import {
  DownloadOutlined,
  CloudDownloadOutlined,
  LoadingOutlined,
  VideoCameraOutlined,
  InfoCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';
import downloadFile from '@helpers/downloadFile';
import { preloaders as $preloaders } from '@stores/periods';
import {
  // cameras as $cameras,
  item as $bus,
} from '@stores/bus';
import SegmentEditPanel from '../SegmentEditPanel';
import ICamera from '@interfaces/ICamera';
import { preloadFile } from '@stores/servicePeriods';
import { get, has } from 'lodash';
import $video, { setSegment } from '@stores/video';
import { MAX_OBJECTS } from '@constants/limits';
import { Divider, Popup } from 'antd-mobile';

interface FragmentListItemProps {
  fragment: IRichPeriod;
  short?: boolean;
  mobile?: boolean;
  editable?: boolean;
  cameraStatus?: ICamera['status'];
  onClick?: () => void;
  selected?: boolean;
  borderless?: boolean;
}

const FragmentListItem: FunctionComponent<FragmentListItemProps> = ({
  fragment,
  short,
  editable,
  cameraStatus,
  mobile,
  borderless,
  ...props
}) => {
  const content = useTranslate(componentContent);
  const preloading = useStoreMap({
    store: $preloaders,
    keys: [],
    fn: (state) => {
      if ([fragment.id, false].includes(state.preloadFile)) {
        return state.preloadFile;
      }
      return null;
    },
  });

  const max_objects_count = get(fragment, 'max_objects_count', 0);

  const downloadSource = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    downloadFile(fragment.download + '?processed=0', fragment.start_time);
  };

  const downloadProcessed = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    downloadFile(fragment.download, fragment.start_time);
  };

  const preload = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    preloadFile({
      segmentId: fragment.id,
      preloaderId: fragment.id,
      cameraId: camera ? camera.id : undefined,
    });
  };

  const busType = useStoreMap({
    store: $bus,
    keys: [],
    fn: (state) => get(state, ['type'], null),
  });

  const camera = useStoreMap({
    store: $bus,
    keys: [fragment.cameraId],
    fn: (state) => {
      const camera = state.cameras.find((c) => c.id === fragment.cameraId);
      return camera || null;
    },
  });

  const isCameraOffline = cameraStatus === 'offline';
  const isSource = hasSourceStatuses.includes(fragment.status);
  const isProcessed = fragment.status === 'PROCESSED';
  const isLive = camera?.live;

  const { selected } = useStore($video);
  const selectedSegment = selected ? selected?.segment : null;

  const downloadSourceButton =
    !isLive && isSource ? (
      <span className={css.icon}>
        <Tooltip title={content.download}>
          <VideoCameraOutlined onClick={downloadSource} />
        </Tooltip>
      </span>
    ) : null;

  const manualEditButton =
    editable && mobile ? (
      <span className={css.icon}>
        <EditOutlined onClick={() => setMobilePopoupVisible(true)} />
      </span>
    ) : null;

  const downloadProcessedButton = isProcessed ? (
    <span className={css.icon}>
      <Tooltip title={content.downloadProcessed}>
        <DownloadOutlined onClick={downloadProcessed} />
      </Tooltip>
    </span>
  ) : null;

  const [mobilePopupVisible, setMobilePopoupVisible] = useState(false);

  const preloadButton =
    busType === 'VMS' && fragment.status === 'LIVE' ? (
      <span className={css.icon}>
        <Tooltip title={content.preload}>
          {fragment && preloading === fragment.id ? (
            <LoadingOutlined />
          ) : (
            <CloudDownloadOutlined onClick={preload} />
          )}
        </Tooltip>
      </span>
    ) : null;

  const inOutInfo = (
    <>
      <span className={css.in}>
        {content.in}: {fragment.entered[0] + fragment.entered[1]}
        {fragment.entered[1] ? (
          <span>
            {' '}
            ({content.children}: {fragment.entered[1] || 0})
          </span>
        ) : null}
      </span>
      <span className={css.out}>
        {content.out}: {fragment.exited[0] + fragment.exited[1]}
        {fragment.exited[1] ? (
          <span>
            {' '}
            ({content.children}: {fragment.exited[1] || 0})
          </span>
        ) : null}
      </span>
    </>
  );

  const editPanel = (
    <SegmentEditPanel
      mobile={mobile}
      value={{
        entered_manual: fragment.entered_manual || [null, null],
        exited_manual: fragment.exited_manual || [null, null],
      }}
      segment={fragment}
    />
  );

  return (
    <div
      className={cn(css.item, {
        [css.selected]: has(props, 'selected')
          ? props.selected
          : fragment.id === selectedSegment?.id,
        [css.short]: short,
        [css.editable]: editable,
        [css.borderless]: borderless,
      })}
      key={fragment.id}
      onClick={
        has(props, 'onClick') ? props.onClick : () => setSegment(fragment)
      }
    >
      <div className={css.title}>
        {/* <Tag>{fragment.id}</Tag> */}
        {editable && max_objects_count > MAX_OBJECTS ? (
          <Tooltip title={content.attention}>
            <InfoCircleOutlined className={css.attention} />
          </Tooltip>
        ) : null}
        <Tag
          color={
            isCameraOffline || errorStatuses.includes(fragment.status)
              ? 'error'
              : [...processingStatuses, ...waitingStatuses].includes(
                  fragment.status,
                )
              ? 'default'
              : 'success'
          }
        >
          {isCameraOffline ? 'offline' : content[fragment.status]}
        </Tag>
        {mobile ? null : (
          <Tag>
            {get(camera, 'name')} {editable ? `(${fragment.cameraId})` : null}
          </Tag>
        )}
        <span className={css.time}>
          {fragment.formattedFrom} - {fragment.formattedTo} ({fragment.duration}
          )
        </span>
        {manualEditButton}
        {downloadSourceButton}
        {downloadProcessedButton}
        {preloadButton}
      </div>
      {short ? null : (
        <div className={css.meta}>
          {inOutInfo}
          {/* // TODO временно отключено до того, как решится проблема с hickvision */}
          {/* {fragment.status === 'LIVE' ? (
          <span className={css.icon}>
            <Tooltip title={content.preload}>
              <CloudDownloadOutlined onClick={preload} />
            </Tooltip>
          </span>
        ) : fragment && preloading === fragment.id ? (
          <span className={css.icon}>
            <LoadingOutlined />
          </span>
        ) : !processingStatuses.includes(fragment.status) ? (
          <span className={css.icon}>
            <Tooltip title={content.download}>
              <DownloadOutlined onClick={download} />
            </Tooltip>
          </span>
        ) : null} */}
          {editable && !mobile ? (
            <span className={css.editPanel}>{editPanel}</span>
          ) : null}
          {editable && mobile ? (
            <Popup
              visible={mobilePopupVisible}
              onMaskClick={() => setMobilePopoupVisible(false)}
            >
              <div className={css.mobileEditPanel}>
                <div>
                  <span className={css.time}>
                    {fragment.formattedFrom} - {fragment.formattedTo} (
                    {fragment.duration})
                  </span>
                </div>
                <Divider />
                <Space direction="vertical">
                  <div className={css.meta}>{inOutInfo}</div>
                  <div>{editPanel}</div>
                </Space>
              </div>
            </Popup>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default FragmentListItem;
