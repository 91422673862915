import React, { FunctionComponent } from 'react';
import css from './RouteSelector.module.less';
// import componentContent from './RouteSelector.content.json';
// import useTranslate from '@hooks/useTranslate';
import { IBusGroup } from '@interfaces/IBus';
import { Empty, Checkbox } from 'antd';
import { useVirtual } from 'react-virtual';

interface ListProps {
  list: IBusGroup[];
  value: IBusGroup['id'][];
  onChange: (value: IBusGroup['id']) => void;
}

const List: FunctionComponent<ListProps> = ({ list, value, onChange }) => {
  // const content = useTranslate(componentContent);
  const parentRef = React.useRef(null);

  const rowVirtualizer = useVirtual({
    size: list ? list.length : 0,
    parentRef,
    estimateSize: React.useCallback(() => 39, []),
  });

  const renderGroup = (group: IBusGroup) => (
    <div
      className={css.option}
      key={group.id}
      onClick={() => onChange(group.id)}
    >
      <div className={css.checkbox}>
        <Checkbox checked={value.includes(group.id)} />
      </div>
      {group.name}
    </div>
  );

  return (
    <div className={css.scroll} ref={parentRef}>
      {list.length ? (
        <div
          style={{
            height: `${rowVirtualizer.totalSize}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {rowVirtualizer.virtualItems.map((virtualRow) => (
            <div
              key={virtualRow.index}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              {renderGroup(list[virtualRow.index])}
            </div>
          ))}
        </div>
      ) : (
        <Empty className={css.empty} />
      )}
    </div>
  );
};

export default List;
