import React, { FunctionComponent, useState } from 'react';
// import css from './TestExport.module.less';
import componentContent from './TestExport.content.json';
import useTranslate from '@hooks/useTranslate';
import { Form, Select, DatePicker, Button } from 'antd';
import { useStore } from 'effector-react';
import { list } from '@stores/buses';
import request from '@helpers/request';
import api from '@constants/endpoints';
import saveResponseAsFile from '@helpers/saveResponseAsFile';
import { Card, Divider } from 'antd-mobile';
const FormItem = Form.Item;

interface TestExportProps {}

const TestExport: FunctionComponent<TestExportProps> = () => {
  const content = useTranslate(componentContent);
  const buses = useStore(list);
  const [pending, setPending] = useState(false);
  const save = async (values: any) => {
    setPending(true);
    try {
      const response = await request(api.stats.workplaces, {
        params: {
          ...values,
          from: values.from.startOf('day').format(),
          to: values.to.endOf('day').format(),
        },
      });
      saveResponseAsFile(response);
    } finally {
      setPending(false);
    }
  };

  return (
    <Card style={{ maxWidth: 420, margin: 'auto' }}>
      <Form onFinish={save}>
        <h2>Отчет</h2>
        <Divider />
        <FormItem name="workplaceIds">
          <Select
            placeholder="Выберите автобусы"
            mode="multiple"
            optionFilterProp="label"
            showSearch
            options={buses.map((b) => ({ value: b.id, label: b.name }))}
          ></Select>
        </FormItem>
        <FormItem name="from" rules={[{ required: true }]}>
          <DatePicker style={{ width: '100%' }} />
        </FormItem>
        <FormItem name="to" rules={[{ required: true }]}>
          <DatePicker style={{ width: '100%' }} />
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" loading={pending}>
            {content.save}
          </Button>
        </FormItem>
      </Form>
    </Card>
  );
};

export default TestExport;
