import { BUS_DEFAULT_TIMEZONE, TIME_FORMAT } from '@config/format';
import React, { FunctionComponent } from 'react';
import { TooltipProps } from 'recharts';
import moment from 'moment-timezone';
import { Card } from 'antd';
import useTranslate from '@hooks/useTranslate';
import { get } from 'lodash';
import componentContent from './CustomTooltip.content.json';

interface CustomTooltipProps {
  timezone: string;
}

const CustomTooltip: FunctionComponent<TooltipProps & CustomTooltipProps> = (
  props,
) => {
  const content = useTranslate(componentContent);
  const timezone = props.timezone || BUS_DEFAULT_TIMEZONE;
  const currentTime = moment(props.label).tz(timezone);
  const label = `${content.from} ${currentTime
    .startOf('hour')
    .format(TIME_FORMAT)} ${content.to} ${currentTime
    .add(1, 'hour')
    .startOf('hour')
    .format(TIME_FORMAT)}`;

  const inPayload = props.payload?.find((data) => data.name === 'entered');
  const outPayload = props.payload?.find((data) => data.name === 'exited');
  const kidsIn = get(inPayload, ['payload', 'enteredKids'], 0);
  const kidsOut = get(inPayload, ['payload', 'exitedKids'], 0);

  return (
    <Card
      size="small"
      style={{ zIndex: 1, position: 'relative', width: 130, marginLeft: -75 }}
    >
      <div>{label}:</div>
      <div>
        <div style={{ color: inPayload?.fill }}>
          {content.in}: {inPayload?.value}{' '}
          {kidsIn ? (
            <div>
              {content.children}: {kidsIn || 0}
            </div>
          ) : null}
        </div>
        <div style={{ color: outPayload?.fill }}>
          {content.out}: {outPayload?.value}
          {kidsOut ? (
            <div>
              {content.children}: {kidsOut || 0}
            </div>
          ) : null}
        </div>
      </div>
    </Card>
  );
};

export default CustomTooltip;
