import { createEffect, createStore, sample } from 'effector';
import { serviceCameras as $serviceCameras, item as $bus } from '@stores/bus';
import { BUS_DEFAULT_TIMEZONE } from '@config/format';
import ICamera from '@interfaces/ICamera';
import IPeriod, { IRichPeriod } from '@interfaces/IPeriod';
import request from '@helpers/request';
import { AxiosRequestConfig } from 'axios';
import api from '@constants/endpoints';
import { get } from 'lodash';
import enrichSegment from '@helpers/enrichSegment';
import createTimelineParams from '@helpers/createTimelineParams';
import ITimelineParams from '@interfaces/ITimelineParams';
import update from 'immutability-helper';
import $date from '@stores/date';

interface ICombineDataStore {
  mergedTimelineParams?: ITimelineParams;
  segmentsByCamera?: Record<ICamera['id'], IRichPeriod[]>;
  mergedSegmentsByCamera?: Record<ICamera['id'], IRichPeriod[]>;
}

export const getServiceList = createEffect({
  name: 'getServiceList',
  async handler({
    startdate,
    enddate,
  }: {
    startdate: string;
    enddate: string;
  }) {
    const cameras = $serviceCameras.getState();
    const bus = $bus.getState();
    const timezone = bus.timezone || BUS_DEFAULT_TIMEZONE;
    const requestConfigs = cameras.map((camera: ICamera) => {
      const requestParams = {
        startdate,
        enddate,
      };
      return {
        urlParams: { cameraId: camera.id },
        params: requestParams,
      };
    });

    const segmentsByCamera: Record<ICamera['id'], IRichPeriod[]> = {};
    const mergedSegmentsByCamera: Record<ICamera['id'], IRichPeriod[]> = {};

    const results = await Promise.all(
      requestConfigs.map((config: AxiosRequestConfig) =>
        request(api.periods.list, config),
      ),
    );

    const mergedTimelineParams = createTimelineParams(results, timezone, {
      merged: true,
    });
    const { totalMin, fullLength } = mergedTimelineParams;

    cameras.forEach((camera, index) => {
      const response = results[index];
      const segments = get(response, ['data', 'segments'], []);
      const segmentsMerged = get(response, ['data', 'merged_segments'], []);
      const commonParams = {
        totalMin,
        fullLength,
        cameraId: camera.id,
        stream_id: camera.stream_id,
        timezone,
      };

      segmentsByCamera[camera.id] = segments.map((segment: IPeriod) =>
        enrichSegment({
          segment,
          ...commonParams,
        }),
      );

      mergedSegmentsByCamera[camera.id] = segmentsMerged.map(
        (segment: IPeriod) =>
          enrichSegment({
            segment,
            ...commonParams,
          }),
      );
    });

    const data = {
      mergedTimelineParams,
      segmentsByCamera,
      mergedSegmentsByCamera,
    };

    return data;
  },
});

export const preloadFile = createEffect({
  name: 'preloadFile',
  async handler({
    segmentId,
  }: {
    segmentId: IPeriod['id'];
    preloaderId?: string;
    cameraId?: ICamera['id'];
  }) {
    const response = await request(api.video.preload, {
      urlParams: { segmentId },
    });
    return response.data as IPeriod;
  },
});

const preloadFileHandler = (
  state: ICombineDataStore,
  {
    result,
    params,
  }: {
    result: IPeriod;
    params: {
      segmentId: IPeriod['id'];
      cameraId?: ICamera['id'];
    };
  },
) => {
  const segment = result;
  if (params.cameraId && result && state.segmentsByCamera) {
    const findedSegmentIndex = state.segmentsByCamera[
      params.cameraId
    ].findIndex((s) => s.id === segment.id);

    if (findedSegmentIndex >= 0) {
      return update(state, {
        segmentsByCamera: {
          [params.cameraId]: {
            $splice: [
              [
                findedSegmentIndex,
                1,
                {
                  ...state.segmentsByCamera[params.cameraId][
                    findedSegmentIndex
                  ],
                  status: segment.status,
                },
              ],
            ],
          },
        },
      });
    } else return state;
  }
};

sample({
  source: $date,
  target: getServiceList,
  filter: (data) => !!data,
  fn(data) {
    return {
      // @ts-ignore
      startdate: data.formattedStartdate,
      // @ts-ignore
      enddate: data.formattedEnddate,
    };
  },
});

const $servicePeriods = createStore<ICombineDataStore>({})
  .on(getServiceList.done, (state, { result }) => result)
  .on(preloadFile.done, preloadFileHandler);

export default $servicePeriods;
