import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import css from './StatsTimeline.module.less';
import componentContent from './StatsTimeline.content.json';
import useTranslate from '@hooks/useTranslate';
import { Radio, DatePicker, Input, Button } from 'antd';
import { Popup } from 'antd-mobile';
import stats, {
  exportStats,
  getStats,
  ReportPeriod,
  setParams,
  touch,
} from '@stores/stats';
import { useStore, useStoreMap } from 'effector-react';
import {
  CalendarOutlined,
  BarChartOutlined,
  DownloadOutlined,
  SlidersOutlined,
} from '@ant-design/icons';
import {
  BUS_DEFAULT_TIMEZONE,
  DATE_FORMAT,
  SERVER_DATE_FORMAT,
} from '@config/format';
import moment, { Moment } from 'moment';
import {
  preloaders as $busPreloaders,
  list as $buses,
  UNGROUP_ID,
} from '@stores/buses';
import { get } from 'lodash';
import IBus from '@interfaces/IBus';
import BusSelector from '@components/BusSelector';
import useAdmin from '@hooks/useAdmin';
import useMedia from '@hooks/useMedia';
import CustomRangePicker from '@components/CustomRangePicker';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import classNames from 'classnames';
import RouteSelector from '@components/RouteSelector';

interface FiltersProps {}

const Filters: FunctionComponent<FiltersProps> = () => {
  const content = useTranslate(componentContent);
  const [rangeOpened, openRange] = useState(false);
  const { preloaders, params } = useStore(stats);
  const buses = useStore($buses);
  const isAdmin = useAdmin();
  const { isTabletOrMobile } = useMedia();
  const [mobileVisibleState, setMobileVisibleState] = useState(false);

  const initialReportRef = useRef<string | null>(null);
  useEffect(() => {
    if (
      !isAdmin &&
      params.selectedBusIds.length &&
      !(initialReportRef.current === 'done')
    ) {
      createReport();
      initialReportRef.current = 'done';
    }

    return () => {
      initialReportRef.current = null;
    };

    // eslint-disable-next-line
  }, [params.selectedBusIds.length, isAdmin]);

  const availablePeriods: ReportPeriod[] = [
    'year',
    'quarter',
    'month',
    'week',
    'date',
  ];

  const { period, date, selectedBusIds, disabledButton, customDate } = params;

  const prevReportState = useRef<boolean>(disabledButton);
  useEffect(() => {
    if (!prevReportState.current && disabledButton)
      setMobileVisibleState(false);
    prevReportState.current = disabledButton;
  }, [disabledButton]);

  const getParams = (params?: { forExport?: boolean }) => {
    const busTimezones: (string | null)[] = [];
    const { forExport } = params || {};
    const selectedBuses: IBus[] = buses.filter((bus: IBus) => {
      const selected = selectedBusIds.includes(bus.id);
      if (selected && !busTimezones.includes(bus.timezone)) {
        busTimezones.push(bus.timezone);
      }
      return selected;
    });

    const selectedTimezone: string = busTimezones[0] || BUS_DEFAULT_TIMEZONE;

    if ((period as string) === 'custom') {
      const start = get(customDate, [0]) || moment().startOf('month');
      const end = get(customDate, [1]) || moment().endOf('month');
      const startdate = start
        .clone()
        .tz(selectedTimezone)
        .startOf(period)
        .format(forExport ? SERVER_DATE_FORMAT : undefined);
      const enddate = end
        .clone()
        .tz(selectedTimezone)
        .endOf(period)
        .format(forExport ? SERVER_DATE_FORMAT : undefined);
      const duration = moment.duration(end.diff(start));
      const months = duration.asMonths();
      const customPeriod: ReportPeriod = months < 4 ? 'month' : 'year';

      return {
        startdate,
        enddate,
        selectedBuses,
        selectedTimezone,
        period: customPeriod,
        date,
      };
    } else {
      const startdate = date
        .clone()
        .tz(selectedTimezone)
        .startOf(period)
        .format(forExport ? SERVER_DATE_FORMAT : undefined);
      const enddate = date
        .clone()
        .tz(selectedTimezone)
        .endOf(period)
        .format(forExport ? SERVER_DATE_FORMAT : undefined);
      return {
        startdate,
        enddate,
        selectedBuses,
        selectedTimezone,
        period,
        date,
      };
    }
  };

  const createReport = () => {
    if (date && period && selectedBusIds.length) {
      getStats(getParams());
      touch(true);
    }
  };

  const _exportStats = () => {
    if (date && period && selectedBusIds.length) {
      exportStats(getParams({ forExport: true }));
    }
  };

  const listPreloading = useStoreMap({
    store: $busPreloaders,
    keys: [],
    fn: (state) => !!state.getList,
  });

  const getPickerFooter = () =>
    period === 'date' ? (
      <div
        className={css.clickableFoter}
        onClick={() => {
          setParams({
            date: moment().subtract(1, 'day').startOf('day'),
          });
          openRange(false);
        }}
      >
        {content.tomorrow}
      </div>
    ) : null;

  const submitButton = (
    <Input.Group
      compact
      className={classNames(css.submit, { [css.full]: isTabletOrMobile })}
    >
      <Button
        type="primary"
        onClick={createReport}
        disabled={disabledButton}
        loading={!!preloaders.getStats}
        icon={<BarChartOutlined />}
        style={{ width: 'calc(100% - 32px)' }}
      >
        {content.createReport}
      </Button>
      <Button
        type="primary"
        disabled={!selectedBusIds.length || !date}
        icon={<DownloadOutlined />}
        loading={!!preloaders.exportStats}
        onClick={_exportStats}
      />
    </Input.Group>
  );

  const customPickerProps: RangePickerProps<Moment> = {
    open: rangeOpened,
    onOpenChange: openRange,
    inputReadOnly: true,
    value: customDate,
    onChange: (customDate) => setParams({ customDate }),
    allowClear: false,
    style: { width: '100%' },
    format: DATE_FORMAT,
  };

  const formElement = (
    <div
      className={classNames(css.filters, { [css.mobile]: isTabletOrMobile })}
    >
      <div>
        <Radio.Group
          onChange={(e) => {
            if (e.target.value === 'custom') openRange(true);
            setParams({ period: e.target.value });
          }}
          value={period}
          style={{ width: '100%' }}
        >
          {availablePeriods.map((period) => (
            <Radio.Button key={period} value={period}>
              {content[period]}
            </Radio.Button>
          ))}
          {!isTabletOrMobile ? (
            <Radio.Button value="custom">
              <CalendarOutlined />
            </Radio.Button>
          ) : null}
        </Radio.Group>
      </div>
      {isTabletOrMobile ? (
        <Button
          type={
            params.period === ('custom' as ReportPeriod) ? 'primary' : 'default'
          }
          onClick={() => {
            openRange(true);
            setParams({ period: 'custom' });
          }}
        >
          <CalendarOutlined />
        </Button>
      ) : null}
      <div className={css.full}>
        {(period as string) === 'custom' ? (
          isTabletOrMobile ? (
            <CustomRangePicker {...customPickerProps} />
          ) : (
            <DatePicker.RangePicker key="custom" {...customPickerProps} />
          )
        ) : (
          <DatePicker
            key={period}
            picker={period}
            style={{ width: '100%' }}
            value={date}
            onChange={(date) => setParams({ date })}
            inputReadOnly
            allowClear={false}
            open={rangeOpened}
            onOpenChange={openRange}
            renderExtraFooter={getPickerFooter}
            showToday={false}
          />
        )}
      </div>
      <RouteSelector
        listPreloading={listPreloading}
        value={params.busRouteIds}
        onChange={(busRouteIds) =>
          setParams({
            busRouteIds,
            selectedBusIds: buses
              .filter((b) =>
                busRouteIds.includes(get(b, ['group', 'id'], UNGROUP_ID)),
              )
              .map((b) => b.id),
          })
        }
      />
      <BusSelector
        listPreloading={listPreloading}
        value={selectedBusIds}
        onChange={(selectedBusIds) =>
          setParams({ selectedBusIds, busRouteIds: [] })
        }
      />
      {submitButton}
    </div>
  );

  if (isTabletOrMobile) {
    return (
      <div>
        <div className={css.mobileToggler}>
          <Button
            type="primary"
            icon={<SlidersOutlined />}
            block
            onClick={() => setMobileVisibleState(!mobileVisibleState)}
          >
            {content.filters}
          </Button>
        </div>
        <Popup
          onMaskClick={() => setMobileVisibleState(false)}
          visible={mobileVisibleState}
        >
          <div className={css.popupConainer}>{formElement}</div>
        </Popup>
      </div>
    );
  } else return <div>{formElement}</div>;
};

export default Filters;
