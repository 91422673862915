import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import ym, { YMInitializer } from 'react-yandex-metrika';
import appConfig from '@config/app';

import 'moment/locale/ru';
import { useStore } from 'effector-react';
import authStore from '@stores/auth';
import localeStore, { localeMap } from '@stores/locale';
import AuthRoute from '@components/AuthRoute/AuthRoute';
import routes from '@config/routes';
import RootLayout from '@layouts/RootLayout';

// Routes
import Login from './routes/Login';
import Buses from './routes/Buses';
import Bus from './routes/Bus';
import history from '@helpers/history';
import Stats from './routes/Stats';
import News from './routes/News';
import FAQ from './routes/FAQ';
import TestExport from './routes/TestExport';

import useCommonData from '@hooks/useCommonData';

export default () => {
  const auth = useStore(authStore);
  const localeName = useStore(localeStore);
  const isAuth = !!auth.user;
  const targetUrl = auth.targetUrl;

  let homePagePath = routes.buses.path;

  useCommonData();

  return (
    <ConfigProvider
      locale={localeMap[localeName].locale}
      // TODO: ломает верстку в некоторых местах, стоит придумать что-то более гибкое
      // getPopupContainer={(node) =>
      //   node && node.parentNode
      //     ? (node.parentNode as HTMLElement)
      //     : document.body
      // }
    >
      <Router history={history}>
        <YMInitializer
          accounts={[appConfig.ymId]}
          options={{
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
          }}
          version="2"
        />
        <RootLayout isAuth={isAuth}>
          <Switch>
            <Route path={routes.login.path} render={() => <Login />} exact />

            <AuthRoute
              path={routes.buses.path}
              render={() => <Buses />}
              exact
            />
            <AuthRoute path={routes.bus.path} render={() => <Bus />} exact />
            <AuthRoute path={routes.stats.path} render={() => <Stats />} />
            <AuthRoute path={routes.news.path} render={() => <News />} />
            <AuthRoute path={routes.faq.path} render={() => <FAQ />} />
            <AuthRoute
              path={routes.testExport.path}
              render={() => <TestExport />}
            />

            {targetUrl ? (
              <Redirect to={targetUrl}></Redirect>
            ) : (
              <Redirect to={homePagePath}></Redirect>
            )}
          </Switch>
        </RootLayout>
      </Router>
    </ConfigProvider>
  );
};

history.listen((location) => {
  ym('hit', location.pathname);
});
