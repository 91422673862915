import React, { FunctionComponent } from 'react';
import css from './MergedRequestsList.module.less';
// import componentContent from './MergedRequestsList.content.json';
// import useTranslate from '@hooks/useTranslate';
import { useStore } from 'effector-react';
import { $list, $preloaders } from '@stores/merge';
import { Spin } from 'antd';
import Item from '@components/MergedRequestsList/Item';

interface MergedRequestsListProps {}

const MergedRequestsList: FunctionComponent<MergedRequestsListProps> = () => {
  // const content = useTranslate(componentContent);
  const list = useStore($list);
  const preloaders = useStore($preloaders);

  if (!list.length)
    return <div>{/* <Empty description={content.empty}></Empty> */}</div>;

  return (
    <div className={css.root}>
      <Spin spinning={!!preloaders.getRequests}>
        {list.map((item) => (
          <Item key={item.id} item={item} />
        ))}
      </Spin>
    </div>
  );
};

export default MergedRequestsList;
