import { AxiosResponse } from 'axios';
import downloadFile from './downloadFile';

export default (response: AxiosResponse<any>) => {
  const cd = response.headers['content-disposition'];
  const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const match = regex.exec(cd);
  let fileName = match ? match[1] : 'file.csv';

  try {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(response.data, fileName);
    } else {
      const url = URL.createObjectURL(response.data);
      downloadFile(url, fileName);
      URL.revokeObjectURL(url);
    }
  } catch (exc) {
    console.error('Save Blob method failed with the following exception.');
    console.error(exc);
  }
};
