import routes, { RouteName } from '@config/routes';
import history from '@helpers/history';
import IBus from '@interfaces/IBus';
import qs from 'qs';

export const getPath = (routeName: RouteName, params?: Record<string, any>) => {
  const currentRoute = routes[routeName];
  if (currentRoute.getPath && params) {
    return currentRoute.getPath(params);
  }
  return currentRoute.path;
};

export const changeParams = (params: Record<string, any>) => {
  const oldParams = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });
  const newParams = { ...oldParams, ...params };
  history.replace({
    pathname: history.location.pathname,
    search: `?${qs.stringify(newParams)}`,
  });
};

export const setBus = (busId?: IBus['id']) => {
  if (!busId) return;
  history.push({
    pathname: getPath('bus', { busId }),
    search: history.location.search,
  });
};

export const goTo = (
  routeName: RouteName,
  params?: Record<string, any>,
  // state?: HistoryLocationState,
  state?: any,
) => {
  history.push(getPath(routeName, params), state);
};

export const goBack = () => {
  history.goBack();
};
