import { difference } from 'lodash';

export type StatusValue =
  | 'ADDING'
  | 'ADDED'
  | 'LOADING'
  | 'LOADED'
  | 'PROCESSING'
  | 'PROCESSED'
  | 'DOWNLOADING_FROM_TASK'
  | 'MERGING '
  | 'LIVE'
  | 'WAS_MERGED'
  | 'DOWNLOAD_FAIL';

const periodStatuses: StatusValue[] = [
  'ADDING',
  'ADDED',
  'LOADING',
  'LOADED',
  'PROCESSING',
  'PROCESSED',
  'DOWNLOADING_FROM_TASK',
  'LIVE',
];

export const processingStatuses: StatusValue[] = [
  'ADDING',
  'LOADING',
  'PROCESSING',
  'MERGING ',
  'DOWNLOADING_FROM_TASK',
];

export const waitingStatuses: StatusValue[] = ['ADDED', 'LOADED'];

export const hasVideoStatuses: StatusValue[] = [
  'PROCESSED',
  'LIVE',
  'DOWNLOADING_FROM_TASK',
];

export const liveVideoStatuses: StatusValue[] = [
  'LIVE',
  'DOWNLOADING_FROM_TASK',
];

export const errorStatuses: StatusValue[] = ['DOWNLOAD_FAIL'];

export const hasSourceStatuses: StatusValue[] = difference(
  periodStatuses,
  processingStatuses,
);

export default periodStatuses;
