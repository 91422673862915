import {
  createStore,
  createEvent,
  createEffect,
  combine,
  sample,
} from 'effector';
import request from '@helpers/request';
import api from '@constants/endpoints';
import createPreloadersStore from './preloaders';
import IPeriod, { IEvent, IRichPeriod } from '@interfaces/IPeriod';
import ICamera from '@interfaces/ICamera';
import { item as $bus, cameras } from './bus';
import { Moment } from 'moment-timezone';
import { BUS_DEFAULT_TIMEZONE, SERVER_DATE_FORMAT } from '@config/format';
import sortByDate from '@helpers/sortByDate';
import update from 'immutability-helper';
import ITimelineParams from '../interfaces/ITimelineParams';
import { getServiceList, preloadFile } from './servicePeriods';
import { SegmentManualValue } from '@components/SegmentEditPanel/SegmentEditPanel';
import $date from './date';
import IBus from '@interfaces/IBus';
import ISegmentsResponseItem from '@interfaces/ISegmentsResponse';
import IDayTrafficResponseItem from '@interfaces/IDayTrafficResponse';
import enrichSegment from '@helpers/enrichSegment';
import { forEach, get } from 'lodash';
import createTimelineParams from '@helpers/createTimelineParams';

type SummaryItem = {
  eventSumOut: number;
  eventSumIn: number;
  adminEventSumOut: number;
  adminEventSumIn: number;
};

export interface ICombineDataStore {
  segments: Record<ICamera['id'], IRichPeriod[]>;
  timelineParams: ITimelineParams;
  summary: Record<ICamera['id'], SummaryItem>;
}

const initialCombineData: ICombineDataStore = {
  segments: {},
  timelineParams: {
    totalMax: 0,
    totalMin: 0,
    formattedTotalMin: '00:00',
    formattedTotalMax: '00:00',
    fullLength: 0,
  },
  summary: {},
};

export const updateItem = createEffect({
  name: 'updateItem',
  async handler({
    segmentId,
    data,
  }: {
    segmentId: IPeriod['id'];
    preloaderId: IPeriod['id'];
    data: SegmentManualValue;
  }) {
    const response = await request(api.segments.updateItem, {
      urlParams: { segmentId },
      data,
    });
    return response.data as IPeriod;
  },
});

export const preloadAllFiles = createEffect({
  name: 'preloadAllFiles',
  async handler({
    cameraId,
    startTime,
    endTime,
    callback,
  }: {
    cameraId: string;
    startTime: string;
    endTime: string;
    callback?: () => void;
  }) {
    const response = await request(api.video.preloadAll, {
      params: { deviceId: cameraId, startTime, endTime },
    });
    if (callback) callback();
    return response.data as IPeriod;
  },
});

export const resetPeriods = createEvent('reset segments');

export const getList = createEffect({
  name: 'getList',
  async handler({ date, bus }: { date: Moment; bus: IBus }) {
    const formattedDate = date.format(SERVER_DATE_FORMAT);

    const countCameraIds = bus.cameras.filter((c) => !c.live).map((c) => c.id);

    // Данные по видеосегментам
    const segmentsResponse = await request(api.segments.getList, {
      params: { workplaceId: bus.id, date: formattedDate },
    });
    const segmentsData: ISegmentsResponseItem[] =
      segmentsResponse.data.sort(sortByDate);

    const timelineParams = createTimelineParams(
      segmentsData
        .filter((item) => countCameraIds.includes(item.deviceId))
        .map((data) => ({ data })),
      bus.timezone || BUS_DEFAULT_TIMEZONE,
    );
    const { totalMin, totalMax } = timelineParams;

    const fullLength = totalMax - totalMin;

    const summary: Record<ICamera['id'], SummaryItem> = {};
    const segments = segmentsData.reduce((acc, item) => {
      summary[item.deviceId] = {
        adminEventSumIn: item.totalEntered,
        adminEventSumOut: item.totalExited,
        eventSumIn: item.totalEnteredManual,
        eventSumOut: item.totalExitedManual,
      };

      const segments: IRichPeriod[] = item.segments.map((segment) => {
        const camera = bus.cameras.find((c) => c.id === item.deviceId);
        return enrichSegment({
          segment,
          totalMin,
          fullLength,
          cameraId: item.deviceId,
          stream_id: get(camera, 'stream_id', ''),
          timezone: bus.timezone || BUS_DEFAULT_TIMEZONE,
        });
      });
      acc[item.deviceId] = segments.sort(sortByDate);
      return acc;
    }, {} as Record<ICamera['id'], IRichPeriod[]>);

    const combineData: ICombineDataStore = {
      segments,
      // events,
      timelineParams,
      summary,
    };
    return combineData;
  },
});

export const getEvents = createEffect({
  name: 'getEvents',
  async handler({ date, bus }: { date: Moment; bus: IBus }) {
    const formattedDate = date.format(SERVER_DATE_FORMAT);
    const dayStatsResponse = await request(api.stats.getDay, {
      params: { date: formattedDate, id: bus.id },
    });
    const dayStats: IDayTrafficResponseItem[] = dayStatsResponse.data;
    const events: Record<ICamera['id'], IEvent[]> = {};
    dayStats.forEach((item) => {
      if (events[item.device_id]) {
        events[item.device_id].push(...item.events);
      } else {
        events[item.device_id] = [...item.events];
      }
    });
    return events;
  },
});

const preloadFileHandler = (
  state: IRichPeriod[],
  { result }: { result: IPeriod },
) => {
  const segment = result;
  const findedSegmentIndex = state.findIndex((s) => s.id === segment.id);
  if (findedSegmentIndex) {
    return update(state, {
      $splice: [
        [
          findedSegmentIndex,
          1,
          {
            ...state[findedSegmentIndex],
            status: segment.status,
          },
        ],
      ],
    });
  } else return state;
};

const updateItemHandler = (
  state: IRichPeriod[],
  { result }: { result: IPeriod },
) => {
  const { exited_manual, entered_manual } = result;
  const updatedSegmentIndex = state.findIndex((s) => s.id === result.id);

  if (updatedSegmentIndex >= 0) {
    return update(state, {
      $splice: [
        [
          updatedSegmentIndex,
          1,
          {
            ...state[updatedSegmentIndex],
            entered_manual,
            exited_manual,
          },
        ],
      ],
    });
  }
};

export const combineData = createStore<ICombineDataStore>(initialCombineData)
  .on(getList.done, (state, { result }) => ({ ...state, ...result }))
  .reset(resetPeriods);

export const $events = createStore<Record<ICamera['id'], IEvent[]>>({})
  .on(getEvents.done, (state, { result }) => result)
  .reset(resetPeriods);

export const preloaders = createPreloadersStore([
  getList,
  getEvents,
  getServiceList,
  preloadFile,
  preloadAllFiles,
  updateItem,
]);

const periods = combine({
  combineData,
  preloaders,
});

sample({
  source: [$date, $bus],
  target: [getList, getEvents],
  clock: [$date, $bus],
  filter: ([dateData, bus]) => {
    return !!dateData && !!bus.id;
  },
  fn([dateData, bus]) {
    return {
      // @ts-ignore
      date: dateData.date,
      bus,
    };
  },
});

export const $allSegments = createStore<IRichPeriod[]>([])
  .on(preloadFile.done, preloadFileHandler)
  .on(updateItem.done, updateItemHandler);

sample({
  source: [combineData, cameras],
  target: $allSegments,
  fn([combineData, cameras]) {
    const { segments } = combineData;
    const cameraIds = cameras.filter((c) => c.selected).map((c) => `${c.id}`);
    const newState: IRichPeriod[] = [];
    forEach(segments, (segments, id) => {
      if (cameraIds.includes(`${id}`)) newState.push(...segments);
    });
    newState.sort(sortByDate);
    return newState;
  },
});

export default periods;
