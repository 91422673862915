import React, { FunctionComponent } from 'react';
import css from './BusListSelector.module.less';
import classNames from 'classnames';
import IBus from '@interfaces/IBus';
import { useEffect } from 'react';
import { useRef } from 'react';
import { setBus } from '@helpers/navigation';

interface ItemProps {
  active: boolean;
  bus: IBus;
}

const Item: FunctionComponent<ItemProps> = ({ active, bus }) => {
  const ref = useRef<any>(null);

  useEffect(() => {
    if (ref.current && active) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        top: 30,
      });
    }
  }, [active]);

  return (
    <div
      title={bus.name}
      ref={ref}
      onClick={(e) => setBus(bus.id)}
      className={classNames(css.busListItem, {
        [css.active]: active,
      })}
    >
      {bus.name}
    </div>
  );
};

export default Item;
