import { createEvent, createStore, Event } from 'effector';

interface IFiltersFabricConfig {
  listName: string;
  deaultValues?: Record<string, any>;
  resetEvent: Event<void>;
}

const createFiltersStore = (config: IFiltersFabricConfig) => {
  const defaultFilters = { ...(config.deaultValues || null) };
  const { listName } = config;
  const setFilters: Event<Record<string, any>> = createEvent(
    `Set ${listName} filters`,
  );
  const setFiltersHandler = (
    state: Record<string, any>,
    payload: Record<string, any>,
  ) => {
    const filters = { ...defaultFilters, ...payload };
    return filters;
  };
  const filters = createStore<Record<string, any>>(defaultFilters)
    .on(setFilters, setFiltersHandler)
    .reset(config.resetEvent);
  return { filters, setFilters };
};

export default createFiltersStore;
