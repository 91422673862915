import React, { FunctionComponent } from 'react';
import css from './StatsLegend.module.less';
import componentContent from './StatsLegend.content.json';
import useTranslate from '@hooks/useTranslate';
import StatsLegendItem from '@components/StatsLegendItem';
import { useStore, useStoreMap } from 'effector-react';
import {
  barChartSource,
  activeRef as $activeRef,
  hoveredRef as $hoveredRef,
  dataKey as $dataKey,
  groupByKey as $groupByKey,
  setRef,
} from '@stores/stats';
import { get } from 'lodash';
import moment from 'moment';
import { CloseOutlined } from '@ant-design/icons';
import { useVirtual } from 'react-virtual';
import { useMemo } from 'react';
import classNames from 'classnames';
import { DATE_FORMAT } from '@config/format';
import { busGroups } from '@stores/buses';

interface StatsLegendProps {
  isOneDay: boolean;
}

const StatsLegend: FunctionComponent<StatsLegendProps> = ({ isOneDay }) => {
  const content = useTranslate(componentContent);
  const activeDate = useStore($activeRef);
  const dataKey = useStore($dataKey);
  const { selectedBuses, reportTimezone, tickFormat, routeIds } =
    useStore(barChartSource);
  const hoveredData = useStore($hoveredRef);
  const groupByKey = useStore($groupByKey);

  const selectedRoutes = useStoreMap({
    store: busGroups,
    keys: [routeIds.join(',')],
    fn: (state, [ids]) => ids.split(',').map((id) => state[id]),
  });

  const items = {
    bus: selectedBuses,
    route: selectedRoutes,
  };

  const selectedItems = items[groupByKey];

  const selectedData = useStoreMap({
    store: barChartSource,
    keys: [activeDate, groupByKey],
    fn: (state, [value]) => {
      if (!value) return null;
      const item = state.dataBy[groupByKey].find((i) => i.timeValue === value);
      return item || null;
    },
  });

  const oneDayData = useStoreMap({
    store: barChartSource,
    keys: [isOneDay, groupByKey],
    fn: (state, [isOneDay]) => {
      if (!isOneDay) return null;
      const item = state.dataBy[groupByKey][0];
      return item || null;
    },
  });

  const isActiveDateToday = useMemo(() => {
    return activeDate
      ? moment(activeDate).tz(reportTimezone).isSame(moment(), 'day')
      : false;
  }, [activeDate, reportTimezone]);

  const isHoveredDateToday = useMemo(() => {
    return hoveredData
      ? moment(hoveredData.timeValue).tz(reportTimezone).isSame(moment(), 'day')
      : false;
  }, [hoveredData, reportTimezone]);

  const isToday = isActiveDateToday || isHoveredDateToday;

  const title = oneDayData
    ? `${moment(oneDayData.timeValue).tz(reportTimezone).format(DATE_FORMAT)}`
    : activeDate
    ? `${moment(activeDate).tz(reportTimezone).format(tickFormat)}`
    : hoveredData
    ? `${moment(hoveredData.timeValue).tz(reportTimezone).format(tickFormat)}`
    : null;

  const parentRef = React.useRef(null);

  const rowVirtualizer = useVirtual({
    size: selectedItems ? selectedItems.length : 0,
    parentRef,
    estimateSize: React.useCallback(() => 21, []),
  });

  const renderItem = (index: number) => {
    const item = selectedItems[index];
    if (!item) return null;
    const formattedValue = (
      oneDayData
        ? get(oneDayData, [item.id, dataKey], '')
        : selectedData
        ? get(selectedData, [item.id, dataKey], '')
        : hoveredData
        ? get(hoveredData, [item.id], '')
        : ''
    ).toLocaleString();
    const postfix = dataKey === 'fare' ? ' ₽' : '';
    if (!item) return null;
    return (
      <StatsLegendItem
        item={item}
        index={index}
        key={`${item.id}_${index}`}
        value={formattedValue ? formattedValue + postfix : ''}
      />
    );
  };

  return (
    <div className={css.legend}>
      <div className={css.title}>
        <div className={css.titleContent}>{title}</div>
        {activeDate ? (
          <CloseOutlined className={css.close} onClick={() => setRef(null)} />
        ) : null}
      </div>
      {isToday ? (
        <div className={css.inProgress}>{content.inProgress}</div>
      ) : null}
      <div
        className={classNames(css.legendScroll, { [css.short]: isToday })}
        ref={parentRef}
      >
        <div
          style={{
            height: `${rowVirtualizer.totalSize}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {rowVirtualizer.virtualItems.map((virtualRow) => (
            <div
              key={virtualRow.index}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              {renderItem(virtualRow.index)}
            </div>
          ))}
        </div>
      </div>
      <div className={css.timezone}>
        <div>{content.timezoneLabel}</div>
        <div>{reportTimezone}</div>
      </div>
    </div>
  );
};

export default StatsLegend;
