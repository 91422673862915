import { createEffect, createEvent, createStore, sample } from 'effector';
import ICalculatorData from '@interfaces/ICalculatorData';
import request from '@helpers/request';
import api from '@constants/endpoints';
import { get, isEqual } from 'lodash';
import createPreloadersStore from '@stores/preloaders';
import { createDebounce } from '@helpers/effector-debounce';
import { getData } from '@stores/date';
import { item as $bus, getItem } from '@stores/bus';
import { getList } from '@stores/periods';
import moment from 'moment';
import { BUS_DEFAULT_TIMEZONE, SERVER_DATE_FORMAT } from '@config/format';

export const setCalculatorData = createEvent<Partial<ICalculatorData>>(
  'set calculator data',
);

export const updateData = createEffect({
  name: 'updateData',
  async handler(
    payload: Partial<ICalculatorData & { isShiftUpdated: boolean }>,
  ) {
    const { isShiftUpdated, ...data } = payload;
    if (data) {
      const response = await request(api.calculation.update, { data });
      return response.data;
    }
  },
});

const $busCalculator = createStore<Partial<ICalculatorData> | null>(null)
  .on(getData.done, (state, { result: { calculator } }) => calculator)
  .on(getItem.done, (state, { result: { bus_calculation } }) => bus_calculation)
  .on(setCalculatorData, (state, payload) => ({ ...state, ...payload }))
  .on(updateData.done, (state, { result }) =>
    isEqual(result, state) ? state : result,
  );

const bouncedCalculatorEvent = createDebounce($busCalculator, 500);

export const $serverData = createStore<Partial<ICalculatorData> | null>(null)
  .on(getData.done, (state, { result: { calculator } }) => calculator)
  .on(getItem.done, (state, { result: { bus_calculation } }) => bus_calculation)
  .on(updateData.done, (state, { result }) =>
    isEqual(result, state) ? state : result,
  );

export const preloaders = createPreloadersStore([updateData, getData]);

sample({
  source: [$busCalculator, $serverData],
  target: updateData,
  clock: [bouncedCalculatorEvent],
  fn: ([localData, serverData]: any) => {
    if (!isEqual(localData, serverData)) {
      const isShiftUpdated =
        !!localData &&
        (get(localData, 'shift_start') !== get(serverData, 'shift_start') ||
          get(localData, 'shift_end') !== get(serverData, 'shift_end'));
      return { ...localData, isShiftUpdated };
    } else {
      return null;
    }
  },
});

sample({
  source: [$bus],
  target: getList,
  clock: updateData.done,
  filter: ([bus], { params }) => {
    const { isShiftUpdated } = params;
    return !!isShiftUpdated;
  },
  fn: ([bus], { result: newData }) => {
    return {
      date: moment.tz(
        get(newData, 'date', ''),
        SERVER_DATE_FORMAT,
        bus.timezone || BUS_DEFAULT_TIMEZONE,
      ),
      bus,
    };
  },
});

export default $busCalculator;
