import React, { FunctionComponent } from 'react';
import IBus, { IBusGroup } from '@interfaces/IBus';
import css from './Bus.module.less';
import Camera from './components/Camera';
import { getPath } from '@helpers/navigation';
import { get, sortBy } from 'lodash';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import {
  VerticalAlignBottomOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Dropdown, Menu, Typography } from 'antd';
import { useStore, useStoreMap } from 'effector-react';
import { busGroups, setGroup, UNGROUP_ID } from '@stores/buses';
import componentContent from './Bus.content.json';
import useTranslate from '@hooks/useTranslate';
import { preloaders } from '@stores/bus';
import useAdmin from '@hooks/useAdmin';
import { $customerGroups } from '@stores/customer-groups';

interface BusProps {
  item: IBus;
  groupIds: IBusGroup['id'][];
}

const Bus: FunctionComponent<BusProps> = ({ item, groupIds }) => {
  const customerGroups = useStore($customerGroups);
  const isAdmin = useAdmin();
  const url = getPath('bus', { busId: item.id });
  const allGroups = useStore(busGroups);
  const content = useTranslate(componentContent);
  const groups = isAdmin
    ? sortBy(
        groupIds
          .filter((gid) => get(item, ['group', 'id']) !== gid)
          .map((gid) => allGroups[gid]),
        'name',
      )
    : sortBy(
        customerGroups.filter((g) => get(item, ['group', 'id']) !== g.id),
        'name',
      );
  const loading = useStoreMap({
    store: preloaders,
    keys: [item.id],
    fn: (state, [key]) => state.setGroup === key,
  });

  return (
    <Link to={url} className={cn(css.root, 'ant-card', 'ant-card-bordered')}>
      <h3>
        {item.name}
        {isAdmin && item.group_device_id ? (
          <div>
            <div className={css.deviceId} onClick={(e) => e.preventDefault()}>
              {content.deviceId}:{' '}
              <Typography.Text copyable>{item.group_device_id}</Typography.Text>
            </div>
          </div>
        ) : null}
      </h3>
      <div className={css.cameras}>
        {sortBy(item.cameras, 'stream_id').map((camera) => (
          <Camera item={camera} key={camera.id} />
        ))}
      </div>
      {groups.length > 1 ? (
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu
              onClick={({ key, domEvent }) => {
                domEvent.stopPropagation();
                setGroup({
                  workplaceId: item.id,
                  groupId: key === UNGROUP_ID ? null : key,
                  preloaderId: item.id,
                });
              }}
            >
              {groups.map((g) =>
                g ? (
                  <Menu.Item key={g.id}>
                    {content.removeTo} {g.name}
                  </Menu.Item>
                ) : item.group ? (
                  <Menu.Item key={UNGROUP_ID}>{content.ungroup}</Menu.Item>
                ) : null,
              )}
            </Menu>
          }
        >
          <div className={css.remove}>
            {loading ? <LoadingOutlined /> : <VerticalAlignBottomOutlined />}
          </div>
        </Dropdown>
      ) : null}
    </Link>
  );
};

export default Bus;
