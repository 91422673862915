import React, { FunctionComponent } from 'react';
import css from './GetBusReportButton.module.less';
import componentContent from './GetBusReportButton.content.json';
import useTranslate from '@hooks/useTranslate';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { useStore } from 'effector-react';
import dateStore from '@stores/date';
import $bus, { getReport, getReportWithTariff } from '@stores/bus';
import { Tooltip } from 'antd';
import { SERVER_DATE_FORMAT } from '@config/format';
import { get } from 'lodash';
import moment from 'moment';
import { IBusGroup } from '@interfaces/IBus';

interface GetBusReportButtonProps {}

const GetBusReportButton: FunctionComponent<GetBusReportButtonProps> = () => {
  const content = useTranslate(componentContent);
  const date = useStore(dateStore);
  const { item: bus, preloaders } = useStore($bus);
  const calcType: IBusGroup['type_calculation'] = get(bus, [
    'group',
    'type_calculation',
  ]);

  const download = () => {
    getReport({
      params: {
        workplaceIds: [bus.id],
        from: date?.formattedStartdate,
        to: date?.formattedEnddate,
      },
    });
  };

  const downloadWithTarif = () => {
    getReportWithTariff({
      params: {
        workplaceIds: [bus.id],
        date: get(date, 'date', moment()).format(SERVER_DATE_FORMAT),
      },
    });
  };

  return (
    <div className={css.root}>
      {preloaders.getReport || preloaders.getReportWithTariff ? (
        <LoadingOutlined />
      ) : (
        <Tooltip title={content.download}>
          <DownloadOutlined
            onClick={calcType === 'CALCULATION' ? downloadWithTarif : download}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default GetBusReportButton;
