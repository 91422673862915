import React, { FunctionComponent } from 'react';
import css from './RouteTabs.module.less';
import componentContent from './RouteTabs.content.json';
import useTranslate from '@hooks/useTranslate';
import { Tabs } from 'antd';
import { useStore } from 'effector-react';
import { $selectedTab, setSelectedTab, TabName } from '@stores/route';
import $date from '@stores/date';
import { get } from 'lodash';

interface RouteTabsProps {}

const RouteTabs: FunctionComponent<RouteTabsProps> = () => {
  const content = useTranslate(componentContent);
  const selectedTab = useStore($selectedTab);
  const dateState = useStore($date);
  const date = get(dateState, 'formattedDate');

  return (
    <div className={css.root}>
      <Tabs
        type="card"
        size="small"
        activeKey={selectedTab}
        onChange={(key) => setSelectedTab(key as TabName)}
      >
        {['stops', 'price', 'routes'].map((key) => (
          <Tabs.TabPane key={key} tab={content[key]}></Tabs.TabPane>
        ))}
        {date ? (
          <Tabs.TabPane key="actions" tab={content.actions}></Tabs.TabPane>
        ) : null}
      </Tabs>
    </div>
  );
};

export default RouteTabs;
