import React, { FunctionComponent, useEffect } from 'react';
import { DatePicker, InputNumber } from 'antd';
import css from './Sumcount.module.less';
import componentContent from './Sumcount.content.json';
import useTranslate from '@hooks/useTranslate';
import cn from 'classnames';
import {
  LogoutOutlined,
  LoginOutlined,
  SyncOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import Tooltip from 'antd/es/tooltip';

import { useStore } from 'effector-react';
import { get } from 'lodash';
import {
  SERVER_DATE_FORMAT,
  TIME_FORMAT,
  TIME_FORMAT_WITH_SECONDS,
} from '@config/format';
import $busCalculator, {
  setCalculatorData,
  preloaders as $preloaders,
} from '@stores/busCalculator';
import FareCorrection from '@components/FareCorrection';
import MobileTimePicker from '@components/MobileTimePicker';
import moment, { Moment } from 'moment';
import { Spin } from 'antd';
import { useState } from 'react';
import useMedia from '@hooks/useMedia';
import classNames from 'classnames';
import useTotalSum from '@hooks/useTotalSum';
import { item, setTab } from '@stores/bus';
import $date from '@stores/date';
import { setSelectedTab } from '@stores/route';
import { setLap } from '@stores/gps';
import useAdmin from '@hooks/useAdmin';
import { MIN_FARE } from '@constants/limits';
import { recalcDay } from '@stores/day-summary';

interface SumcountProps {}

type Direction = 'in' | 'out';

const Sumcount: FunctionComponent<SumcountProps> = () => {
  const content = useTranslate(componentContent);
  const preloaders = useStore($preloaders);

  const calculatorState = useStore($busCalculator);
  const bus = useStore(item);
  const routeId = get(bus, 'id');
  const dateState = useStore($date);

  const isRecalculating = useStore(recalcDay.pending);

  const correction = get(calculatorState, 'cashless', 0);
  const fare = get(calculatorState, 'fare', MIN_FARE) || MIN_FARE;
  const percent = get(calculatorState, 'salary_percent', 0) || 0;
  const direction: Direction =
    get(calculatorState, 'calculation_event', 'ENTERED') === 'ENTERED'
      ? 'in'
      : 'out';

  const [shift, setShift] = useState<{
    start: Moment | null;
    end: Moment | null;
  }>({
    start: get(calculatorState, 'shift_start')
      ? moment(get(calculatorState, 'shift_start'), TIME_FORMAT_WITH_SECONDS)
      : null,
    end: get(calculatorState, 'shift_end')
      ? moment(get(calculatorState, 'shift_end'), TIME_FORMAT_WITH_SECONDS)
      : null,
  });

  const { totalSum, calcSum, restSum } = useTotalSum();

  useEffect(() => {
    setShift({
      start: get(calculatorState, 'shift_start')
        ? moment(get(calculatorState, 'shift_start'), TIME_FORMAT_WITH_SECONDS)
        : null,
      end: get(calculatorState, 'shift_end')
        ? moment(get(calculatorState, 'shift_end'), TIME_FORMAT_WITH_SECONDS)
        : null,
    });
  }, [calculatorState]);

  const setShiftField = (field: 'start' | 'end', value: Moment | null) => {
    setShift({
      ...shift,
      [field]: value,
    });
    setCalculatorData({
      shift_end:
        field === 'end'
          ? value
            ? value.format(TIME_FORMAT_WITH_SECONDS)
            : undefined
          : get(calculatorState, 'shift_end'),
      shift_start:
        field === 'start'
          ? value
            ? value.format(TIME_FORMAT_WITH_SECONDS)
            : undefined
          : get(calculatorState, 'shift_start'),
    });
  };
  const hasCorrection = Number.isFinite(correction) && correction !== 0;
  const { isTabletOrMobile } = useMedia();

  const goToTarification = () => {
    setTab('gps');
    setLap('route');
    setSelectedTab('price');
  };

  const isAdmin = useAdmin();

  const hasTarification =
    get(bus, ['group', 'type_calculation']) === 'CALCULATION' &&
    get(bus, ['group', 'track', 'length']) &&
    isAdmin;

  return (
    <div className={classNames(css.root, { [css.mobile]: isTabletOrMobile })}>
      <div className={css.inputs}>
        <div className={css.inputRow}>
          <div className={css.label}>
            {hasTarification ? content.busFareTarif : `${content.busFare}, ₽`}
          </div>
          <div className={css.inputGroup}>
            {hasTarification ? (
              <div
                className={css.tarification}
                onClick={() => goToTarification()}
              >
                {content.tarification}
              </div>
            ) : (
              <>
                {direction === 'in' ? (
                  <Tooltip title={content.showByIn}>
                    <LoginOutlined
                      onClick={() =>
                        setCalculatorData({ calculation_event: 'EXITED' })
                      }
                      className={cn(css.directionIcon, css[direction])}
                    />
                  </Tooltip>
                ) : null}
                {/* @ts-ignore */}
                {direction === 'out' ? (
                  <Tooltip title={content.showByOut}>
                    <LogoutOutlined
                      onClick={() =>
                        setCalculatorData({ calculation_event: 'ENTERED' })
                      }
                      className={cn(css.directionIcon, css[direction])}
                    />
                  </Tooltip>
                ) : null}

                <InputNumber
                  size="small"
                  type="number"
                  min={MIN_FARE}
                  value={fare}
                  onChange={(value) =>
                    setCalculatorData({ fare: value as number })
                  }
                />
              </>
            )}
          </div>
        </div>
        <div className={css.inputRow}>
          <div className={css.label}>{content.percent}, %</div>
          <InputNumber
            size="small"
            type="number"
            min={0}
            max={100}
            value={percent}
            onChange={(value) =>
              setCalculatorData({ salary_percent: value as number })
            }
          />
        </div>
      </div>
      <div>
        <div className={css.sumLabel}>{content.total}</div>
        <div className={cn(css.sum, css.total, css[direction])}>
          {(totalSum || 0).toLocaleString('ru')} <span>₽</span>
          {isAdmin ? (
            <div className={css.recalcButton}>
              {isRecalculating ? (
                <LoadingOutlined />
              ) : (
                <SyncOutlined
                  onClick={() =>
                    recalcDay({
                      workplace_id: routeId,
                      date: dateState
                        ? dateState.date.format(SERVER_DATE_FORMAT)
                        : undefined,
                    })
                  }
                />
              )}
            </div>
          ) : null}
        </div>
        <div className={css.row}>
          <div>
            <div className={css.sumLabel}>{content.salary}</div>
            <div className={cn(css[direction], css.sum)}>
              {(restSum || 0).toLocaleString('ru')} <span>₽</span>
            </div>
          </div>
          <div className={css.verticalDivider} />
          <div>
            <div className={css.sumLabel}>{content.sum}</div>
            <div className={cn(css[direction], css.sum)}>
              {(calcSum || 0).toLocaleString('ru')} <span>₽</span>{' '}
              {hasCorrection ? null : <FareCorrection />}
              {hasCorrection ? (
                <div className={css.correction}>
                  {correction > 0 ? '+' : ''}
                  {(correction || 0).toLocaleString('ru')} <FareCorrection />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(css.shiftTime, {
          [css.mobile]: isTabletOrMobile,
        })}
      >
        <div
          className={classNames(css.timeItem, {
            [css.mobile]: isTabletOrMobile,
          })}
        >
          <div className={css.label}>{content.shiftStart}</div>
          <Spin spinning={!!preloaders.updateData}>
            {isTabletOrMobile ? (
              <MobileTimePicker
                title={content.time}
                value={shift.start}
                onChange={(value) => setShiftField('start', value)}
              />
            ) : (
              <DatePicker.TimePicker
                onChange={(value) => setShiftField('start', value)}
                value={shift.start}
                size="small"
                format={TIME_FORMAT}
                placeholder={content.time}
              />
            )}
          </Spin>
        </div>
        <div
          className={classNames(css.timeItem, {
            [css.mobile]: isTabletOrMobile,
          })}
        >
          <div className={css.label}>{content.shiftEnd}</div>
          <Spin spinning={!!preloaders.updateData}>
            {isTabletOrMobile ? (
              <MobileTimePicker
                title={content.time}
                value={shift.end}
                onChange={(value) => setShiftField('end', value)}
              />
            ) : (
              <DatePicker.TimePicker
                onChange={(value) => setShiftField('end', value)}
                value={shift.end}
                size="small"
                format={TIME_FORMAT}
                placeholder={content.time}
              />
            )}
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default Sumcount;
