import { AxiosRequestConfig } from 'axios';

interface IApiEndpoints {
  [controller: string]: {
    [endpoint: string]: AxiosRequestConfig;
  };
}

const api: IApiEndpoints = {
  auth: {
    getToken: {
      url: 'oauth/token',
      method: 'POST',
    },
    getMe: {
      url: 'api/auth/me',
      method: 'GET',
    },
    logOut: {
      url: 'api/auth/logout',
      method: 'POST',
    },
  },
  buses: {
    list: {
      url: 'api/bus_list',
      method: 'GET',
    },
    item: {
      url: '/api/bus/info/{busId}',
      method: 'GET',
    },
    updateStatus: {
      url: 'api/workplaces/status/{busId}',
      method: 'GET',
    },
    setGroup: {
      url: '/api/set-group',
      method: 'PUT',
    },
  },
  periods: {
    list: {
      url: 'api/period_list/{cameraId}',
      method: 'GET',
    },
  },
  video: {
    segment: {
      url: 'api/video_feed/{segmentId}?processed=1&hash={fileHash}',
      method: 'GET',
    },
    download: {
      url: 'api/download/{segmentId}?processed=0',
      method: 'GET',
    },
    file: {
      url: 'api/video_feed2/{segmentId}?processed=1&hash={fileHash}',
    },
    preload: {
      url: 'api/segments/set-downloading/{segmentId}',
      method: 'PATCH',
    },
    preloadAll: {
      url: 'api/segments/set-downloading',
      method: 'POST',
    },
  },
  segments: {
    updateItem: {
      url: 'api/segments/update-events/{segmentId}',
      method: 'POST',
    },
    recalc: {
      url: '/api/segments/recalculate/{cameraId}',
      method: 'POST',
    },
    getList: {
      url: '/api/segments/day-segments',
      method: 'GET',
    },
  },
  messages: {
    list: {
      url: '/api/news/active-messages',
      method: 'GET',
      params: {
        active: true,
      },
    },
  },
  stats: {
    list: {
      url: 'api/stat/passenger-traffic',
      method: 'GET',
    },
    getDay: {
      url: 'api/stat/day-traffic',
      method: 'GET',
    },
    export: {
      url: 'api/stat/passenger-traffic/export',
      method: 'GET',
      responseType: 'blob',
    },
    exportWithTarif: {
      url: 'api/stat/tariff-calc/export',
      method: 'GET',
      responseType: 'blob',
    },
    workplaces: {
      url: 'api/stat/workplaces-report',
      method: 'GET',
      responseType: 'blob',
    },
    getHaltStats: {
      url: 'api/stat/bus-stop/stat',
      method: 'GET',
    },
    getDayResult: {
      url: 'api/stat/day-results',
      method: 'GET',
    },
  },
  gps: {
    list: {
      url: 'api/gps',
      method: 'GET',
    },
    halts: {
      url: 'api/bus-stop/search',
      method: 'GET',
    },
    laps: {
      url: 'api/bus-stop/laps',
      method: 'GET',
    },
    lapsByDay: {
      url: 'api/bus-stop/day-laps',
      method: 'GET',
    },
    getPosition: {
      url: 'api/workplaces/gps/{busId}',
      method: 'GET',
    },
    setTerminal: {
      url: '/api/bus-stop/set-terminal-bus-stop',
      method: 'POST',
    },
  },
  calculation: {
    get: {
      url: '/api/workplaces/calculation',
      method: 'GET',
    },
    update: {
      url: '/api/workplaces/calculation',
      method: 'PUT',
    },
    recalc: {
      url: '/api/stat/passenger-traffic/recalculate-bus',
      method: 'POST',
    },
  },
  news: {
    list: {
      url: '/api/news/search',
      method: 'GET',
      params: {
        sortBy: 'createdAt',
        sortType: 'DESC',
        perPage: 5000,
        type: 'NEWS',
        active: true,
      },
    },
    all: {
      url: '/api/news/search',
      method: 'GET',
      params: {
        active: false,
      },
    },
    read: {
      url: '/api/news/{newsId}/read',
      method: 'POST',
    },
  },
  shift: {
    save: {
      url: '/api/workplaces/shift',
      method: 'POST',
    },
  },
  route: {
    generateRoute: {
      url: '/api/groups/{groupId}/track/from-lap',
      method: 'POST',
    },
    generateStops: {
      url: '/api/bus-stop/update/{workplaceId}',
      method: 'POST',
    },
    uploadGpx: {
      url: '/api/groups/from-file',
      method: 'POST',
    },
    uploadGpxAndUpdate: {
      url: '/api/groups/from-file',
      method: 'PUT',
    },
    addRouteForBus: {
      url: '/api/groups/workplace',
      method: 'PUT',
    },
    deleteRouteForBus: {
      url: '/api/groups/workplace',
      method: 'DELETE',
    },
    setStops: {
      url: '/api/groups/stops/from-lap',
      method: 'POST',
    },
    getStops: {
      url: '/api/groups/{groupId}/stops',
      method: 'GET',
    },
    getGroup: {
      url: '/api/groups/{groupId}',
      method: 'GET',
    },
    updateStop: {
      url: '/api/groups/stop/{stopId}',
      method: 'POST',
    },
    deleteStop: {
      url: '/api/groups/stop/{stopId}',
      method: 'DELETE',
    },
    getCost: {
      url: '/api/route-cost/group/{groupId}',
      method: 'GET',
    },
    updateCost: {
      url: '/api/route-cost',
      method: 'PUT',
    },
    setDirection: {
      url: '/api/groups/stops/terminal-stops',
      method: 'POST',
    },
    getListForCustomer: {
      url: '/api/groups/client/{customerId}',
      method: 'GET',
    },
  },
  concatMedia: {
    merge: {
      url: '/api/concatenated-medias/merge',
      method: 'POST',
    },
    getList: {
      url: '/api/concatenated-medias',
      method: 'GET',
    },
    getFile: {
      url: '/api/concatenated-medias/download/{requestId}',
      method: 'GET',
      responseType: 'blob',
    },
  },
  groups: {
    getList: {
      url: '/api/groups/search',
      method: 'GET',
    },
  },
};

export default api;
