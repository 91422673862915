import React, { FunctionComponent } from 'react';
import css from './MobileDayInfo.module.less';
// // import componentContent from './MobileDayInfo.content.json';
// // import useTranslate from '@hooks/useTranslate';
import { useStore } from 'effector-react';
import bus from '@stores/bus';
import DateSelector from '@components/DateSelector';
import GetBusReportButton from '@components/GetBusReportButton';
import MergeSegmentsButton from '@components/MergeSegmentsButton';
import RecalcButton from '@components/RecalcButton';
import MobileCountTab from '@components/MobileCountTab';
import MobileServiceTab from '@components/MobileServiceTab';
import BusTabs from '@components/BusTabs';
import MobileGpsTab from '@components/MobileGpsTab';
import classNames from 'classnames';
import useMedia from '@hooks/useMedia';
import useAdmin from '@hooks/useAdmin';

interface MobileDayInfoProps {}

const MobileDayInfo: FunctionComponent<MobileDayInfoProps> = () => {
  // const content = useTranslate(componentContent);

  const { item, serviceCameras, activeTab } = useStore(bus);
  const isAdmin = useAdmin();
  const { isAlbum } = useMedia();

  return (
    <div
      className={classNames(css.root, {
        [css.hasBottomPanel]: activeTab === 'count',
      })}
    >
      <div className={classNames(css.headContainer, { [css.album]: isAlbum })}>
        <div className={css.header}>
          <div className={css.dateRow}>
            <DateSelector />
            <GetBusReportButton />
            <MergeSegmentsButton />
            {isAdmin ? <RecalcButton /> : null}
          </div>
        </div>
        {serviceCameras.length || item.gps_trackable ? (
          <div className={css.busTabs}>
            <BusTabs mode={isAlbum ? 'selector' : 'tabs'} />
          </div>
        ) : null}
      </div>
      {activeTab === 'count' ? (
        <MobileCountTab />
      ) : activeTab === 'service' ? (
        <div className={css.serviceTab}>
          <MobileServiceTab />
        </div>
      ) : activeTab === 'gps' ? (
        <MobileGpsTab />
      ) : null}
    </div>
  );
};

export default MobileDayInfo;
