// import { message } from 'antd';
import IAuthUser from '@interfaces/IAuthUser';
import { get, isObject } from 'lodash';

class Storage {
  listeners: Record<string, any>;

  constructor() {
    this.listeners = {};
  }

  public read(key: string) {
    return localStorage.getItem(key);
  }

  public dispatch(key: string, data: any) {
    window.dispatchEvent(
      new CustomEvent('customStorage', { detail: { [key]: data } }),
    );
  }

  public readObject(key: string) {
    const text = this.read(key);
    let obj: Record<string, any> = {};
    try {
      obj = JSON.parse(text as string);
    } catch (error) {
      // message.error(error);
    }
    return obj;
  }

  public write(key: string, data: string, idObject?: boolean) {
    localStorage.setItem(key, data);
    if (!isObject) this.dispatch(key, data);
  }

  public writeObject(key: string, data: object | null) {
    const text = JSON.stringify(data);
    this.write(key, text, true);
    this.dispatch(key, data);
  }

  public remove(key: string) {
    localStorage.removeItem(key);
  }

  public readUser(): IAuthUser {
    return this.readObject('user') as IAuthUser;
  }

  public subscribe(key: string, handler: (data: any) => void) {
    const nativeHandler = (e: Event) => {
      const data = get(e, ['detail', key], null);
      handler(data);
    };
    this.listeners[key] = nativeHandler;
    window.addEventListener('customStorage', nativeHandler);
  }
}

const storage = new Storage();
export default storage;
