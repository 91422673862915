import { useStoreMap } from 'effector-react';
import { cameras as $cameras } from '@stores/bus';
import ICamera from '@interfaces/ICamera';
import { combineData } from '@stores/periods';
import { forEach, get } from 'lodash';
import { $preparedData } from '@stores/date';
import { preloaders as $periodPreloaders } from '@stores/periods';

const useSummary = () => {
  const { selectedCameraIds, allCameraIds } = useStoreMap({
    store: $cameras,
    keys: [],
    fn: (state) => {
      const selectedCameraIds: ICamera['id'][] = [];
      const allCameraIds: ICamera['id'][] = [];
      state.forEach((c) => {
        if (c.selected) {
          selectedCameraIds.push(c.id);
        }
        allCameraIds.push(c.id);
      });
      return { selectedCameraIds, allCameraIds };
    },
  });

  const isAllSelected = allCameraIds.join(',') === selectedCameraIds.join(',');

  const preparedData = useStoreMap({
    store: $preparedData,
    keys: [],
    fn: (state) => {
      return {
        eventSumIn: get(state, ['entered'], 0),
        eventSumOut: get(state, ['exited'], 0),
      };
    },
  });

  const loadingDetailed = useStoreMap({
    store: $periodPreloaders,
    keys: [],
    fn: (state) => !!state.getList,
  });

  const data = useStoreMap({
    store: combineData,
    keys: [selectedCameraIds.join(',')],
    fn: (state, [ids]) => {
      let eventSumIn = 0;
      let eventSumOut = 0;
      let adminEventSumIn = 0;
      let adminEventSumOut = 0;
      forEach(state.summary, (item, id) => {
        if (!ids.split(',').includes(`${id}`)) return;
        eventSumIn = eventSumIn + item.eventSumIn;
        eventSumOut = eventSumOut + item.eventSumOut;
        adminEventSumIn = adminEventSumIn + item.adminEventSumIn;
        adminEventSumOut = adminEventSumOut + item.adminEventSumOut;
      });
      return { eventSumIn, eventSumOut, adminEventSumIn, adminEventSumOut };
    },
  });

  return {
    ...data,
    ...(isAllSelected && loadingDetailed ? preparedData : null),
  };
};

export default useSummary;
