import React, { FunctionComponent } from 'react';
import css from './LapsTimeLine.module.less';
// import componentContent from './LapsTimeLine.content.json';
// import useTranslate from '@hooks/useTranslate';
import { useStore } from 'effector-react';
import {
  timeline as $timeline,
  selectedLapNumber as $selectedLapNumber,
  setLap,
} from '@stores/gps';
import cn from 'classnames';

interface LapsTimeLineProps {}

const LapsTimeLine: FunctionComponent<LapsTimeLineProps> = () => {
  const timeline = useStore($timeline);
  const selectedLapNumber = useStore($selectedLapNumber);
  // const content = useTranslate(componentContent);

  if (!timeline || !timeline.reachLaps.length) return null;

  return (
    <div className={css.root}>
      {timeline.reachLaps.map((lap, i) => {
        const isSelected = selectedLapNumber === lap.number;
        const isFirst = i === 0;
        const isLast = i === timeline.reachLaps.length - 1;
        return (
          <div
            onClick={() => setLap(lap.number)}
            key={lap.start}
            className={cn(css.lap, {
              [css.active]: isSelected,
            })}
            style={{
              left: `${lap.startPositionPercent}%`,
              width: `${lap.percent}%`,
            }}
          >
            {isSelected || isFirst ? (
              <div className={cn(css.start)}>{lap.formattedStart}</div>
            ) : null}
            {isSelected || isLast ? (
              <div className={css.end}>{lap.formattedEnd}</div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default LapsTimeLine;
