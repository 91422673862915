import { IEvent } from '@interfaces/IPeriod';
import moment from 'moment-timezone';

const createBarChartData = ({
  totalMax,
  totalMin,
  allEvents,
}: {
  totalMin: number;
  totalMax: number;
  allEvents: IEvent[];
}) => {
  const hoursMap = new Map();
  const barChartTicks: number[] = [];

  for (
    let currentTick = moment(totalMin).add(0.5, 'hour').valueOf();
    currentTick < totalMax;
    currentTick = moment(currentTick).add(1, 'hour').valueOf()
  ) {
    const hourInTimeline = moment(currentTick);
    const hourInTimelineFormatted = hourInTimeline.format();

    barChartTicks.push(hourInTimeline.startOf('hour').valueOf());

    hoursMap.set(hourInTimelineFormatted, {
      entered: 0,
      exited: 0,
      timeValue: currentTick,
      name: currentTick,
    });
  }
  barChartTicks.push(totalMax);

  allEvents.forEach((event) => {
    const { entered, exited, start_time } = event;
    const hourInTimeline = moment(start_time).startOf('hour').add(0.5, 'hour');
    const hourInTimelineFormatted = hourInTimeline.format();

    const createdHour = hoursMap.get(hourInTimelineFormatted);
    if (createdHour) {
      hoursMap.set(hourInTimelineFormatted, {
        ...createdHour,
        entered: createdHour.entered + entered,
        exited: createdHour.exited + exited,
      });
    }
  });

  const barChartData = Array.from(hoursMap.values());

  return {
    ticks: barChartTicks,
    data: barChartData,
  };
};

export default createBarChartData;
