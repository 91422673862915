import IBus, { IBusGroup } from '@interfaces/IBus';
import ICustomer from '@interfaces/ICustomer';
import { listByCustomers, UNGROUP_ID } from '@stores/buses';
import { useStoreMap } from 'effector-react';
import { filter, get, sortBy } from 'lodash';

const useBusesNavigation = ({
  currentClientId,
  item,
  allGroups,
}: {
  currentClientId: ICustomer['id'];
  item: IBus;
  allGroups: Record<IBusGroup['id'], IBusGroup | null>;
}) => {
  const [previousBusId, nextBusId] = useStoreMap({
    store: listByCustomers,
    keys: [currentClientId, item.id, allGroups],
    fn(state, [clientId, busId, allGroups]) {
      const empty = [undefined, undefined, undefined, undefined];
      const groups = state.get(clientId);
      if (!groups) return empty;
      const clientGroupIds = Array.from(groups.keys());
      const clientGroups = filter(allGroups, (group, id) =>
        group ? clientGroupIds.includes(group.id) : false,
      );
      const sortedClientGroups = sortBy(
        [{ id: UNGROUP_ID, name: UNGROUP_ID }, ...clientGroups],
        'name',
      );
      const buses: IBus[] = [];
      sortedClientGroups.forEach((g) => {
        const group = groups.get(g ? g.id : UNGROUP_ID);
        if (group) buses.push(...sortBy(group, 'name'));
      });
      const busIndex = buses.findIndex((b) => b.id === busId);
      if (busIndex < 0) return empty;
      const firstBusId = buses.length > 1 ? get(buses, [0, 'id']) : undefined;
      const lastBusId =
        buses.length > 1 ? get(buses, [buses.length - 1, 'id']) : undefined;
      const previousBusIndex = busIndex - 1;
      const nextBusIndex = busIndex + 1;
      const previousBusId = get(buses, [previousBusIndex, 'id']);
      const nextBusId = get(buses, [nextBusIndex, 'id']);
      return [previousBusId || lastBusId, nextBusId || firstBusId];
    },
  });
  return [previousBusId, nextBusId];
};

export default useBusesNavigation;
