import React, { FunctionComponent } from 'react';
import css from './StatsTimeline.module.less';
import componentContent from './StatsTimeline.content.json';
import useTranslate from '@hooks/useTranslate';
import { Radio } from 'antd';
import classNames from 'classnames';
import useMedia from '@hooks/useMedia';
import stats, { setDataKey, setGroupByKey } from '@stores/stats';
import { useStore } from 'effector-react';
import { LoginOutlined, LogoutOutlined } from '@ant-design/icons';

interface DataDisplayParamsProps {
  totalCount: number;
}

const DataDisplayParams: FunctionComponent<DataDisplayParamsProps> = ({
  totalCount,
}) => {
  const content = useTranslate(componentContent);
  const { isTabletOrMobile } = useMedia();
  const { dataKey, groupByKey } = useStore(stats);

  return (
    <div
      className={classNames(css.totalContainer, {
        [css.mobile]: isTabletOrMobile,
      })}
    >
      <div className={css.selector}>
        <Radio.Group
          onChange={(e) => setDataKey(e.target.value)}
          value={dataKey}
        >
          <Radio.Button value="entered">
            {isTabletOrMobile ? <LoginOutlined /> : content.enteredLabel}
          </Radio.Button>
          <Radio.Button value="exited">
            {isTabletOrMobile ? <LogoutOutlined /> : content.exitedLabel}
          </Radio.Button>
          <Radio.Button value="fare">
            {isTabletOrMobile ? (
              <span className={css.rubIcon}>₽</span>
            ) : (
              content.fareLabel
            )}
          </Radio.Button>
        </Radio.Group>
        <Radio.Group
          onChange={(e) => setGroupByKey(e.target.value)}
          value={groupByKey}
        >
          <Radio.Button value="bus">{content.bus}</Radio.Button>
          <Radio.Button value="route">{content.route}</Radio.Button>
        </Radio.Group>
      </div>
      <div className={css.total}>
        <span className={css.label}>{content[dataKey]}</span>
        <div className={css.totalSumNum}>
          <div className={css.totalSum}>
            {totalCount.toLocaleString('ru')}
            {dataKey === 'fare' ? ` ₽` : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataDisplayParams;
