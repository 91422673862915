import React, { FunctionComponent } from 'react';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  Area,
  ReferenceLine,
  CategoricalChartWrapper,
  AreaProps,
  BarChart,
  Bar,
} from 'recharts';

import stats, { hoverBus, setHoveredData, setRef } from '@stores/stats';

import CustomStatsTooltip from '@components/CustomStatsTooltip';
import { useStore } from 'effector-react';
import moment from 'moment-timezone';
import chartColors from '@constants/chartColors';
import { GREEN } from '@constants/colors';

interface ChartProps {
  data: any[];
  isOneDay: boolean;
}

const Chart: FunctionComponent<ChartProps> = ({ data, isOneDay }) => {
  const { barChartSource, hoveredBusId, activeRef, groupByKey } =
    useStore(stats);
  const chartProps: CategoricalChartWrapper = {
    data: data,
    onClick: ({ activeLabel }) => setRef(activeLabel),
    onMouseLeave: () => setHoveredData(null),
  };

  const getAreaProps = (id: string, i: number) =>
    ({
      key: id,
      type: 'monotone',
      dataKey: id,
      stackId: isOneDay ? undefined : 1,
      fill: chartColors[i] || GREEN,
      opacity: hoveredBusId ? (`${hoveredBusId}` === `${id}` ? 1 : 0.5) : 1,
    } as AreaProps);

  const busIds = barChartSource.selectedBuses.map((bus) => bus.id);
  const routeIds = barChartSource.routeIds;

  const ids = {
    bus: busIds,
    route: routeIds,
  };

  if (isOneDay)
    return (
      <ResponsiveContainer width="100%" height={360}>
        <BarChart data={data}>
          <XAxis dataKey="timeValue" tickFormatter={() => ''} />
          <YAxis />
          {ids[groupByKey].map((id, i: number) => (
            <Bar
              {...getAreaProps(id, i)}
              onMouseEnter={() => hoverBus(id)}
              onMouseLeave={() => hoverBus(null)}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    );

  return (
    <ResponsiveContainer width="100%" height={360}>
      <AreaChart {...chartProps}>
        <Tooltip
          content={
            <CustomStatsTooltip
              timezone={barChartSource.reportTimezone}
              period={barChartSource.tickType}
              tickFormat={barChartSource.tickExpandedFormat}
              items={barChartSource.selectedBuses}
            />
          }
          cursor={{ fill: '#00000050' }}
          wrapperStyle={{ top: 80, left: -240 }}
          allowEscapeViewBox={{ x: false, y: false }}
          coordinate={{ x: 1667883600000, y: 0 }}
        />
        <XAxis
          dataKey="timeValue"
          type="number"
          domain={[
            barChartSource.lineChartTicks[0],
            barChartSource.lineChartTicks[
              barChartSource.lineChartTicks.length + 1
            ],
          ]}
          ticks={barChartSource.lineChartTicks}
          interval="preserveStartEnd"
          tickFormatter={(tick) =>
            moment(tick)
              .tz(barChartSource.reportTimezone)
              .format(barChartSource.tickFormat)
          }
        />
        <YAxis type="number" domain={[0, 'dataMax+10']} />
        {activeRef ? <ReferenceLine x={activeRef} stroke="white" /> : null}
        {ids[groupByKey].map((id, i: number) => (
          <Area {...getAreaProps(id, i)} />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default Chart;
