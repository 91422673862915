import { TIME_FORMAT } from '@config/format';
import ICamera from '@interfaces/ICamera';
import IPeriod, { IRichPeriod } from '@interfaces/IPeriod';
import moment from 'moment-timezone';
import { get } from 'lodash';
import IGpsPoint from '../interfaces/IGpsPoint';

const enrichSegment = ({
  segment,
  totalMin,
  fullLength,
  cameraId,
  stream_id,
  timezone,
}: {
  segment: IPeriod;
  totalMin: number;
  fullLength: number;
  cameraId: ICamera['id'];
  stream_id: string;
  timezone: string;
}) => {
  const startValue = moment(segment.start_time).valueOf();
  const endValue = moment(segment.end_time).valueOf();

  const left = (100 * (startValue - totalMin)) / fullLength + '%';
  const right = 100 - (100 * (endValue - totalMin)) / fullLength + '%';
  const formattedFrom = moment(segment.start_time)
    .tz(timezone)
    .format(TIME_FORMAT);
  const formattedTo = moment(segment.end_time).tz(timezone).format(TIME_FORMAT);
  const timeValue = moment(segment.end_time).tz(timezone).valueOf();
  const durationValue = moment(segment.end_time).diff(
    moment(segment.start_time),
  );
  const durationObject = moment.duration(durationValue);
  const duration = `${durationObject.hours()}h ${durationObject.minutes()}m ${durationObject.seconds()}s`;
  const gps: IGpsPoint[] = get(segment, ['gps'], []);
  const gpsPoints: IRichPeriod['gpsPoints'] = gps.map((p) => [
    p.latitude,
    p.longitude,
  ]);

  let info: Record<string, any> = {};
  try {
    info = segment.info ? JSON.parse(segment.info as string) : {};
  } catch (error) {
    console.error(error);
  }

  const reachSegment: IRichPeriod = {
    ...segment,
    info,
    left,
    right,
    formattedFrom,
    formattedTo,
    timeValue,
    duration,
    cameraId,
    stream_id,
    gpsPoints,
  };

  return reachSegment;
};

export default enrichSegment;
