import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { get, merge, forEach } from 'lodash';
import { message } from 'antd';
import storage from './localStorage';
import { logout, saveTokens, LogoutReasonEnum } from '@stores/auth';
import api from '@constants/endpoints';
import qs from 'qs';
import appConfig from '@config/app';
import BASIC_AUTH_TOKEN from '@constants/basicAuthToken';

interface AdditionalOptions extends AxiosRequestConfig {
  urlParams?: Record<string, string | undefined>;
}

class RefreshAuthLogicError extends Error {}

const instance = axios.create({
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  baseURL: appConfig.apiUrl,
});

const refreshInstance = axios.create({
  baseURL: appConfig.apiUrl,
});

const getAccessToken = (requestOptions: AxiosRequestConfig) => {
  const basicAuthToken = get(
    requestOptions,
    ['headers', 'Authorization'],
    '',
  ).includes('Basic')
    ? requestOptions.headers.Authorization
    : null;

  const bearerAuthToken = get(
    storage.readObject('tokens'),
    ['access_token'],
    null,
  );

  if (bearerAuthToken && !basicAuthToken) {
    return `Bearer ${bearerAuthToken}`;
  }

  if (basicAuthToken) {
    return basicAuthToken;
  }
};

instance.interceptors.request.use((request) => {
  request.headers['Authorization'] = getAccessToken(request);
  return request;
});

const checkStatus = (response: AxiosResponse) => {
  if (response.status >= 200 && response.status < 300) {
    return;
  }
  throw new Error(response.statusText);
};

const refreshAuthLogic = async (failedRequest: any) => {
  const refresh_token = storage.readObject('tokens').refresh_token;
  const Authorization = BASIC_AUTH_TOKEN;
  const body = qs.stringify({
    grant_type: 'refresh_token',
    refresh_token,
  });
  const refreshOptions = merge({}, api.auth.getToken, {
    headers: { Authorization },
    data: body,
  });
  try {
    const refreshResponse = await refreshInstance(refreshOptions);
    const newTokens = refreshResponse.data;
    storage.writeObject('tokens', newTokens);
    saveTokens(newTokens);
    const newAccessToken = get(newTokens, ['access_token'], null);
    failedRequest.response.config.headers[
      'Authorization'
    ] = `Bearer ${newAccessToken}`;
    return Promise.resolve();
  } catch (error) {
    logout(LogoutReasonEnum.TOKEN_EXPIRED);
    // throw new RefreshAuthLogicError();
  }
};

createAuthRefreshInterceptor(instance, refreshAuthLogic);

async function request(
  options: AxiosRequestConfig,
  additionalOptions?: AdditionalOptions,
  clientOptions: {
    catch?: boolean;
  } = {
    catch: true,
  },
) {
  const mergedOptions = merge({}, options);

  if (additionalOptions) {
    const { urlParams, ...rest } = additionalOptions;
    if (urlParams) {
      forEach(urlParams, (paramValue, paramName) => {
        mergedOptions.url = paramValue
          ? mergedOptions.url?.replace(`{${paramName}}`, paramValue)
          : mergedOptions.url;
      });
    }

    merge(mergedOptions, rest);
  }

  try {
    const response = await instance(mergedOptions);
    checkStatus(response);
    return response;
  } catch (error) {
    if (error instanceof RefreshAuthLogicError) {
      throw new Error('fail_login');
    }

    const errorDescription =
      get(error, 'response.data.error_description') ||
      get(error, 'response.data.message') ||
      get(error, 'response.data.error') ||
      get(error, 'response.data') ||
      get(error, 'message');
    const errorStatus = get(error, 'response.status');
    const errorStatusText = get(error, 'response.statusText');

    const errorMessage =
      errorStatus && (errorDescription || errorStatusText)
        ? `${errorStatus}: ${errorDescription || errorStatusText}`
        : errorDescription || 'Undefined Error';

    const isUnAuth = errorStatus === 401;

    if (clientOptions.catch && !isUnAuth) {
      message.error(errorMessage);
    }

    throw error;
  }
}

export default request;
