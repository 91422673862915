import React, { FunctionComponent } from 'react';
import css from './DateSelector.module.less';
// import componentContent from './DateSelector.content.json';
// import useTranslate from '@hooks/useTranslate';
import { Input, Button, DatePicker, Spin } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import $date, { $preloaders, setDate } from '@stores/date';
import { item as $busItem } from '@stores/bus';
import { useStore } from 'effector-react';
import { DATE_FORMAT } from '@config/format';
import useMedia from '@hooks/useMedia';
import classNames from 'classnames';

interface DateSelectorProps {}

const DateSelector: FunctionComponent<DateSelectorProps> = () => {
  // const content = useTranslate(componentContent);

  const dateState = useStore($date);
  const busState = useStore($busItem);
  const date = dateState ? dateState.date : null;
  const timezone = busState.timezone;
  const preloaders = useStore($preloaders);

  const goPrevDay = () =>
    setDate(date ? date?.clone().subtract(1, 'day') : null);

  const goNextDay = () => setDate(date ? date?.clone().add(1, 'day') : null);
  const { isTabletOrMobile } = useMedia();

  return (
    <div>
      <div className={css.root}>
        <Spin spinning={!!preloaders.getData}>
          <Input.Group
            compact
            className={css.dateGroup}
            style={{ whiteSpace: 'nowrap' }}
          >
            <Button icon={<LeftOutlined />} onClick={goPrevDay} />
            <DatePicker
              format={DATE_FORMAT}
              allowClear={false}
              value={date}
              onChange={setDate}
              style={{ width: 110 }}
            />
            <Button icon={<RightOutlined />} onClick={goNextDay} />
          </Input.Group>
        </Spin>
        <div
          className={classNames(css.timezone, {
            [css.mobile]: isTabletOrMobile,
          })}
        >
          {timezone}
        </div>
      </div>
    </div>
  );
};

export default DateSelector;
