import {
  createEffect,
  createEvent,
  createStore,
  Effect,
  Event,
  guard,
  sample,
} from 'effector';

const schedulePending = (
  name: string,
  {
    start,
    abort = createEvent(`${name}Reset`),
    timeout = 1000,
    effect,
  }: {
    start: Event<any>;
    abort: Event<void>;
    timeout: number;
    effect: Effect<any, any, Error>;
  },
) => {
  const $working = createStore<boolean>(false, { name: `${name}Working` })
    .on(start, () => true)
    .on(abort, () => false);

  const $params = createStore<any>(null, { name: `${name}Params` })
    .on(start, (state, params) => params)
    .on(abort, () => null);

  const scheduleEffect = createEffect({
    name: `${name}Planned`,
    async handler(params: any) {
      requestAnimationFrame(() => setTimeout(effect, timeout, params));
    },
  });

  sample({
    source: start,
    target: effect,
  });

  guard({
    clock: effect.done,
    filter: $working,
    source: $params,
    target: scheduleEffect,
  });
};

export default schedulePending;
