import {
  BUS_DEFAULT_TIMEZONE,
  DATE_FORMAT,
  SERVER_DATE_FORMAT,
} from '@config/format';
import { createEffect, createEvent, createStore, sample } from 'effector';
import moment, { Moment } from 'moment-timezone';
import { changeParams } from '@helpers/navigation';
import { item as $bus } from './bus';
import IBus, { IBusGroup } from '@interfaces/IBus';
import {
  $dayList,
  selectedLapNumber,
  getDayList as getGps,
  recreateStops,
} from '@stores/gps';
import { get } from 'lodash';
import request from '@helpers/request';
import api from '@constants/endpoints';
import createPreloadersStore from '@stores/preloaders';
import { StatsItem } from '@stores/stats';
import { getDayResultData } from '@stores/day-summary';

export type DateState = null | {
  date: Moment;
  formattedDate: string;

  startdate: Moment;
  formattedStartdate: string;
  startdateValue: number;

  enddate: Moment;
  formattedEnddate: string;
  enddateValue: number;
};

export const setDate = createEvent<Moment | null>('set date');
export const initDate = createEvent<Moment | null>('init date');
export const reset = createEvent<any>('reset date');

const createData = (date: Moment, bus: IBus) => {
  const formattedDate = date.format(DATE_FORMAT);
  const timezone = bus.timezone || BUS_DEFAULT_TIMEZONE;

  const startdate = date?.clone().startOf('day').tz(timezone, true);
  const enddate = date?.clone().endOf('day').tz(timezone, true);

  const formattedStartdate = startdate?.format();
  const formattedEnddate = enddate?.format();

  const startdateValue = startdate?.valueOf();
  const enddateValue = enddate?.valueOf();

  const newState: DateState = {
    date,
    formattedDate,
    startdate,
    enddate,
    formattedStartdate,
    formattedEnddate,
    startdateValue,
    enddateValue,
  };

  return newState;
};

export const getData = createEffect({
  name: 'getData',
  async handler({
    dateState,
    workplaceId,
    type_calculation,
  }: {
    workplaceId: IBus['id'];
    dateState: DateState;
    type_calculation: IBusGroup['type_calculation'];
  }) {
    if (!dateState) return { calculator: null, dateState: null };
    const response = await request(api.calculation.get, {
      params: {
        workplaceId,
        date: dateState.date.format(SERVER_DATE_FORMAT),
      },
    });
    const queryParams = { date: dateState.formattedDate };
    const calculator = response.data;
    changeParams(queryParams);
    return { calculator, dateState: dateState };
  },
});

export const getPrepareDayStats = createEffect({
  name: 'getPrepareDayStats',
  async handler({
    dateState,
    workplaceId,
  }: {
    workplaceId: IBus['id'];
    dateState: DateState;
  }) {
    const response = await request(api.stats.list, {
      params: {
        workplaces: [workplaceId],
        dateFrom: dateState?.formattedStartdate,
        dateTo: dateState?.formattedEnddate,
        groupby: 'day',
      },
    });
    return response.data as StatsItem[];
  },
});

export const $preparedData = createStore<StatsItem | null>(null)
  .on(getPrepareDayStats.done, (_, { result }) => get(result, [0]))
  .reset(getPrepareDayStats);

export const $preloaders = createPreloadersStore([getData, getPrepareDayStats]);

const $date = createStore<DateState>(null, {
  updateFilter: (next, prev) => {
    if (prev && next) {
      return !prev.date.isSame(next.date);
    }
    if (!next) return true;
    if (!prev && next) return true;
    return false;
  },
}).reset(reset);

sample({
  clock: $date,
  source: [$bus],
  target: [getData, getDayResultData],
  filter: ([bus], date) => {
    if (bus.id && date) return true;
    return false;
  },
  fn([busState], dateState) {
    const type_calculation: IBusGroup['type_calculation'] = get(busState, [
      'group',
      'type_calculation',
    ]);
    if (!dateState)
      return { dateState: null, workplaceId: busState.id, type_calculation };
    return { dateState, workplaceId: busState.id, type_calculation };
  },
});

sample({
  clock: $date,
  source: [$bus],
  target: getPrepareDayStats,
  filter: ([bus], date) => {
    if (bus.id && date) return true;
    return false;
  },
  fn([busState], dateState) {
    if (!dateState) return { dateState: null, workplaceId: busState.id };
    return { dateState, workplaceId: busState.id };
  },
});

sample({
  clock: setDate,
  source: [$bus],
  target: $date,
  fn([busState], newDate) {
    const data = newDate ? createData(newDate, busState) : null;
    return data;
  },
});

sample({
  clock: initDate,
  source: [$date, $bus],
  target: $date,
  fn([dateState, busState], newDate) {
    const isSame = dateState ? dateState?.date.isSame(newDate) : false;
    if (isSame) return dateState;
    if (!newDate) return null;
    const data: DateState = createData(newDate, busState);
    const queryParams = data ? { date: data.formattedDate } : {};
    changeParams(queryParams);
    return data;
  },
});

sample({
  source: [$date, $dayList],
  target: selectedLapNumber,
  fn: ([date, laps]) => {
    const now = moment();
    const formattedToday = now.format(DATE_FORMAT);
    const lapIndex =
      formattedToday === date?.formattedDate ? laps.length - 1 : 0;
    const selectedLapNumber = get(laps, [lapIndex, 'number'], null);
    return selectedLapNumber;
  },
});

sample({
  source: [$date, $bus],
  clock: [$date, $bus, recreateStops.done],
  target: getGps,
  filter: ([dateState, busState]) => !!dateState && busState.gps_trackable,
  fn([dateState, busState]) {
    return {
      date: moment(get(dateState, 'date')).format(SERVER_DATE_FORMAT),
      workplaceId: get(busState, 'id', ''),
    };
  },
});

export default $date;
