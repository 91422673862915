import React, { FunctionComponent } from 'react';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  Tooltip,
  Cell,
  ReferenceLine,
  ReferenceArea,
} from 'recharts';
import moment from 'moment';
import { Empty } from 'antd';
import CustomTooltip from '@components/CustomTooltip';
import {
  BUS_DEFAULT_TIMEZONE,
  FULL_TIME_FORMAT_WITH_SECONDS,
  TIME_FORMAT,
} from '@config/format';
import css from './Daychart.module.less';
import { GREEN, RED } from '@constants/colors';
import { useStore, useStoreMap } from 'effector-react';
import $periods from '@stores/periods';
import { item } from '@stores/bus';
import useMedia from '@hooks/useMedia';
import $busCalculator from '@stores/busCalculator';
import { get } from 'lodash';
import $date from '@stores/date';
import useBarChart from '@hooks/useBarChart';

const BAR_OPACITY = 0.8;

const CustomizedLabel = (props: any) => {
  const { x, y, fill, value } = props;
  return (
    <text
      opacity={BAR_OPACITY}
      x={x}
      y={y}
      dy={-4}
      dx={16}
      fontSize="16"
      fontWeight="bold"
      fontFamily="sans-serif"
      fill={fill}
      textAnchor="middle"
    >
      {value}
    </text>
  );
};

interface DaychartProps {}

const Daychart: FunctionComponent<DaychartProps> = () => {
  const { isTabletOrMobile } = useMedia();
  const {
    combineData: { timelineParams },
  } = useStore($periods);
  const barChartSource = useBarChart();
  const bus = useStore(item);
  const calculator = useStore($busCalculator);
  const hasData = get(barChartSource, ['data', 'length']);
  const formattedDate = useStoreMap({
    store: $date,
    keys: [],
    fn: (state) => get(state, 'formattedDate', null),
  });

  const minValue = timelineParams.totalMin;
  const maxValue = timelineParams.totalMax;
  const timezone = bus.timezone || BUS_DEFAULT_TIMEZONE;
  const { data, ticks } = barChartSource;
  const shiftStart =
    get(calculator, 'shift_start') && formattedDate
      ? moment
          .tz(
            `${formattedDate} ${calculator?.shift_start}`,
            FULL_TIME_FORMAT_WITH_SECONDS,
            timezone,
          )
          .valueOf()
      : null;
  const shiftEnd =
    get(calculator, 'shift_end') && formattedDate
      ? moment
          .tz(
            `${formattedDate} ${calculator?.shift_end}`,
            FULL_TIME_FORMAT_WITH_SECONDS,
            timezone,
          )
          .valueOf()
      : null;

  if (!hasData) {
    return <Empty />;
  }

  return (
    <ResponsiveContainer width="100%" height={120}>
      <BarChart data={data}>
        <XAxis
          dataKey="timeValue"
          type={data.length === 1 ? 'category' : 'number'} // TODO: схлопываются бары, причина не выяснена
          domain={[minValue, maxValue]}
          ticks={ticks}
          interval="preserveStartEnd"
          tickFormatter={(tick) =>
            moment(tick).tz(timezone).format(TIME_FORMAT)
          }
        />
        <YAxis hide type="number" domain={[0, 'dataMax+50']} />
        {isTabletOrMobile ? null : (
          <Tooltip
            content={<CustomTooltip timezone={timezone} />}
            cursor={{ fill: '#00000050' }}
            wrapperStyle={{ top: 80, zIndex: 1 }}
            allowEscapeViewBox={{ x: true, y: false }}
          />
        )}
        <Bar
          dataKey="entered"
          fill={GREEN}
          label={<CustomizedLabel fill={GREEN} />}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              opacity={BAR_OPACITY}
              className={css.hoverableCell}
            />
          ))}
        </Bar>
        <Bar dataKey="exited" fill={RED} label={<CustomizedLabel fill={RED} />}>
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              opacity={BAR_OPACITY}
              className={css.hoverableCell}
            />
          ))}
        </Bar>
        {shiftStart ? (
          <ReferenceArea
            x1={minValue}
            x2={shiftStart}
            fill="#141414"
            isFront={true}
            fillOpacity={0.8}
          />
        ) : null}
        {shiftStart ? (
          <ReferenceLine x={shiftStart} strokeOpacity={0.5} />
        ) : null}
        {shiftEnd ? (
          <ReferenceArea
            x1={shiftEnd}
            x2={maxValue}
            fill="#141414"
            isFront={true}
            fillOpacity={0.8}
          />
        ) : null}
        {shiftEnd ? <ReferenceLine x={shiftEnd} strokeOpacity={0.5} /> : null}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Daychart;
