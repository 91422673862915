import api from '@constants/endpoints';
import request from '@helpers/request';
import saveResponseAsFile from '@helpers/saveResponseAsFile';
import schedulePending from '@helpers/schedulePending';
import IMergeRequest from '@interfaces/IMergeRequest';
import createPreloadersStore from '@stores/preloaders';
import { createEffect, createEvent, createStore, sample } from 'effector';
import update from 'immutability-helper';

export const openModal = createEvent('open merge modal');
export const closeModal = createEvent('close merge modal');
export const startRequestsPending = createEvent('startRequestsPending');
export const abortRequestsPending = createEvent('abortRequestsPending');

const setProgress = createEvent<
  Record<
    string,
    {
      loaded: number;
      total: number;
    }
  >
>('set progress');

export const merge = createEffect({
  name: 'merge',
  async handler({
    data,
  }: {
    data: { deviceId: string; from: string; processed: boolean; to: string };
  }) {
    const response = await request(api.concatMedia.merge, { data });
    closeModal();
    return response.data;
  },
});

export const getRequests = createEffect({
  name: 'getRequests',
  async handler() {
    const response = await request(api.concatMedia.getList);
    return response.data;
  },
});

export const getRequestsHided = createEffect({
  name: 'getRequestsHided',
  async handler() {
    const response = await request(api.concatMedia.getList);
    return response.data;
  },
});

schedulePending('mergeRequestsPending', {
  effect: getRequestsHided,
  timeout: 2000,
  start: startRequestsPending,
  abort: abortRequestsPending,
});

export const getFile = createEffect({
  name: 'getFile',
  async handler({ requestId }: { requestId: string }) {
    const response = await request(api.concatMedia.getFile, {
      urlParams: { requestId },
      onDownloadProgress: function ({ loaded, total }) {
        setProgress({
          [requestId]: {
            loaded,
            total,
          },
        });
      },
    });
    saveResponseAsFile(response);
  },
});

export const $progress = createStore<
  Record<
    string,
    {
      loaded: number;
      total: number;
    }
  >
>({}).on(setProgress, (state, payload) => ({ ...state, ...payload }));

export const $preloaders = createPreloadersStore([merge, getRequests]);

export const $list = createStore<IMergeRequest[]>([])
  .on([getRequests.done, getRequestsHided.done], (_, { result }) => result)
  .on(getFile.done, (state, { params }) => {
    const index = state.findIndex((r) => r.id === params.requestId);
    if (index < 0) return state;
    return update(state, {
      $splice: [[index, 1, { ...state[index], status: 'SENT' }]],
    });
  });

sample({
  clock: merge.done,
  target: getRequests,
});

export const $modalVisible = createStore(false)
  .on(openModal, () => true)
  .on(closeModal, () => false);
