import React, {
  FunctionComponent,
} from 'react';
import css from './RouteActions.module.less';
import componentContent from './RouteActions.content.json';
import useTranslate from '@hooks/useTranslate';
import Button from 'antd/es/button';
import { useStore } from 'effector-react';
import $date from '@stores/date';
import { get } from 'lodash';
import { recreateStops } from '@stores/gps';
import { useParams } from 'react-router';
import { SERVER_DATE_FORMAT } from '@config/format';

interface RouteActionsProps {}

const RouteActions: FunctionComponent<RouteActionsProps> = () => {
  const content = useTranslate(componentContent);
  const dateState = useStore($date);
  const currentDate = get(dateState, 'date');
  const currentDateFormatted = get(dateState, 'formattedDate');
  const { busId } = useParams<{ busId: string }>();
  const recreatingStops = useStore(recreateStops.pending);

  return (
    <div className={css.root}>
      <div className={css.item}>
        <div className={css.extra}>{content.recalcExtra}</div>
        <Button
          loading={recreatingStops}
          onClick={() =>
            recreateStops({
              workplaceId: busId,
              date: currentDate
                ? currentDate.format(SERVER_DATE_FORMAT)
                : undefined,
            })
          }
        >
          {content.recalc} {currentDateFormatted}
        </Button>
      </div>
    </div>
  );
};

export default RouteActions;
