import React, { FunctionComponent, useState } from 'react';
import css from './MobileTimePicker.module.less';
import componentContent from './MobileTimePicker.content.json';
import useTranslate from '@hooks/useTranslate';
import moment, { Moment } from 'moment';
import { TIME_FORMAT } from '@config/format';
import Button from 'antd/es/button';
import { Picker } from 'antd-mobile/es/components/picker/picker';

interface MobileTimePickerProps {
  value: Moment | null;
  onChange: (value: Moment) => void;
  title?: string;
}

const createTimeLabel = (value: number) => ({
  label: `${value < 10 ? '0' : ''}${value}`,
  value: `${value < 10 ? '0' : ''}${value}`,
});

const MobileTimePicker: FunctionComponent<MobileTimePickerProps> = ({
  value,
  title,
  onChange,
}) => {
  const content = useTranslate(componentContent);
  const [visible, setVisible] = useState(false);
  const hoursOptions = Array.from(Array(24).keys()).map(createTimeLabel);
  const minutesOptions = Array.from(Array(60).keys()).map(createTimeLabel);

  return (
    <div className={css.root}>
      <Button onClick={() => setVisible(true)} size="small" className={css.button}>
        {value ? value.format(TIME_FORMAT) : content.undefined}
      </Button>
      <Picker
        title={title}
        cancelText={content.cancel}
        confirmText={content.ok}
        columns={[hoursOptions, minutesOptions]}
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        value={value ? value.format(TIME_FORMAT).split(':') : undefined}
        onConfirm={(value) => onChange(moment(value.join(':'), TIME_FORMAT))}
      />
    </div>
  );
};

export default MobileTimePicker;
