import React, { FunctionComponent, useLayoutEffect } from 'react';

interface CMSPlayerProps {
  playUrl?: string;
}

const CMSPlayer: FunctionComponent<CMSPlayerProps> = ({
  playUrl,
}) => {
  useLayoutEffect(() => {
    // @ts-ignore
    const swfobject: any = new Cmsv6Player({
      domId: 'cmsv6flash',
      isVodMode: true,
      width: '100%',
      height: 294,
      lang: 'ru',
    });

    const url = playUrl || '';

    swfobject.startVodM(url, '');

    return () => {
      swfobject.stopVodM();
    }
  }, [playUrl]);

  return (
    <div>
      <div id="cmsv6flash"></div>
    </div>
  );
};

export default CMSPlayer;
