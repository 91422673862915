import { DEFAULT_PAGE_SIZE } from '@constants/pagination';
import { clearFilters } from '@helpers/clearFilters';
import request from '@helpers/request';
import createFiltersStore from '@stores/filters';
import createPaginationStore, { IPaginationsParams } from '@stores/pagination';
import createPreloadersStore from '@stores/preloaders';
import { PaginationProps } from 'antd/lib/pagination';
import { AxiosRequestConfig } from 'axios';
import { attach, createEffect, createEvent, createStore } from 'effector';
import { cloneDeep, get, isEqual, set } from 'lodash';

function createQueryListStore<T>({
  listName,
  endpoint,
  defaultFilterValues,
}: {
  listName: string;
  endpoint: AxiosRequestConfig;
  defaultFilterValues: Record<string, any>;
}) {
  const reset = createEvent(`reset ${listName} store`);

  const getList = createEffect({
    name: 'getList',
    async handler({
      pagination,
      filters,
      urlParams,
    }: {
      pagination: PaginationProps;
      filters: Record<string, any>;
      urlParams?: Record<string, any>;
    }) {
      const paginationsParams: IPaginationsParams = {
        page: (pagination.current || 1) - 1,
        perPage: pagination.pageSize || DEFAULT_PAGE_SIZE,
      };
      const response = await request(endpoint, {
        params: {
          ...paginationsParams,
          ...filters,
        },
        urlParams,
      });
      return get(response, ['data']);
    },
  });

  const preloaders = createPreloadersStore([getList]);

  const { pagination, setPagination } = createPaginationStore({
    listName,
    getList,
    resetEvent: reset,
  });

  const { filters } = createFiltersStore({
    listName,
    resetEvent: reset,
    deaultValues: defaultFilterValues,
  });

  const urlParams = createStore<Record<string, any> | null>(null)
    .on(getList.done, (state, { params: { urlParams } }) => urlParams)
    .reset(reset);

  const getListAttached = attach({
    source: [filters],
    effect: getList,
    mapParams: (
      params: {
        pagination: PaginationProps;
        filters: Record<string, any>;
      },
      [filtersState],
    ) => {
      const newParams = cloneDeep(params);
      if (!isEqual(clearFilters(params.filters), clearFilters(filtersState))) {
        set(newParams, ['pagination', 'current'], 1);
      }
      return newParams;
    },
  });

  filters.on(getList.done, (state, { params: { filters } }) => {
    return filters;
  });

  const list = createStore<T[]>([])
    .on(getList.done, (state, { result }) => result.items)
    .reset(reset);

  return {
    getList,
    setPagination,
    reset,
    list,
    preloaders,
    pagination,
    filters,
    getListAttached,
    urlParams,
  };
}

export default createQueryListStore;
