import { SERVER_DATE_FORMAT } from '@config/format';
import api from '@constants/endpoints';
import request from '@helpers/request';
import IBus, { IBusGroup } from '@interfaces/IBus';
import IDayResult from '@interfaces/IDayResult';
import { DateState } from '@stores/date';
import { createEffect, createStore } from 'effector';

export const getDayResultData = createEffect({
  name: 'getDayResultData',
  async handler({
    dateState,
    workplaceId,
  }: {
    workplaceId: IBus['id'];
    dateState: DateState;
    type_calculation: IBusGroup['type_calculation'];
  }) {
    if (!dateState) return null;
    const response = await request(api.stats.getDayResult, {
      params: {
        workplaceId,
        date: dateState.date.format(SERVER_DATE_FORMAT),
      },
    });
    return response.data;
  },
});

export const recalcDay = createEffect({
  name: 'recalcDay',
  async handler({
    date,
    workplace_id,
  }: {
    date?: string;
    workplace_id: string;
  }) {
    const response = await request(api.calculation.recalc, {
      data: { date, workplace_id },
    });
    return response.data as IDayResult;
  },
});

export const $daySummary = createStore<IDayResult | null>(null)
  .on(getDayResultData.done, (_, { result }) => result)
  .on(recalcDay.done, (_, { result }) => result);
