import { NavBar, TabBar, Popup } from 'antd-mobile';
// import { Dropdown } from 'antd';
import React, { FunctionComponent } from 'react';
import css from './Mobile.module.less';
// import componentContent from './Mobile.content.json';
// import useTranslate from '@hooks/useTranslate';
import cn from 'classnames';
import { goBack, goTo } from '@helpers/navigation';
import { useStore } from 'effector-react';
import locale from '@stores/locale';
import UserBlock from '@components/UserBlock';
import { $currentRouteTitle } from '@stores/app';
import history from '@helpers/history';
import { BellFilled, BellOutlined } from '@ant-design/icons';
import useCurrentRoute from '@hooks/useCurrentRoute';
import { filter, get } from 'lodash';
import routes, { MenuSlot, RouteName } from '@config/routes';
import { useState } from 'react';
// import MainMenu from '@components/MainMenu';
import Notifications from '@components/Notifications';
import useNotiCount from '@hooks/useNotiCount';
import { setBodyRef } from '@stores/bodyRef';

interface MobileProps {
  isAuth: boolean;
  children?: React.ReactNode;
}

// const mobileItems = filter(routes, (route) =>
//   route.menuSlotes ? route.menuSlotes.includes('mobile') : false,
// );

const Mobile: FunctionComponent<MobileProps> = ({ children, isAuth }) => {
  // const content = useTranslate(componentContent);
  const routeTitle = useStore($currentRouteTitle);
  const currentRoute = useCurrentRoute();
  const { unreaded } = useNotiCount();

  const localeName = useStore(locale);

  const rightButtons = (
    <div className={css.actions}>
      <UserBlock onlyLogout />
    </div>
  );

  const onBack = () => {
    if (currentRoute && currentRoute.getParentPath) {
      const parentPath = currentRoute.getParentPath();
      history.push(parentPath);
    } else {
      goBack();
    }
  };

  const panelItems = filter(routes, (route) =>
    (get(route, 'menuSlotes', []) as MenuSlot[]).includes('mobile'),
  );

  const [notiVisible, setNotiVisible] = useState(false);

  const notiPanel = (
    <Popup visible={notiVisible} onMaskClick={() => setNotiVisible(false)}>
      <div className={css.notiContainer}>
        <Notifications open={notiVisible} />
      </div>
    </Popup>
  );

  return (
    <div className={css.root}>
      <div className={css.header}>
        <NavBar onBack={onBack} right={isAuth ? rightButtons : null}>
          {/* <Dropdown trigger={['click']} overlay={<MainMenu mode="vertical" />}> */}
          <div>{routeTitle || currentRoute?.title[localeName]}</div>
          {/* </Dropdown> */}
        </NavBar>
      </div>
      <div className={css.body} ref={(current) => setBodyRef({ current })}>
        <div
          className={cn({
            [css.desktopWrapper]: !currentRoute?.hasMobileVersion,
          })}
        >
          {children}
        </div>
      </div>
      <div className={css.footer}>
        <TabBar
          activeKey={currentRoute?.name}
          onChange={(key) => {
            if (key === 'noti') {
              setNotiVisible(true);
              return;
            }
            goTo(key as RouteName);
          }}
        >
          {panelItems.map((route) => (
            <TabBar.Item key={route.name} icon={route.icon}></TabBar.Item>
          ))}

          <TabBar.Item
            key={'noti'}
            icon={
              unreaded ? (
                <BellFilled className={css.filled} />
              ) : (
                <BellOutlined />
              )
            }
          ></TabBar.Item>
        </TabBar>
        {notiPanel}
      </div>
    </div>
  );
};

export default Mobile;
