import { $allSegments } from '@stores/periods';
import { useStore } from 'effector-react';

const useSegments = () => {
  // const cameraIds = useStoreMap({
  //   store: $cameras,
  //   keys: [],
  //   fn: (state) => {
  //     const selectedIds: ICamera['id'][] = [];
  //     state.forEach((c) => (c.selected ? selectedIds.push(c.id) : null));
  //     return selectedIds.join(',');
  //   },
  // });

  // const allSegments = useStoreMap({
  //   store: combineData,
  //   keys: [cameraIds],
  //   fn: (state, [idsString]) => {
  //     const ids = idsString.split(',');
  //     return state.allSegments.filter((s) => ids.includes(`${s.cameraId}`));
  //   },
  // });

  const allSegments = useStore($allSegments);

  return allSegments;
};

export default useSegments;
