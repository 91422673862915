import { createStore, Effect } from 'effector';

const createPreloadersStore = (effects: Effect<any, any, Error>[]) => {
  const preloaders = createStore<Record<string, boolean | string>>({});
  effects.forEach((effect) => {
    preloaders
      .on(effect, (state, payload) => {
        const preloaderId = payload ? payload.preloaderId : null;
        return {
          ...state,
          [effect.compositeName.fullName]: preloaderId || true,
        };
      })
      .on(effect.finally, (state) => ({
        ...state,
        [effect.compositeName.fullName]: false,
      }));
  });
  return preloaders;
};

export default createPreloadersStore;
