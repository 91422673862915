import React, {
  FunctionComponent,
  ChangeEvent,
  useState,
  useEffect,
} from 'react';
import css from './SegmentEditPanel.module.less';
import componentContent from './SegmentEditPanel.content.json';
import useTranslate from '@hooks/useTranslate';
import cn from 'classnames';
import { useDebounce } from 'use-debounce';
import { updateItem, preloaders as $preloaders } from '@stores/periods';
import { IRichPeriod } from '@interfaces/IPeriod';
import update from 'immutability-helper';
import { isEqual, get } from 'lodash';
import { useStore, useStoreMap } from 'effector-react';
import { LoadingOutlined } from '@ant-design/icons';
import $video, { setSegment } from '@stores/video';

export type SegmentManualValue = {
  entered_manual: (number | undefined)[];
  exited_manual: (number | undefined)[];
};

// TODO: какая-то фигня с тайпскриптом
// export type SegmentManualValue = {
//   entered_manual: [number | undefined, number | undefined];
//   exited_manual: [number | undefined, number | undefined];
// };

// const validateValue = (value: SegmentManualValue) => {
//   return !isEqual(
//     [undefined, undefined, undefined, undefined],
//     [...value.entered_manual, ...value.exited_manual]
//   );
// };

interface SegmentEditPanelProps {
  value: SegmentManualValue;
  segment: IRichPeriod;
  mobile?: boolean;
}

// TODO: какая-то фигня с тайпскриптом
// const editedFields: ['entered_manual' | 'exited_manual', 0 | 1][][] = [
const editedFields: any[][] = [
  [
    ['entered_manual', 0],
    ['exited_manual', 0],
  ],
  [
    ['entered_manual', 1],
    ['exited_manual', 1],
  ],
];

const SegmentEditPanel: FunctionComponent<SegmentEditPanelProps> = (props) => {
  const content = useTranslate(componentContent);
  const [value, setValue] = useState<SegmentManualValue | undefined>(
    props.value,
  );
  const getChangeHandler =
    (name: 'entered_manual' | 'exited_manual', index: 0 | 1) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const hasValue = +e.target.value >= 0 && e.target.value !== '';
      setValue(
        update(value, {
          [name]: {
            $splice: [[index, 1, hasValue ? +e.target.value : undefined]],
          },
        }),
      );
    };

  const { selected } = useStore($video);
  const selectedSegment = selected ? selected?.segment : null;

  const [debouncedValue] = useDebounce(value, 500);

  const isUpdating = useStoreMap({
    store: $preloaders,
    keys: [],
    fn: (state) => state.updateItem === props.segment.id,
  });

  const onFocus = () => {
    if (get(selectedSegment, ['id']) !== props.segment.id) {
      setSegment(props.segment);
    }
  };

  useEffect(() => {
    if (!isEqual(props.value, debouncedValue) && debouncedValue) {
      updateItem({
        segmentId: props.segment.id,
        preloaderId: props.segment.id,
        data: debouncedValue,
      });
    }
    // eslint-disable-next-line
  }, [debouncedValue]);

  return (
    <div className={css.root}>
      {editedFields.map((group, index) => {
        return (
          <div key={index}>
            {group.map(([key, index]) => {
              const label = key.includes('entered') ? 'in' : 'out';
              const placeholder =
                content[`${label}${index === 1 ? 'Kids' : ''}Placeholder`];
              let currentValue = get(value, [key, index]);
              currentValue = [undefined, null].includes(currentValue as any)
                ? undefined
                : currentValue;

              return (
                <input
                  value={currentValue}
                  key={placeholder}
                  type="number"
                  className={cn(css.input, css[label], {
                    [css.mobile]: props.mobile,
                  })}
                  placeholder={placeholder}
                  title={placeholder}
                  onChange={getChangeHandler(key, index)}
                  onClick={(e) => e.stopPropagation()}
                  onFocus={onFocus}
                />
              );
            })}
          </div>
        );
      })}
      <div className={css.preloader}>
        {isUpdating ? <LoadingOutlined /> : null}
      </div>
    </div>
  );
};

export default SegmentEditPanel;
