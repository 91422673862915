import React, { FunctionComponent } from 'react';
import css from './Desktop.module.less';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import routes from '@config/routes';
import UserBlock from '@components/UserBlock';
import MainMenu from '@components/MainMenu';
import logo from '../../logo.svg';
import moment from 'moment';
const { Header, Content, Footer } = Layout;

interface DesktopProps {
  isAuth: boolean;
  children?: React.ReactNode;
}

const currentYear = moment().get('year');

const Desktop: FunctionComponent<DesktopProps> = ({ isAuth, children }) => {
  // const content = useTranslate(componentContent);

  return (
    <Layout className={css.root}>
      <Header className={css.header}>
        <div className={css.widthContainer}>
          <Link to={routes.root.path} className={css.logo}>
            <img src={logo} alt="RDetector" />
          </Link>
          {isAuth ? <MainMenu mode="horizontal" /> : null}
          {isAuth ? <UserBlock /> : null}
        </div>
      </Header>
      <Content className={css.content}>{children}</Content>
      <Footer style={{ textAlign: 'center' }}>
        © ООО «Норд Клан», 2020-{currentYear}
      </Footer>
    </Layout>
  );
};

export default Desktop;
