import React, { FunctionComponent } from 'react';
import css from './News.module.less';
import componentContent from './News.content.json';
import useTranslate from '@hooks/useTranslate';
import { useStore } from 'effector-react';
import $news, { getAll, getAllAttached } from '@stores/news';
import { Spin, Pagination } from 'antd';
import Item from './Item';
import useMedia from '@hooks/useMedia';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useRef } from 'react';

interface NewsProps {}

const News: FunctionComponent<NewsProps> = () => {
  const content = useTranslate(componentContent);
  const { all, preloaders, pagination, filters } = useStore($news);
  const { isTabletOrMobile } = useMedia();

  useEffect(() => {
    getAll({ pagination, filters });
    // eslint-disable-next-line
  }, []);

  const titleRef = useRef<HTMLDivElement>(null);

  return (
    <div className={classNames(css.root, { [css.mobile]: isTabletOrMobile })}>
      <div>
        <h1 ref={titleRef}>{content.title}</h1>
      </div>
      <Spin spinning={!!preloaders.getList}>
        <div>
          {all.map((n) => (
            <Item item={n} key={n.id} />
          ))}
        </div>
      </Spin>
      <div className={css.pagination}>
        <Pagination
          {...pagination}
          onChange={(current) => {
            getAllAttached({ filters, pagination: { ...pagination, current } });
            if (titleRef.current) titleRef.current.scrollIntoView();
          }}
        />
      </div>
    </div>
  );
};

export default News;
