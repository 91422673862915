import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import css from './MergeModal.module.less';
import componentContent from './MergeModal.content.json';
import useTranslate from '@hooks/useTranslate';
import { $modalVisible, $preloaders, closeModal, merge } from '@stores/merge';
import { useStore } from 'effector-react';
import { Modal, Slider, Radio, Input, DatePicker, Button } from 'antd';
import { combineData } from '@stores/periods';
import moment from 'moment-timezone';
import { cameras as $cameras, item } from '@stores/bus';
import { BUS_DEFAULT_TIMEZONE, TIME_FORMAT } from '@config/format';
import { SliderValue } from 'antd/lib/slider';
import { get } from 'lodash';
import MergedRequestsList from '@components/MergedRequestsList';
import { DownOutlined, RightOutlined } from '@ant-design/icons';

interface MergeModalProps {}

const MergeModal: FunctionComponent<MergeModalProps> = () => {
  const content = useTranslate(componentContent);
  const visible = useStore($modalVisible);
  const { timelineParams } = useStore(combineData);
  const bus = useStore(item);
  const timezone = bus.timezone || BUS_DEFAULT_TIMEZONE;
  const min = timelineParams.totalMin;
  const max = timelineParams.totalMax;
  const [rangeValue, setRange] = useState<SliderValue>([min, max]);
  const cameras = useStore($cameras);
  const [cameraValue, setCameraValue] = useState(get(cameras, [0, 'id']));
  const [processed, setProcessed] = useState(true);
  const preloaders = useStore($preloaders);
  const [listVisible, setListVisible] = useState(false);

  const utcOffset = useMemo(() => {
    return moment.tz(timezone).utcOffset();
  }, [timezone]);

  const startValue = get(rangeValue, [0], undefined);
  const endValue = get(rangeValue, [1], undefined);
  const start = startValue
    ? moment.utc(startValue).tz(timezone)
    : moment.utc(startValue).tz(timezone).subtract(utcOffset, 'm');
  const end = endValue
    ? moment.utc(endValue).tz(timezone)
    : moment.utc(endValue).tz(timezone).subtract(utcOffset, 'm');

  useEffect(() => {
    setRange([min, max]);
  }, [min, max]);
  useEffect(() => {
    setCameraValue(get(cameras, [0, 'id']));
  }, [cameras]);

  const requestMerge = () => {
    merge({
      data: {
        from: start.format(),
        to: end.format(),
        processed,
        deviceId: cameraValue,
      },
    });
  };

  return (
    <Modal
      onCancel={() => closeModal()}
      visible={visible}
      title={content.title}
      footer={null}
    >
      <div className={css.form}>
        <div className={css.params}>
          <Radio.Group
            className={css.radio}
            value={cameraValue}
            onChange={(e) => {
              setCameraValue(e.target.value);
            }}
          >
            {cameras.map((c) => (
              <Radio.Button key={c.id} value={c.id}>
                <span className="nowrap">{c.name}</span>
              </Radio.Button>
            ))}
          </Radio.Group>
          <Input.Group compact className="nowrap" style={{ width: 'auto' }}>
            <DatePicker.TimePicker
              allowClear={false}
              value={start}
              onChange={(momentValue) => {
                const value = momentValue?.valueOf();
                if (value && value >= min && value < endValue)
                  setRange([
                    value,
                    get(rangeValue, [1], undefined),
                  ] as SliderValue);
              }}
            />
            <DatePicker.TimePicker
              allowClear={false}
              value={end}
              onChange={(momentValue) => {
                const value = momentValue?.valueOf();
                if (value && value <= max && value > startValue)
                  setRange([
                    get(rangeValue, [0], undefined),
                    value,
                  ] as SliderValue);
              }}
            />
          </Input.Group>
        </div>
        <Slider
          range
          step={600000}
          tipFormatter={(value) =>
            value ? moment.utc(value).tz(timezone).format(TIME_FORMAT) : '00:00'
          }
          value={rangeValue}
          onChange={setRange}
          min={timelineParams.totalMin}
          max={timelineParams.totalMax}
        />
        <Radio.Group
          onChange={(e) => setProcessed(e.target.value)}
          value={processed}
        >
          <Radio.Button value={true}>{content.processedVideo}</Radio.Button>
          <Radio.Button value={false}>{content.sourceVideo}</Radio.Button>
        </Radio.Group>
        <div className={css.actions}>
          <Button
            onClick={() => requestMerge()}
            loading={!!preloaders.merge}
            type="primary"
          >
            {content.createRequest}
          </Button>
          <div className={css.extra}>{content.extra}</div>
        </div>
      </div>
      <div className={css.list}>
        <div
          className={css.listTitle}
          onClick={() => setListVisible(!listVisible)}
        >
          {listVisible ? <DownOutlined /> : <RightOutlined />}
          {content.lastRequests}{' '}
        </div>
        {listVisible ? <MergedRequestsList /> : null}
      </div>
    </Modal>
  );
};

export default MergeModal;
