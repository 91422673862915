import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import css from './HaltsListItem.module.less';
import componentContent from './HaltsListItem.content.json';
import useTranslate from '@hooks/useTranslate';
import { IHalt, IStop } from '@interfaces/IGpsPoint';
import cn from 'classnames';
import { BUS_DEFAULT_TIMEZONE } from '@config/format';
import { useStore, useStoreMap } from 'effector-react';
import $bus from '@stores/bus';
import {
  selectedLapNumber as $selectedLapNumber,
  setTerminal,
  preloaders,
} from '@stores/gps';
import { useMemo } from 'react';
import {
  PushpinOutlined,
  LoadingOutlined,
  CloseOutlined,
  BarChartOutlined,
  EditOutlined,
  DeleteOutlined,
  EnvironmentOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { get } from 'lodash';
import { Tooltip, Input, Dropdown, Menu } from 'antd';
import useMedia from '@hooks/useMedia';
import classNames from 'classnames';
import { setParams } from '@stores/halt-stats';
import Button from 'antd/es/button';
import {
  $editingHaltId,
  $preloaders,
  deleteRouteStop,
  setDirectionAttached,
  setEditingHalt,
  updateRouteStopAttached,
} from '@stores/route';
import { Popconfirm } from 'antd';
import Stop from './Stop';
import IRouteStop from '@interfaces/IRouteStops';

interface HaltsListItemProps {
  halt: IHalt | null;
  singleStop?: IStop | IRouteStop;
  index?: number;
  selected?: boolean;
  onClick?: (id: IHalt['id'] | null) => void;
  onClose?: (id: IHalt['id'] | null) => void;
  isRoute?: boolean;
  isStart?: boolean;
  isEnd?: boolean;
  isMiddle?: boolean;
}

const HaltsListItem: FunctionComponent<HaltsListItemProps> = ({
  halt,
  selected,
  onClick,
  onClose,
  isRoute,
  index,
  isStart,
  isEnd,
  isMiddle,
  singleStop,
}) => {
  const name = singleStop ? singleStop.name : get(halt, ['name'], '');
  const singleStopId = get(singleStop, 'id', '');
  const haltId = singleStop
    ? get(singleStop, 'classifier_id')
    : get(halt, 'id', '');
  const content = useTranslate(componentContent);
  const ref = useRef<any>(null);
  const { item } = useStore($bus);
  const selectedLapNumber = useStore($selectedLapNumber);
  const timezone = item.timezone || BUS_DEFAULT_TIMEZONE;
  const { isTabletOrMobile } = useMedia();
  const editingHaltId = useStore($editingHaltId);
  const [editingValue, setEditingValue] = useState<string | undefined>(name);
  const isUpdating = useStoreMap({
    store: $preloaders,
    keys: [],
    fn: (state) => {
      return state.updateRouteStop === singleStopId;
    },
  });
  const isDeleting = useStoreMap({
    store: $preloaders,
    keys: [],
    fn: (state) => {
      return state.deleteRouteStop === singleStopId;
    },
  });
  useEffect(() => {
    setEditingValue(name);
  }, [name]);

  useEffect(() => {
    if (ref.current && selected) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    // eslint-disable-next-line
  }, [selected]);

  const stops = useMemo(
    () =>
      singleStop
        ? [singleStop]
        : halt
        ? halt.stops.filter((s) => s.lap === selectedLapNumber)
        : [],
    // eslint-disable-next-line
    [selectedLapNumber],
  );

  const terminalSetting = useStoreMap({
    store: preloaders,
    keys: [],
    fn: (state) =>
      singleStop
        ? get(singleStop, 'classifier_id') === state.setTerminal
        : stops
            .map((s) => get(singleStop, 'classifier_id'))
            .includes(state.setTerminal as any),
  });

  const groupId = get(item, ['group', 'id'], '');

  const routeActions = (
    <div className={css.actions}>
      {/* <div
        className={classNames(css.action, {
          [css.mobile]: isTabletOrMobile,
        })}
        onClick={() => console.log(halt)}
      >
        {(index || 0) % 3 === 0 ? (
          <EnvironmentOutlined />
        ) : (
          <span className={css.routeLine}></span>
        )}
      </div> */}
      <Dropdown
        trigger={['click']}
        placement="bottomRight"
        overlay={
          <Menu
            onClick={({ key, domEvent }) => {
              domEvent.stopPropagation();
              setDirectionAttached({
                [key as 'firstRouteStopId' | 'lastRouteStopId']: singleStopId,
                groupId,
              });
            }}
          >
            <Menu.Item key="firstRouteStopId">{content.setStart}</Menu.Item>
            {isStart || isMiddle ? (
              <Menu.Item key="lastRouteStopId">{content.setEnd}</Menu.Item>
            ) : null}
          </Menu>
        }
      >
        <div
          className={classNames(css.action, {
            [css.mobile]: isTabletOrMobile,
          })}
          onClick={(e) => e.stopPropagation()}
        >
          {isStart || isEnd ? (
            <EnvironmentOutlined />
          ) : isMiddle ? (
            <span className={css.routeLine}></span>
          ) : (
            <PushpinOutlined />
          )}
        </div>
      </Dropdown>
      <div
        className={classNames(css.action, {
          [css.mobile]: isTabletOrMobile,
        })}
        onClick={(e) => {
          e.stopPropagation();
          setEditingHalt(singleStopId);
        }}
      >
        <EditOutlined />
      </div>
      <Popconfirm
        title={content.confirmDelete}
        placement="left"
        onConfirm={() => {
          deleteRouteStop({ stopId: singleStopId, preloaderId: singleStopId });
        }}
      >
        <div
          className={classNames(css.action, {
            [css.mobile]: isTabletOrMobile,
          })}
        >
          {isDeleting ? <LoadingOutlined /> : <DeleteOutlined />}
        </div>
      </Popconfirm>
    </div>
  );

  const haltNameElement = (
    // <div className={css.info}>
    //   {content.stop} {content.on} {name}
    // </div>
    <div className={css.info}>{name}</div>
  );

  const routeHaltNameElement =
    editingHaltId === singleStopId ? (
      <Input.Group compact>
        <Input
          value={editingValue}
          onChange={(e) => setEditingValue(e.target.value)}
          className={css.editNameField}
          autoFocus
          onPressEnter={(e) => {
            updateRouteStopAttached({ id: singleStopId, name: editingValue });
          }}
        />
        <Button
          icon={isUpdating ? <LoadingOutlined /> : <CheckOutlined />}
          type="primary"
          onClick={(e) => {
            e.stopPropagation();
            updateRouteStopAttached({ id: singleStopId, name: editingValue });
          }}
        />
      </Input.Group>
    ) : (
      <span>{name}</span>
    );

  return (
    <div
      className={cn(css.halt, {
        [css.selected]: selected,
      })}
      ref={ref}
      onClick={
        onClick
          ? () => {
              onClick(!selected ? singleStopId : null);
            }
          : undefined
      }
    >
      <div className={classNames(css.body, { [css.route]: isRoute })}>
        {name && !isRoute ? haltNameElement : routeHaltNameElement}
        <div className={css.haltInfo}>
          {isRoute ? null : (
            <div className={css.haltStats}>
              {(stops as IStop[]).map((s: IStop) => (
                <Stop timezone={timezone} bus={item} key={s.id} item={s} />
              ))}
            </div>
          )}
        </div>
      </div>
      {isRoute ? (
        routeActions
      ) : (
        <div className={css.actions}>
          {get(item, ['group']) ? (
            <Tooltip title={content.viewStats} placement="left">
              <div
                className={classNames(css.action, {
                  [css.mobile]: isTabletOrMobile,
                })}
                onClick={(e) => {
                  e.stopPropagation();
                  if (onClick) onClick(singleStopId);
                  setParams({
                    busStopClassifierId: haltId,
                  });
                }}
              >
                <BarChartOutlined />
              </div>
            </Tooltip>
          ) : null}
          <Tooltip title={content.setTerminal}>
            <div
              className={classNames(css.action, {
                [css.mobile]: isTabletOrMobile,
              })}
              onClick={() =>
                setTerminal({
                  busStopClassifierId: get(stops, [0, 'classifier_id'], ''),
                  workplaceId: item.id,
                  preloaderId: get(stops, [0, 'classifier_id'], ''),
                })
              }
            >
              {terminalSetting ? (
                <LoadingOutlined />
              ) : (
                <PushpinOutlined
                  className={cn({
                    [css.selected]: stops
                      .map((s) => haltId)
                      .includes(item.terminal_bus_stop_id),
                  })}
                />
              )}
            </div>
          </Tooltip>
          {onClose ? (
            <div
              className={classNames(css.action, {
                [css.mobile]: isTabletOrMobile,
              })}
              onClick={(e) => {
                e.stopPropagation();
                onClose(item.id);
              }}
            >
              <CloseOutlined />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default HaltsListItem;
