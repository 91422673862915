import api from '@constants/endpoints';
import { MAX_PAGE_SIZE } from '@constants/limits';
import createQueryListStore from '@helpers/createQueryList';
import request from '@helpers/request';
import INews from '@interfaces/INews';
import { combine, createEffect, createStore } from 'effector';
import { get } from 'lodash';

export const getFaqCount = createEffect({
  name: 'getFaqCount',
  async handler() {
    const response = await request(api.news.all, {
      params: {
        type: 'FAQ',
        pageSize: 1,
      },
    });
    return get(response, ['data', 'items', 'length'], 0);
  },
});

export const $faqCount = createStore(0).on(
  getFaqCount.done,
  (_, { result }) => result,
);

const { list, getList, getListAttached, pagination, filters, preloaders } =
  createQueryListStore<INews>({
    endpoint: api.news.all,
    listName: 'faq',
    defaultFilterValues: {
      sortBy: 'dateFrom',
      sortType: 'DESC',
      type: 'FAQ',
      pageSize: MAX_PAGE_SIZE,
    },
  });

export { getList, getListAttached };

const $faq = combine({
  list,
  preloaders,
  pagination,
  filters,
});

export default $faq;
