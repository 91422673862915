import React, { FunctionComponent } from 'react';
// import css from './BusTabs.module.less';
import componentContent from './BusTabs.content.json';
import useTranslate from '@hooks/useTranslate';
import { Tabs, Select } from 'antd';
import $bus, { setTab, TabName } from '@stores/bus';
import { useStore } from 'effector-react';

const TabPane = Tabs.TabPane;

interface BusTabsProps {
  mode?: 'tabs' | 'selector';
}

const BusTabs: FunctionComponent<BusTabsProps> = (
  { mode } = { mode: 'tabs' },
) => {
  const content = useTranslate(componentContent);
  const { item, activeTab, serviceCameras } = useStore($bus);

  if (mode === 'selector')
    return (
      <Select value={activeTab} onChange={(key) => setTab(key as TabName)}>
        <Select.Option key="count" value="count">
          {content.countTabLabel}
        </Select.Option>
        {serviceCameras.length ? (
          <Select.Option key="service" value="service">
            {content.serviceTabLabel}
          </Select.Option>
        ) : null}
        {item.gps_trackable ? (
          <Select.Option key="gps" value="gps">
            {content.gps}
          </Select.Option>
        ) : null}
      </Select>
    );

  return (
    <Tabs activeKey={activeTab} onChange={(key) => setTab(key as TabName)}>
      <TabPane
        tab={content.countTabLabel}
        key="count"
        active={activeTab === 'count'}
      ></TabPane>
      {serviceCameras.length ? (
        <TabPane
          tab={content.serviceTabLabel}
          key="service"
          active={activeTab === 'service'}
        ></TabPane>
      ) : null}
      {item.gps_trackable ? (
        <TabPane
          tab={content.gps}
          key="gps"
          active={activeTab === 'gps'}
        ></TabPane>
      ) : null}
    </Tabs>
  );
};

export default BusTabs;
