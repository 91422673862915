import { createStore, createEvent, Event } from 'effector';
import { Locale } from 'antd/lib/locale-provider';

import ruRU from 'antd/es/locale/ru_RU';
import enUS from 'antd/es/locale/en_US';

const initialData = (localStorage.getItem('locale') as LocaleName) || 'ru_RU';

export const setLocale: Event<LocaleName> = createEvent('Select Locale');

const setLocaleHandler = (state: LocaleName, localeName: LocaleName) =>
  localeName;

const locale = createStore<LocaleName>(initialData).on(
  setLocale,
  setLocaleHandler,
);

locale.watch((localeName: LocaleName) => {
  localStorage.setItem('locale', localeName);
});

export type LocaleName = 'ru_RU' | 'en_US';

export const localeMap: Record<LocaleName, { locale: Locale; name: string }> = {
  ru_RU: {
    locale: ruRU,
    name: 'RU',
  },
  en_US: {
    locale: enUS,
    name: 'EN',
  },
};

export default locale;
