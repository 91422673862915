import {
  createStore,
  createEvent,
  createEffect,
  combine,
  sample,
} from 'effector';
import request from '@helpers/request';
import api from '@constants/endpoints';
import createPreloadersStore from './preloaders';
import IBus, { IBusGroup } from '@interfaces/IBus';
import { sortBy, get } from 'lodash';
import ICustomer from '../interfaces/ICustomer';
import sortByName from '@helpers/sortByName';

type SortedList = Map<ICustomer['id'], Map<IBusGroup['id'], IBus[]>>;
export const UNGROUP_ID = '!ungrouped';
const ungroupHolder: IBusGroup = {
  id: UNGROUP_ID,
  name: 'Без маршрута',
  deleted: false,
  type_calculation: 'FIXED',
};

export const reset = createEvent('Buses store reset');

export const getList = createEffect({
  name: 'getList',
  async handler() {
    const response = await request(api.buses.list);
    const listByCustomer: SortedList = new Map();
    const customers: ICustomer[] = [];
    const busGroups: Record<IBusGroup['id'], IBusGroup | null> = {};
    response.data.forEach((b: IBus) => {
      const busCustomers = get(b, ['clients'], []);
      const groupId = get(b, ['group', 'id'], UNGROUP_ID);

      busCustomers.forEach((c: ICustomer) => {
        const createdList = listByCustomer.get(c.id);
        if (createdList) {
          const listByGroupId = createdList.get(groupId);
          if (listByGroupId) {
            listByGroupId.push(b);
          } else {
            createdList.set(groupId, [b]);
            busGroups[groupId] = get(b, ['group']);
          }
        } else {
          const listByGroup = new Map();
          listByGroup.set(groupId, [b]);
          listByCustomer.set(c.id, listByGroup);
          busGroups[groupId] = get(b, ['group']);
          customers.push(c);
        }
      });
    });
    listByCustomer.forEach((groups) => {
      groups.forEach((buses) => buses.sort(sortByName));
    });

    return {
      unsorted: sortBy(response.data, ['name']),
      sorted: listByCustomer,
      customers,
      busGroups,
    };
  },
});

export const setGroup = createEffect({
  name: 'setGroup',
  async handler({
    groupId,
    workplaceId,
  }: {
    groupId?: IBusGroup['id'] | null;
    workplaceId: IBus['id'];
    preloaderId: string;
  }) {
    const response = await request(api.buses.setGroup, {
      data: { groupId, workplaceId },
    });
    return response;
  },
});

const sortCustomers = (
  state: ICustomer[],
  { result }: { result: { customers: ICustomer[] } },
) => {
  const sortedList = result.customers.sort((a, b) => {
    const customerSurnameA = get(a, ['surname']);
    const customerSurnameB = get(b, ['surname']);
    const customerNameA = get(a, ['name']);
    const customerNameB = get(b, ['name']);
    const customerFullNameA = [customerSurnameA, customerNameA].join();
    const customerFullNameB = [customerSurnameB, customerNameB].join();
    if (customerFullNameA > customerFullNameB) return 1;
    if (customerFullNameA < customerFullNameB) return -1;
    return 0;
  });
  return sortedList;
};

export const list = createStore<IBus[]>([])
  .on(getList.done, (state, { result }) => result.unsorted)
  .reset(reset);

export const customers = createStore<ICustomer[]>([])
  .on(getList.done, sortCustomers)
  .reset(reset);

export const busGroups = createStore<Record<IBusGroup['id'], IBusGroup | null>>(
  {},
)
  .on(getList.done, (state, { result }) => ({
    ...result.busGroups,
    [UNGROUP_ID]: ungroupHolder,
  }))
  .reset(reset);

export const listByCustomers = createStore<SortedList>(new Map())
  .on(getList.done, (state, { result }) => result.sorted)
  .reset(reset);

export const preloaders = createPreloadersStore([getList, setGroup]);

sample({
  clock: [setGroup.done],
  target: getList,
});

const buses = combine({
  list,
  listByCustomers,
  customers,
  busGroups,
  preloaders,
});

export default buses;
