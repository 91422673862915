import React, { FunctionComponent, useRef } from 'react';
import getStreamUrl from '@helpers/getStreamUrl';
import CMSPlayer from '@components/CMSPlayer';
import { hasVideoStatuses, liveVideoStatuses } from '@constants/periodStatuses';
import { useEffect } from 'react';
import $video, { setNextSegment } from '@stores/video';
import { useStore } from 'effector-react';
import { get } from 'lodash';
import IPeriod from '@interfaces/IPeriod';

interface VideoPreviewProps {
  className?: string;
  autoplay?: boolean;
  segment?: IPeriod;
}

const VideoPreview: FunctionComponent<VideoPreviewProps> = ({
  className,
  autoplay,
  segment,
}) => {
  const ref = useRef<HTMLVideoElement>(null);

  const { selected } = useStore($video);
  const selectedSegment = segment
    ? segment
    : selected
    ? selected?.segment
    : null;
  const selectedSegmentHash = segment
    ? `${segment.id}`
    : selected
    ? selected?.hash
    : null;

  useEffect(() => {
    if (autoplay && ref.current) {
      ref.current.addEventListener('ended', setNextSegment);
    }
    if (!autoplay && ref.current && setNextSegment) {
      ref.current.removeEventListener('ended', setNextSegment);
    }
    // eslint-disable-next-line
  }, [autoplay]);

  useEffect(() => {
    if (ref.current) {
      ref.current.load();
    }
  }, [selectedSegmentHash]);

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      const isBody = get(event, ['target', 'tagName']) === 'BODY';
      if (!isBody) return;
      event.stopPropagation();
      event.preventDefault();
      const target = event.target;
      // @ts-ignore
      if (target && get(target, 'type') === 'checkbox') target.blur();
      if (event.code === 'Space' && ref.current) {
        if (ref.current.paused) {
          ref.current.play();
        } else {
          ref.current.pause();
        }
      }
    };
    document.addEventListener('keypress', handler);
    return () => document.removeEventListener('keypress', handler);
  }, [ref]);

  if (!selectedSegment) return null;

  const isShortDto = !selectedSegment.status;

  if (liveVideoStatuses.includes(selectedSegment.status))
    return (
      <div className={className}>
        <CMSPlayer playUrl={selectedSegment.playback_uri} />
      </div>
    );

  if (!isShortDto && !hasVideoStatuses.includes(selectedSegment.status))
    return null;

  return (
    <video
      controls
      autoPlay
      className={className}
      // key={selectedSegmentHash}
      ref={ref}
    >
      <source src={getStreamUrl(selectedSegment)} type="video/mp4"></source>
    </video>
  );
};

export default VideoPreview;
