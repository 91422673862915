import React, { FunctionComponent } from 'react';
import css from './LapTabsItem.module.less';
import componentContent from './LapTabsItem.content.json';
import useTranslate from '@hooks/useTranslate';
import { IReachLap } from '@interfaces/IGpsPoint';
import cn from 'classnames';
import { setLap, selectedLapNumber as $selectedLapNumber } from '@stores/gps';
import { Tooltip, Dropdown, Menu, Modal } from 'antd';
import {
  UserOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  LoadingOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import useAdmin from '@hooks/useAdmin';
import { useStore, useStoreMap } from 'effector-react';
import { item } from '@stores/bus';
import { get, has } from 'lodash';
import { generateRoute, $preloaders as $routePreloaders } from '@stores/route';

interface LapTabsItemProps {
  lap: IReachLap;
}

const LapTabsItem: FunctionComponent<LapTabsItemProps> = ({ lap }) => {
  const content = useTranslate(componentContent);
  const selectedLapNumber = useStore($selectedLapNumber);
  const isAdmin = useAdmin();
  const bus = useStore(item);
  const hasRoute = has(bus, ['group', 'id']);
  const isRouteGenerated = useStoreMap({
    store: $routePreloaders,
    keys: [],
    fn: (state) => +state.generateRoute === lap.number,
  });
  const menu = (
    <Menu
      onClick={({ key, domEvent }) => {
        domEvent.stopPropagation();
        Modal.confirm({
          onOk: () =>
            generateRoute({
              lapId: lap.lap_id,
              busId: bus.id,
              groupId: get(bus, ['group', 'id']),
              preloaderId: `${lap.number}`,
            }),
          title: content.generateRouteTitle,
          content: content.generateRouteConfirmText,
        });
      }}
    >
      <Menu.Item key="generateRoute">{content.generateRouteTrack}</Menu.Item>
    </Menu>
  );

  return (
    <div
      className={cn(css.tab, {
        [css.active]: selectedLapNumber === lap.number,
      })}
      onClick={() => setLap(lap.number)}
    >
      <div>
        {content.lap} {lap.lap}
      </div>
      <div className={css.meta}>
        <Tooltip placement="right" title={content.passengers}>
          <span>
            <UserOutlined />
            <span className={css.in}>{lap.entered}</span> /{' '}
            <span className={css.out}>{lap.exited}</span>
          </span>
        </Tooltip>
        <Tooltip placement="right" title={content.halts}>
          <span>
            <CheckCircleOutlined />
            {lap.stops}
          </span>
        </Tooltip>
      </div>
      <div className={css.meta}>
        <Tooltip placement="right" title={content.time}>
          <span>
            <ClockCircleOutlined />
            {lap.formattedDuration}
          </span>
        </Tooltip>
      </div>
      {isAdmin && hasRoute ? (
        <span className={css.menuButton}>
          <Dropdown trigger={['click']} overlay={menu}>
            {isRouteGenerated ? <LoadingOutlined /> : <MoreOutlined />}
          </Dropdown>
        </span>
      ) : null}
    </div>
  );
};

export default LapTabsItem;
